import React, { useState, useRef, useEffect } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, MenuItem, Button, } from '@mui/material';
import { TextField, InputAdornment } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {  LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import deLocale from 'date-fns/locale/de'; 
import { parseISO, format,isValid,startOfDay} from 'date-fns';
import { IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { editParentInformation } from '../../../Store/actions';

const ParentInfo = ({lead_parent_info}) => {
console.log("lead_parent_info",lead_parent_info)
  const [editableData, setEditableData] = useState({ ...lead_parent_info });
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);
  const [editedFields, setEditedFields] = useState([]);
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(()=>{
    setEditableData(lead_parent_info)
  },[lead_parent_info])

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditableData({
      ...editableData,
      [name]: value,
    });
    setEditedFields((prevEditedFields) =>
      prevEditedFields.includes(name) ? prevEditedFields : [...prevEditedFields, name]
    );
    
    
  };

  const handleSubmitInfo = () =>{
    console.log("will dispatch the changes")
    console.log("to submit data",editableData)
    setEditedFields([]);
    dispatch(editParentInformation(editableData))

  }


  const handleCellDoubleClick = (cellData) => {
    setIsEditMode(true);
    setSelectedCell(cellData);
  };

  useEffect(() => {
    if (isEditMode && selectedCell) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [isEditMode, selectedCell]);
  


  const handleInputBlur = () => {
    setIsEditMode(false);
    setSelectedCell(null);
  };

  const handleInputKeyDown = (event) => {
    // Allow Enter key to trigger save and exit edit mode
    if (event.key === "Enter") {
      event.preventDefault();
      setIsEditMode(false);
      setSelectedCell(null);
    }
  };

  const handleSaveClick = () => {
    // Here, you can perform any action or API call to save the updated data.
    // For now, we'll simply update the state with the edited data and exit the edit mode.
    setIsEditMode(false);
    setSelectedCell(null);
  };

  const formatDateForPicker = (dateString) => {
    const dateObject = parseISO(dateString, 'dd/MM/yyyy', new Date());
    return isValid(dateObject) ? dateObject : null;
  };

  console.info(lead_parent_info)
  console.info(parseISO(editableData.travel_date))

  return (
    <div>

        
        
        <p style={{ margin: '10px', fontWeight: 'bold', fontStyle: 'italic', color:'red' }}>
            LeadID, Created Data, and Update Date can't be updated from here
        </p>
        <TableContainer component={Paper} sx={{padding: '0px 0px',border: '2px solid black',backgroundColor: 'lightyellow',fontSize: '1.1rem',margin: '1rem 0'}}>
            <Table className='lead-info-table' aria-label="simple table">
                <TableHead className='lead-info-table-head'>
                    <TableRow>
                        <TableCell align='center' sx={{fontFamily:'Raleway',fontWeight:'bold'}}>Lead-ID</TableCell>
                        <TableCell align='center' sx={{fontFamily:'Raleway',fontWeight:'bold'}}>Lead-Type</TableCell>
                        <TableCell align='center' sx={{fontFamily:'Raleway',fontWeight:'bold'}}>Name</TableCell>
                        <TableCell align='center' sx={{fontFamily:'Raleway',fontWeight:'bold'}}>Email</TableCell>
                        <TableCell align='center' sx={{fontFamily:'Raleway',fontWeight:'bold'}}>Mobile</TableCell>
                        <TableCell align='center' sx={{fontFamily:'Raleway',fontWeight:'bold'}}>Created Date</TableCell>
                        <TableCell align='center' sx={{fontFamily:'Raleway',fontWeight:'bold'}}>Updated Date</TableCell>
                        <TableCell align='center' sx={{fontFamily:'Raleway',fontWeight:'bold'}}>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key={lead_parent_info.id}>
                        <TableCell align='center' sx={{fontFamily:'Raleway',fontWeight:500,}}>{lead_parent_info.id}</TableCell>
                        
                        {/* For the lead Type */}
                        <TableCell align="center" sx={{ fontFamily: "Raleway", fontWeight: 500, color: editedFields.includes("lead_type") ? "red" : "inherit",}} onDoubleClick={() => handleCellDoubleClick("lead_type")}>
                            {isEditMode && selectedCell === "lead_type" ? (
                            <TextField select name="lead_type" value={editableData.lead_type} onChange={handleInputChange} onBlur={handleInputBlur} onKeyDown={handleInputKeyDown} style={{ width: "100%", minWidth: "300px" }}
                                InputProps={{endAdornment: (
                                    <InputAdornment position="end">
                                        <CheckCircleIcon color="primary" onClick={handleSaveClick} style={{ cursor: "pointer" }}/>
                                    </InputAdornment>),}}>
                                <MenuItem value="B2B">B2B</MenuItem>
                                <MenuItem value="Internal">Internal</MenuItem>
                            </TextField>
                            ) : (editableData.lead_type)}
                        </TableCell>
                        
                        {/* For the lead Name */}
                        <TableCell align="center" sx={{ fontFamily: "Raleway", fontWeight: 500, color: editedFields.includes("name") ? "red" : "inherit",}} onDoubleClick={() => handleCellDoubleClick("name")}>
                            {isEditMode && selectedCell === 'name' ? (
                            <TextField inputRef={inputRef} multiline  name="name" value={editableData.name} onChange={handleInputChange} onBlur={handleInputBlur} onKeyDown={handleInputKeyDown} style={{ width: '100%', minWidth: '300px' }}
                                InputProps={{endAdornment: (
                                    <InputAdornment position="end">
                                        <CheckCircleIcon color="primary" onClick={handleSaveClick} style={{ cursor: 'pointer' }} />
                                    </InputAdornment>),}}/>
                            ) : (editableData.name)}
                        </TableCell>


                        {/* For the email */}
                        <TableCell align="center" sx={{ fontFamily: "Raleway", fontWeight: 500, color: editedFields.includes("email") ? "red" : "inherit",}} onDoubleClick={() => handleCellDoubleClick("email")}>
                            {isEditMode && selectedCell === 'email' ? (
                            <TextField inputRef={inputRef} multiline  name="email" value={editableData.email} onChange={handleInputChange} onBlur={handleInputBlur} onKeyDown={handleInputKeyDown} style={{ width: '100%', minWidth: '300px' }}
                                InputProps={{endAdornment: (
                                    <InputAdornment position="end">
                                        <CheckCircleIcon color="primary" onClick={handleSaveClick} style={{ cursor: 'pointer' }} />
                                    </InputAdornment>),}}/>
                            ) : (editableData.email)}
                        </TableCell>

                        {/* For the mobile number */}

                        <TableCell align="center" sx={{ fontFamily: "Raleway", fontWeight: 500, color: editedFields.includes("mobile_number") ? "red" : "inherit",}} onDoubleClick={() => handleCellDoubleClick("mobile_number")}>
                            {isEditMode && selectedCell === 'mobile_number' ? (
                            <TextField inputRef={inputRef} multiline name="mobile_number" value={editableData.mobile_number} onChange={handleInputChange} onBlur={handleInputBlur} onKeyDown={handleInputKeyDown} style={{ width: '100%', minWidth: '300px' }}
                                InputProps={{endAdornment: (
                                    <InputAdornment position="end">
                                        <CheckCircleIcon color="primary" onClick={handleSaveClick} style={{ cursor: 'pointer' }} />
                                    </InputAdornment>),}}/>
                            ) : (editableData.mobile_number)}
                        </TableCell>


                        {/* For the last created and updated time */}
                        <TableCell align='center' sx={{fontFamily:'Raleway',fontWeight:500}}>{lead_parent_info.created_time}</TableCell>
                        <TableCell align='center' sx={{fontFamily:'Raleway',fontWeight:500}}>{lead_parent_info.last_update_time}</TableCell>


                        {/* For the status of the lead*/}
                        <TableCell align="center" sx={{ fontFamily: "Raleway", fontWeight: 500,color: editedFields.includes("status") ? "red" : "inherit",}} onDoubleClick={() => handleCellDoubleClick("status")}>
                           {isEditMode && selectedCell === "status" ? (
                            <TextField select name="status" value={editableData.status} onChange={handleInputChange} onBlur={handleInputBlur} onKeyDown={handleInputKeyDown} style={{ width: "100%", minWidth: "300px" }}
                                InputProps={{endAdornment: (
                                    <InputAdornment position="end">
                                    <CheckCircleIcon color="primary" onClick={handleSaveClick} style={{ cursor: "pointer" }}/>
                                    </InputAdornment>),}}>
                                <MenuItem value="Started">Started</MenuItem>
                                <MenuItem value="Completed">Completed</MenuItem>
                                <MenuItem value="Failed">Failed</MenuItem>
                                <MenuItem value="Invoice Mail Sent">Invoice Mail Sent</MenuItem>
                                <MenuItem value="AQCS Done">AQCS Done</MenuItem>
                                <MenuItem value="Lead Confirmed">Lead Confirmed</MenuItem>
                            </TextField>
                            ) : (editableData.status)}
                        </TableCell>

                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        <TableContainer component={Paper} sx={{padding: '0px 0px',border: '2px solid black',backgroundColor: 'lightyellow',fontSize: '1.1rem',margin: '1rem 0'}} >
            <Table className='lead-info-table' aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell align='center' sx={{fontFamily:'Raleway',fontWeight:'bold'}}>Entry Port</TableCell>
                    <TableCell align='center' sx={{fontFamily:'Raleway',fontWeight:'bold'}}>Exit Port</TableCell>
                    <TableCell align='center' sx={{fontFamily:'Raleway',fontWeight:'bold'}}>India Address</TableCell>
                    <TableCell align='center' sx={{fontFamily:'Raleway',fontWeight:'bold'}}>Foreign Address</TableCell>
                    <TableCell align='center' sx={{fontFamily:'Raleway',fontWeight:'bold'}}>Travel Date</TableCell>
                    <TableCell align='center' sx={{fontFamily:'Raleway',fontWeight:'bold'}}>Travel Category</TableCell>
                    <TableCell align='center' sx={{fontFamily:'Raleway',fontWeight:'bold'}}>Transport Category</TableCell>
                    <TableCell align='center' sx={{fontFamily:'Raleway',fontWeight:'bold'}}>Lead Source</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow key={lead_parent_info.id}>
                
                {/* For the port of entry */}
                <TableCell align="center" sx={{ fontFamily: "Raleway", fontWeight: 500,color: editedFields.includes("port_of_entry") ? "red" : "inherit",}} onDoubleClick={() => handleCellDoubleClick("port_of_entry")}>
                            {isEditMode && selectedCell === 'port_of_entry' ? (
                            <TextField inputRef={inputRef} multiline name="port_of_entry" value={editableData.port_of_entry} onChange={handleInputChange} onBlur={handleInputBlur} onKeyDown={handleInputKeyDown} style={{ width: '100%', minWidth: '300px' }}
                                InputProps={{endAdornment: (
                                    <InputAdornment position="end">
                                        <CheckCircleIcon color="primary" onClick={handleSaveClick} style={{ cursor: 'pointer' }} />
                                    </InputAdornment>),}}/>
                            ) : (editableData.port_of_entry)}
                        </TableCell>
            

                {/* For the port of exit */}
                <TableCell align="center" sx={{ fontFamily: "Raleway", fontWeight: 500, color: editedFields.includes("port_of_exit") ? "red" : "inherit",}} onDoubleClick={() => handleCellDoubleClick("port_of_exit")}>
                            {isEditMode && selectedCell === 'port_of_exit' ? (
                            <TextField inputRef={inputRef} multiline name="port_of_exit" value={editableData.port_of_exit} onChange={handleInputChange} onBlur={handleInputBlur} onKeyDown={handleInputKeyDown} style={{ width: '100%', minWidth: '300px' }}
                                InputProps={{endAdornment: (
                                    <InputAdornment position="end">
                                        <CheckCircleIcon color="primary" onClick={handleSaveClick} style={{ cursor: 'pointer' }} />
                                    </InputAdornment>),}}/>
                            ) : (editableData.port_of_exit)}
                        </TableCell>

                
                {/* For the address in India */}
                
                <TableCell align="center" sx={{ fontFamily: "Raleway", fontWeight: 500, color: editedFields.includes("address_in_india") ? "red" : "inherit",}} onDoubleClick={() => handleCellDoubleClick("address_in_india")}>
                            {isEditMode && selectedCell === 'address_in_india' ? (
                            <TextField inputRef={inputRef} multiline name="address_in_india" value={editableData.address_in_india} onChange={handleInputChange} onBlur={handleInputBlur} onKeyDown={handleInputKeyDown} style={{ width: '100%', minWidth: '300px' }}
                                InputProps={{endAdornment: (
                                    <InputAdornment position="end">
                                        <CheckCircleIcon color="primary" onClick={handleSaveClick} style={{ cursor: 'pointer' }} />
                                    </InputAdornment>),}}/>
                            ) : (editableData.address_in_india)}
                        </TableCell>

                
                {/* For the address outside India */}
                <TableCell align="center" sx={{ fontFamily: "Raleway", fontWeight: 500, color: editedFields.includes("address_outside_india") ? "red" : "inherit",}} onDoubleClick={() => handleCellDoubleClick("address_outside_india")}>
                            {isEditMode && selectedCell === 'address_outside_india' ? (
                            <TextField inputRef={inputRef} multiline name="address_outside_india" value={editableData.address_outside_india} onChange={handleInputChange} onBlur={handleInputBlur} onKeyDown={handleInputKeyDown} style={{ width: '100%', minWidth: '300px' }}
                                InputProps={{endAdornment: (
                                    <InputAdornment position="end">
                                        <CheckCircleIcon color="primary" onClick={handleSaveClick} style={{ cursor: 'pointer' }} />
                                    </InputAdornment>),}}/>
                            ) : (editableData.address_outside_india)}
                        </TableCell>

                
                
                {/* For the travel Date */}
                
                <TableCell align='center' sx={{fontFamily: 'Raleway',fontWeight: 500,color: editedFields.includes("travel_date") ? "red" : "inherit",}} onDoubleClick={() => handleCellDoubleClick('travel_date')}>
                    {isEditMode && selectedCell === 'travel_date' ? (
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <DatePicker inputRef={inputRef} name="travel_date" value={formatDateForPicker(editableData.travel_date)} 
                        onChange={(newValue) => {
                            if (newValue) {
                              newValue = startOfDay(newValue);
                            }
                            handleInputChange({
                              target: {
                                name: 'travel_date',
                                value: newValue ? format(newValue, 'dd/MM/yyyy', new Date()) : '',
                              },
                            });
                          }}
                        onBlur={handleInputBlur} onKeyDown={handleInputKeyDown} renderInput={({ inputRef, ...params }) => (<TextField {...params} />)} style={{ flex: 1, minWidth: '300px' }} inputFormat="dd/MM/yyyy" placeholder={editableData.travel_date}/>
                        <IconButton onClick={handleSaveClick} style={{ marginLeft: '5px' }}> <CheckCircleIcon color="primary" /> </IconButton>
                        </div>
                    </LocalizationProvider>
                    ) : (editableData.travel_date)}
                </TableCell>


                {/* For the category of travel */}
                <TableCell align="center" sx={{ fontFamily: "Raleway", fontWeight: 500, color: editedFields.includes("category_of_travel") ? "red" : "inherit",}} onDoubleClick={() => handleCellDoubleClick("category_of_travel")}>
                            {isEditMode && selectedCell === 'category_of_travel' ? (
                            <TextField inputRef={inputRef} multiline name="category_of_travel" value={editableData.category_of_travel} onChange={handleInputChange} onBlur={handleInputBlur} onKeyDown={handleInputKeyDown} style={{ width: '100%', minWidth: '300px' }}
                                InputProps={{endAdornment: (
                                    <InputAdornment position="end">
                                        <CheckCircleIcon color="primary" onClick={handleSaveClick} style={{ cursor: 'pointer' }} />
                                    </InputAdornment>),}}/>
                            ) : (editableData.category_of_travel)}
                        </TableCell>



                {/* For the mode of transport */}
                <TableCell align="center" sx={{ fontFamily: "Raleway", fontWeight: 500, color: editedFields.includes("mode_of_transport") ? "red" : "inherit",}} onDoubleClick={() => handleCellDoubleClick("mode_of_transport")}>
                            {isEditMode && selectedCell === 'mode_of_transport' ? (
                            <TextField inputRef={inputRef} multiline name="mode_of_transport" value={editableData.mode_of_transport} onChange={handleInputChange} onBlur={handleInputBlur} onKeyDown={handleInputKeyDown} style={{ width: '100%', minWidth: '300px' }}
                                InputProps={{endAdornment: (
                                    <InputAdornment position="end">
                                        <CheckCircleIcon color="primary" onClick={handleSaveClick} style={{ cursor: 'pointer' }} />
                                    </InputAdornment>),}}/>
                            ) : (editableData.mode_of_transport)}
                        </TableCell>


                {/* For the source of information */}

                <TableCell align="center" sx={{ fontFamily: "Raleway", fontWeight: 500, color: editedFields.includes("source_of_information") ? "red" : "inherit",}} onDoubleClick={() => handleCellDoubleClick("source_of_information")}>
                            {isEditMode && selectedCell === 'source_of_information' ? (
                            <TextField inputRef={inputRef} multiline name="source_of_information" value={editableData.source_of_information} onChange={handleInputChange} onBlur={handleInputBlur} onKeyDown={handleInputKeyDown} style={{ width: '100%', minWidth: '300px' }}
                                InputProps={{endAdornment: (
                                    <InputAdornment position="end">
                                        <CheckCircleIcon color="primary" onClick={handleSaveClick} style={{ cursor: 'pointer' }} />
                                    </InputAdornment>),}}/>
                            ) : (editableData.source_of_information)}
                </TableCell>

                </TableRow>
            </TableBody>
            </Table>
        </TableContainer>

        <Button variant='contained' onClick={handleSubmitInfo}>Submit Changed Parent Information</Button>
    </div>
  )
}

export default ParentInfo