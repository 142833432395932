import React,{useState} from 'react';
import NewParentInfo from '../../Components/lead_information_components/NewParentInfo/NewParentInfo';
import NewPetInfo from '../../Components/lead_information_components/NewPetInfo/NewPetInfo';
import ParentInfo from '../../Components/lead_information_components/ParentInfo/ParentInfo';
import Navbar from '../../Components/Navbar/Navbar';

function NewForm() {
  
  const [parentSubmitted, setParentSubmitted] = useState(false)
  
  return (
    <div>
        {parentSubmitted?(
        <div>
            
            <NewPetInfo setParentSubmitted={setParentSubmitted}/>

        </div>
       
        ):(<NewParentInfo setParentSubmitted={setParentSubmitted}/>)}
        

        
    </div>
  )
}

export default NewForm