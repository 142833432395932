import React from 'react';
import './PopUp.css';
import { Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const PopUp = ({ content: Content, heading, close, ...props }) => {
  return (
    <>
      <div className='popup-wrapper' onClick={() => close(false)}></div>
      <div className='popup-div'>
        <div className='popup-heading'>
          <h1>{heading}</h1>
          <IconButton onClick={() => close(false)} variant='contained'>
            <CloseIcon />
          </IconButton>
        </div>
        <Content {...props} /> {/* Pass all the props to the Content component */}
      </div>
    </>
  );
};

export default PopUp;