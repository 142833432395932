import React, { useState } from 'react';
import './AddLeads.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, InputLabel, Select, MenuItem } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';





function AddLeads() {

 

 const [leadId, setLeadId] = useState("");  
 const [name, setName] = useState("");
 const [email, setEmail] = useState("");
 const [mobile, setMobile] = useState("");
 const [sourceOfInformation, setSourceOfInformation] = useState("");
 const [categoryOfTravel, setCategoryOfTravel] =useState("");
 const [modeOfTransport, setModeOfTransport] = useState("");
 const [exitPort, setExitPort] = useState("");
 const [entryPort, setEntryPort] = useState("");
 const [indiaAddress, setIndiaAddress] = useState("");
 const [foreignAddress, setForeignAddress] = useState(""); 

 const submitForm=()=>{
    console.info(leadId)
    console.info(name)
    console.info(email)
    console.info(mobile)
    console.info(sourceOfInformation)
    console.info(categoryOfTravel)
    console.info(modeOfTransport)
    console.info(exitPort)
    console.info(entryPort)
    console.info(indiaAddress)
    console.info(foreignAddress)
    }

 

  return (
    <div className='add_leads_page'>
        <div className='add_leads_header'>
        <h1>Petspot Lead Form</h1>
        <Button onClick={()=>{submitForm()}}>Submit</Button>
        </div>  
        <div className='add_leads_boxes'>
        <div className='first_box'>
        <Box
        sx={{
            width: 500,
            maxWidth: '100%',
        }}
        >
        <TextField 
            id="lead_id" 
            label="What is the old lead_id of the pet's owner" 
            variant="standard" 
            fullWidth={true}
            required={true}
            value={leadId}
            onChange={(event) => {
                setLeadId(event.target.value);
            }}/>


        <TextField 
            id="name" 
            label="What is the name of the pet's owner" 
            variant="standard" 
            fullWidth={true}
            required={true}
            value={name}
            onChange={(event) => {
                setName(event.target.value);
            }}/>

        <TextField 
            id="mobile" 
            label="Mobile Number of the pet's owner" 
            variant="standard" 
            fullWidth={true}
            required={true}
            value={mobile}
            onChange={(event) => {
                setMobile(event.target.value);
            }}/>  

        <TextField 
            id="email" 
            label="Email of the pet's owner" 
            variant="standard" 
            fullWidth={true}
            required={true}
            value={email}
            onChange={(event) => {
                setEmail(event.target.value);
            }}/>  

        <p></p>

        <InputLabel id="source_of_information">Where did they get to know about petspot?</InputLabel>
            <Select
            labelId="source_of_information"
            id="source_of_information"
            value={sourceOfInformation}
            label="Where did they get to know about petspot?"
            onChange={(event) => {
                setSourceOfInformation(event.target.value);
            }}
            fullWidth={true}
            input={<OutlinedInput label="Source of Information" />}
            >
                <MenuItem value="Social Media">Social Media</MenuItem>
                <MenuItem value="Vet">Vet</MenuItem>
                <MenuItem value="Friends & Family">Friends & Family</MenuItem>
                <MenuItem value="Petspot Website">PetSpot Website</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
            
            </Select>
         
            <p></p>

            <InputLabel id="category_of_travel">What is the category of travel?</InputLabel>
                <Select
                labelId="category_of_travel"
                id="category_of_travel"
                value={categoryOfTravel}
                label="What is the category of travel?"
                onChange={(event) => {
                    setCategoryOfTravel(event.target.value);
                }}
                fullWidth={true}
                input={<OutlinedInput label="Category Of Travel" />}
                >
                    <MenuItem value="International Import (Foreign Country to India)">International Import (Foreign Country to India)</MenuItem>
                    <MenuItem value="International Export (India to Foreign Country)">International Export (India to Foreign Country)</MenuItem>
                    <MenuItem value="Domestic( Inside India)">Domestic( Inside India)</MenuItem>
                
                </Select>

                <p></p>

                <InputLabel id="mode_of_transport">What will be your mode of transport?</InputLabel>
                    <Select
                    labelId="mode_of_transport"
                    id="mode_of_transport"
                    value={modeOfTransport}
                    label="What will be your mode of transport?"
                    onChange={(event) => {
                        setModeOfTransport(event.target.value);
                    }}
                    fullWidth={true}
                    input={<OutlinedInput label="Mode Of Transport" />}
                    >
                        <MenuItem value="Excess Baggage (Accompanied)">Excess Baggage (Accompanied)</MenuItem>
                        <MenuItem value="Manifested Cargo (Unaccompanied)">Manifested Cargo (Unaccompanied)</MenuItem>
                        <MenuItem value="Don't know yet">Don't know yet</MenuItem>
                        
                    
                    </Select>

                    <TextField 
                        id="exit_port" 
                        label="What is your port of exit?(City - Origin Port)" 
                        variant="standard" 
                        fullWidth={true}
                        required={true}
                        value={exitPort}
                        onChange={(event) => {
                            setExitPort(event.target.value);
                        }}/> 

                    <TextField 
                        id="entry_port" 
                        label="What is your port of entry? (City - Distination Port)" 
                        variant="standard" 
                        fullWidth={true}
                        required={true}
                        value={entryPort}
                        onChange={(event) => {
                            setEntryPort(event.target.value);
                        }}/>   

                    <TextField 
                        id="india_address" 
                        label="Write your complete address in India" 
                        variant="standard" 
                        fullWidth={true}
                        required={true}
                        value={indiaAddress}
                        onChange={(event) => {
                            setIndiaAddress(event.target.value);
                        }}/>   

                    <TextField 
                        id="foreign_address" 
                        label="Write your complete address outside India" 
                        variant="standard" 
                        fullWidth={true}
                        required={true}
                        value={foreignAddress}
                        onChange={(event) => {
                            setForeignAddress(event.target.value);
                        }}/>   
        
        
        </Box>
        </div>

        <div className='second_box'>
        <Box
        sx={{
            width: 500,
            maxWidth: '100%',
        }}
        >
        <TextField 
            id="lead_id" 
            label="What is the old lead_id of the pet's owner" 
            variant="standard" 
            fullWidth={true}
            required={true}
            value={leadId}
            onChange={(event) => {
                setLeadId(event.target.value);
            }}/>


        <TextField 
            id="name" 
            label="What is the name of the pet's owner" 
            variant="standard" 
            fullWidth={true}
            required={true}
            value={name}
            onChange={(event) => {
                setName(event.target.value);
            }}/>

        <TextField 
            id="mobile" 
            label="Mobile Number of the pet's owner" 
            variant="standard" 
            fullWidth={true}
            required={true}
            value={mobile}
            onChange={(event) => {
                setMobile(event.target.value);
            }}/>  

        <TextField 
            id="email" 
            label="Email of the pet's owner" 
            variant="standard" 
            fullWidth={true}
            required={true}
            value={email}
            onChange={(event) => {
                setEmail(event.target.value);
            }}/>  

        <p></p>

        <InputLabel id="source_of_information">Where did they get to know about petspot?</InputLabel>
            <Select
            labelId="source_of_information"
            id="source_of_information"
            value={sourceOfInformation}
            label="Where did they get to know about petspot?"
            onChange={(event) => {
                setSourceOfInformation(event.target.value);
            }}
            fullWidth={true}
            input={<OutlinedInput label="Source of Information" />}
            >
                <MenuItem value="Social Media">Social Media</MenuItem>
                <MenuItem value="Vet">Vet</MenuItem>
                <MenuItem value="Friends & Family">Friends & Family</MenuItem>
                <MenuItem value="Petspot Website">PetSpot Website</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
            
            </Select>
         
            <p></p>

            <InputLabel id="category_of_travel">What is the category of travel?</InputLabel>
                <Select
                labelId="category_of_travel"
                id="category_of_travel"
                value={categoryOfTravel}
                label="What is the category of travel?"
                onChange={(event) => {
                    setCategoryOfTravel(event.target.value);
                }}
                fullWidth={true}
                input={<OutlinedInput label="Category Of Travel" />}
                >
                    <MenuItem value="International Import (Foreign Country to India)">International Import (Foreign Country to India)</MenuItem>
                    <MenuItem value="International Export (India to Foreign Country)">International Export (India to Foreign Country)</MenuItem>
                    <MenuItem value="Domestic( Inside India)">Domestic( Inside India)</MenuItem>
                
                </Select>

                <p></p>

                <InputLabel id="mode_of_transport">What will be your mode of transport?</InputLabel>
                    <Select
                    labelId="mode_of_transport"
                    id="mode_of_transport"
                    value={modeOfTransport}
                    label="What will be your mode of transport?"
                    onChange={(event) => {
                        setModeOfTransport(event.target.value);
                    }}
                    fullWidth={true}
                    input={<OutlinedInput label="Mode Of Transport" />}
                    >
                        <MenuItem value="Excess Baggage (Accompanied)">Excess Baggage (Accompanied)</MenuItem>
                        <MenuItem value="Manifested Cargo (Unaccompanied)">Manifested Cargo (Unaccompanied)</MenuItem>
                        <MenuItem value="Don't know yet">Don't know yet</MenuItem>
                        
                    
                    </Select>

                    <TextField 
                        id="exit_port" 
                        label="What is your port of exit?(City - Origin Port)" 
                        variant="standard" 
                        fullWidth={true}
                        required={true}
                        value={exitPort}
                        onChange={(event) => {
                            setExitPort(event.target.value);
                        }}/> 

                    <TextField 
                        id="entry_port" 
                        label="What is your port of entry? (City - Distination Port)" 
                        variant="standard" 
                        fullWidth={true}
                        required={true}
                        value={entryPort}
                        onChange={(event) => {
                            setEntryPort(event.target.value);
                        }}/>   

                    <TextField 
                        id="india_address" 
                        label="Write your complete address in India" 
                        variant="standard" 
                        fullWidth={true}
                        required={true}
                        value={indiaAddress}
                        onChange={(event) => {
                            setIndiaAddress(event.target.value);
                        }}/>   

                    <TextField 
                        id="foreign_address" 
                        label="Write your complete address outside India" 
                        variant="standard" 
                        fullWidth={true}
                        required={true}
                        value={foreignAddress}
                        onChange={(event) => {
                            setForeignAddress(event.target.value);
                        }}/>   
        
        
        </Box>
        </div>    
        </div>             
        
    </div>
  )
}

export default AddLeads