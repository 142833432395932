import React,{useState,useEffect} from 'react'
import './Expenses.css';
import { Button,TextField,InputAdornment } from '@mui/material';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import ExpenseCard from '../../Components/ExpensesComponents/Expense/ExpenseCard';
import Navbar from '../../Components/Navbar/Navbar';
import { useDispatch,useSelector } from 'react-redux';
import { addExpense, deleteExpense, getExpense,addExpenseTransaction } from '../../Store/actions';

import PopUp from '../../Components/PopUp/PopUp';
import AddExpenseForm from '../../Components/ExpensesComponents/AddExpenseForm/AddExpenseForm';
import ExpensePurchaseForm from '../../Components/ExpensesComponents/ExpensePurchaseForm/ExpensePurchaseForm';

const addButtonStyles = {
  padding: '1rem 2rem',
  background: '#76BFF3',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '250px',
  borderRadius: '30px',
  height: '60px',
  color: 'white',
  fontFamily: 'Raleway',
  fontSize: '1.3rem',
  fontWeight: '600',
};

const button_styles = {width:'150px',margin:'0 1rem',color:'white',fontFamily:'Raleway',fontSize:'1rem',fontWeight:700,letterSpacing:'0.1px',height:'35px',borderRadius:'10px'};

const Expenses = () => {

  const dispatch = useDispatch();

  const data = useSelector((state) => state.global.expenses);
  console.info("expenses data:",data)

  const [selectedItemId, setSelectedItemId] = useState(null);
  const [expensePurchaseStatus, setExpensePurchaseStatus] = useState(false);
  const [addExpenseStatus, setAddExpenseStatus] = useState(false);
  
  const handleDelete = (e,row) => {
    e.preventDefault();
    console.log("deleted id: ", row._id);
    dispatch(deleteExpense(row._id));
    dispatch(getExpense());
  }
  const handleAddExpense = (e,addForm) => {
    e.preventDefault();
    console.log("Add Expense form submitted ",addForm);
    dispatch(addExpense(addForm));
    setAddExpenseStatus(false);
    dispatch(getExpense());
  }
  
  const handlePurchaseFormSubmit = (purchaseFormData) => {
    console.log(selectedItemId);
    purchaseFormData.expense_id = selectedItemId;
    purchaseFormData.purchase_flag = "Expense";
    console.log("Purchase Expense form submitted ", purchaseFormData);
    dispatch(addExpenseTransaction(purchaseFormData));
    setExpensePurchaseStatus(false);
    
  };
  
  const handleAddPurchase = (id) => {
    console.log(id);
    setSelectedItemId(id);
    setExpensePurchaseStatus(!expensePurchaseStatus);
  };

  useEffect(()=>{
    dispatch(getExpense());
  },[dispatch])
  
  return (
    <div>
      
    <div className='inventory'>
        <div className='inventory-heading'>
            <h1>EXPENSES</h1>
            <Button onClick={()=>setAddExpenseStatus(!addExpenseStatus)} variant='contained' sx={addButtonStyles}>
              Add Expense <LibraryAddOutlinedIcon />
            </Button>
        </div>
        {addExpenseStatus && <AddExpenseForm handleAddItem={handleAddExpense} />}
        {expensePurchaseStatus && (
          <PopUp content={ExpensePurchaseForm} heading="Add Expense" close={setExpensePurchaseStatus} props={{ data, handlePurchaseFormSubmit, selectedItemId }} />
        )}
        <div className='inventory-items'>
            {data?.map((row) => (
              <ExpenseCard key={row._id} row={row} del={handleDelete} addPurchase={() => handleAddPurchase(row._id)} />
            ))}
        </div>
    </div>
    </div>
  )
}


export default Expenses