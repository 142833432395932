import React, { useEffect } from 'react'
import { TableContainer,TableBody,TableRow,TableHead,TableCell,Paper,Table } from '@mui/material';
import './ClientActions.css';
import { useSelector } from 'react-redux';



const ClientActions = () => {

 const action_data = useSelector((state)=>state.global.userActions)

 useEffect(()=>{
 },[action_data])

  return (
    <div className='lead-info-client-actions'>
        <h2 className='lead-info-client-actions-head'>Actions taken by the client</h2>
        {(action_data)?
        (<TableContainer component={Paper} sx={{borderRadius:'20px'}}>
            <Table stickyHeader  aria-label="sticky table" sx={{}}>
            <TableHead>
                <TableRow>
                    <TableCell component="th" align='center' sx={{fontWeight:'bold',fontFamily:'Raleway',width:'15%',border:'1px solid #bab7b7'}}>Date</TableCell>
                    <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway',width:'25%%',border:'1px solid #bab7b7'}}>Activity Type</TableCell>
                    <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway',width:'50%',border:'1px solid #bab7b7'}}>Activity</TableCell>
                    <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway',border:'1px solid #bab7b7'}}>Stakeholder</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {action_data.map((row) => (
                    <TableRow key={row.id}>
                        <TableCell align='center' sx={{fontWeight:500,fontFamily:'Raleway',width:'15%',border:'1px solid #bab7b7'}}>{row.created_at?.split('T')[0].split('-').reverse().join('-')}</TableCell>
                        <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway',border:'1px solid #bab7b7'}}>{row.activity_type}</TableCell>
                        <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway',border:'1px solid #bab7b7'}}>{row.activity_desc}</TableCell>
                        <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway',border:'1px solid #bab7b7'}}>{row.user_id}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>):
        <p>There are no actions registered with the CRM system as of now </p>
        }
    </div>
  )
}

export default ClientActions