import React, { useState, useRef, useEffect } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, MenuItem } from '@mui/material';
import { InputAdornment,Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { parseISO, format,isValid,startOfDay} from 'date-fns';
import { IconButton } from '@mui/material';
import './PetInfo.css';

import { useDispatch } from 'react-redux';
import PetTableCell from '../NewPetInfo/PetTableCell';
import PetTableDateCell from '../NewPetInfo/PetTableDateCell';
import { deletePetInformation, editPetInformation } from '../../../Store/actions';



const PetInfo = ({pets_info, lead_id}) => {
    
  
  const [editableData, setEditableData] = useState(
        Object.fromEntries(pets_info.map((pet) => [pet.id, { ...pet }]))
      );

  useEffect(()=>{
    setEditableData(Object.fromEntries(pets_info.map((pet) => [pet.id, { ...pet }])))
  },[pets_info])    
  
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedCells, setEditedCells] = useState({});
  const [selectedCell, setSelectedCell] = useState({});
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const inputRefs = useRef({});

  

  const [newPetData, setNewPetData] = useState({
    pet_name: '',
    pet_species: '',
    pet_breed: '',
    pet_color: '',
    pet_sex: '',
    pet_birth_date_str: '',
    microchip_number: '',
    crate_flag: '',
    anti_rabies_vaccination_date: '',
    dhppil_vaccination_date: '',
    pet_weight: 0.0,
    pet_length: 0.0,
    pet_height: 0.0,
    pet_elbow_height: 0.0,
    pet_width: 0.0,
    pet_and_crate_weight: 0.0,
  });

  const [newPetId, setNewPetId] = useState('1');



  const handleDeleteRow = (petId) => {
    // Create a copy of the current editableData
    const updatedData = { ...editableData };

    // Create a data from updated data with pet_id
    const dataToDelete = Object.values(updatedData).find((data) => data.pet_id === petId);


    // Delete the row with the specified petId
    delete updatedData[petId];

    dispatch(deletePetInformation(dataToDelete['_id']))
    // Update the state with the new data
    setEditableData(updatedData);
  };

  // Set the initial value for newPetId when the component mounts or pets_info changes
 

  const handleAddRow = () => {
    // Convert the newPetId to a number for incrementing
    const nextPetIdNumber = parseInt(newPetId) + 1;
    
  
    // Add the new pet data to the state.
    // You can perform validation here if required before adding the data.
    setEditableData((prevEditableData) => ({
      ...prevEditableData,
      [newPetId]: { ...newPetData, id: newPetId },
    }));
  
    // Increment the pet id for the next row and convert back to a string
    setNewPetId(String(nextPetIdNumber));

    
  };


  const handleInputChange = (event, petId) => {
    const { name, value } = event.target;
    setEditableData((prevEditableData) => ({
      ...prevEditableData,
      [petId]: {
        ...prevEditableData[petId],
        [name]: value,
      },
    }));

    // Update the edited cell positions for the specific pet ID
    setEditedCells((prevEditedCells) => ({
      ...prevEditedCells,
      [petId]: [...(prevEditedCells[petId] || []), `${petId}_${name}`],
    }));
  };
  

  const handleSubmitInfo = () =>{
   
      console.log("lead_id",lead_id)
      // Extract the required data from editableData
      const newData = Object.values(editableData);
      // Dispatch the action with the extracted data
      dispatch(editPetInformation(newData));
   
    setEditedCells([]);

  }


  const handleCellDoubleClick = (cellData, petId) => {
    setSelectedCell({ cellData: cellData, petId: petId });
    setIsEditMode(true);
  };
  useEffect(() => {
    if (isEditMode && selectedCell) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [isEditMode, selectedCell]);


  


  const handleInputBlur = () => {
    setIsEditMode(false);
    setSelectedCell(null);
  };

  const handleInputKeyDown = (event) => {
    // Allow Enter key to trigger save and exit edit mode
    if (event.key === "Enter") {
      event.preventDefault();
      setIsEditMode(false);
      setSelectedCell(null);
    }
  };

  const handleSaveClick = () => {
    // Here, you can perform any action or API call to save the updated data.
    // For now, we'll simply update the state with the edited data and exit the edit mode.
    setIsEditMode(false);
    setSelectedCell(null);
  };




  const formatDateForPicker = (dateString) => {
    const dateObject = parseISO(dateString, 'dd/MM/yyyy', new Date());
    return isValid(dateObject) ? dateObject : null;
  };

  return (
    <>{pets_info?
    <div>
        <div className='add-button-container'>
            <Button variant='contained' onClick={handleAddRow} sx={{ margin: '1rem 0' }}>Add Row</Button>
        </div>
        <TableContainer component={Paper} sx={{padding: '0px 0px',border: '2px solid black',backgroundColor: 'lightgreen',fontSize: '1.1rem',margin: '1rem 0'}}>
            <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                <TableCell component="th" align='center' sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Pet Name</TableCell>
                <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Species</TableCell>
                <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Breed</TableCell>
                <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Color</TableCell>
                <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Sex-Neutered? </TableCell>
                <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>DOB</TableCell>
                <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Microchip No.</TableCell>
                <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Crate</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.values(editableData).map((row) => (
                    <TableRow key={row.id}>

                        <PetTableCell field="pet_name" value={editableData[row.id]?.pet_name}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />
                        <PetTableCell field="pet_species" value={editableData[row.id]?.pet_species}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />
                        <PetTableCell field="pet_breed" value={editableData[row.id]?.pet_breed}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />
                        <PetTableCell field="pet_color" value={editableData[row.id]?.pet_color}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />
                        <PetTableCell field="pet_sex" value={editableData[row.id]?.pet_sex}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />
                        <PetTableDateCell field="pet_birth_date_str" value={editableData[row.id]?.pet_birth_date_str}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />            
                        <PetTableCell field="microchip_number" value={editableData[row.id]?.microchip_number}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />
                        <PetTableCell field="crate_flag" value={editableData[row.id]?.crate_flag}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />
                        <TableCell align="center">
                            <IconButton color="black" onClick={() => handleDeleteRow(row.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>
 
                    </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
        <TableContainer component={Paper} sx={{padding: '0px 0px',border: '2px solid black',backgroundColor: 'lightgreen',fontSize: '1.1rem',margin: '1rem 0'}}>
            <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell align='center' sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Rabies Vaccination</TableCell>
                    <TableCell align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>DHPPIL Vaccination</TableCell>
                    <TableCell align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Weight</TableCell>
                    <TableCell align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Length</TableCell>
                    <TableCell align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Height</TableCell>
                    <TableCell align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Elbow Height</TableCell>
                    <TableCell align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Width</TableCell>
                    <TableCell align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Travel Weight</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.values(editableData).map((row) => (
                    <TableRow key={row.id}>
                        {/* For the rabies vaccine */}
                        <PetTableDateCell field="anti_rabies_vaccination_date" value={editableData[row.id]?.anti_rabies_vaccination_date}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />            
                        <PetTableDateCell field="dhppil_vaccination_date" value={editableData[row.id]?.dhppil_vaccination_date}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />            
                        <PetTableCell field="pet_weight" value={editableData[row.id]?.pet_weight}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />
                        <PetTableCell field="pet_length" value={editableData[row.id]?.pet_length}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />
                        <PetTableCell field="pet_height" value={editableData[row.id]?.pet_height}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />
                        <PetTableCell field="pet_width" value={editableData[row.id]?.pet_width}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />
                        <PetTableCell field="pet_and_crate_weight" value={editableData[row.id]?.pet_and_crate_weight}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />
                        
                    </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
        <Button variant='contained' onClick={handleSubmitInfo}>Submit Changed Pet Information</Button>
        
    </div>:null}
    </>
  )
}

export default PetInfo