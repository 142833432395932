import React from 'react';
import './Homepage.css';
import GoogleSignIn from '../../Components/GoogleSignIn/GoogleSignIn';

function Homepage() {
  return (
    <div>
        <GoogleSignIn/>
    </div>
  )
}

export default Homepage