import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { updateBaseDataFromGoogleSheet } from '../../Store/actions';
import { useDispatch, useSelector } from 'react-redux';


import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
// import petspotLogo from '../../public/petspotLogo.png'

const pages = ['My Leads'];

function Navbar() {
 
 const navigate = useNavigate();
//  const dispatch = useDispatch();

//  var user = useSelector((state) => state.global.user);
 

//  const refreshData = () =>{
//   // alert('going to refresh the data in the backend')
//   dispatch(updateBaseDataFromGoogleSheet({
//       email:user.email,
//   }))
//  }
  
 const cargo_agents = () =>{
  navigate('/cargo_agents')
 }

 const inventory = () =>{
  navigate('/inventory')
 }

 const expenses = () =>{
  navigate('/expenses')
 }

 const transactions = () =>{
  navigate('/finance')
 }
 const contactUs = () =>{
  navigate('/mailer')
 }

 const emailer = () =>{
  navigate('/mail')
 }

  function handleLink(page){
    switch(page) {
        case 'My Leads':
          console.info(page)
          navigate('/my_leads')
          break;
        case 'B2B Leads':
            console.info(page)
          navigate('/b2b_leads')
          break;
        case 'Follow Up Leads':
            console.info(page)
          navigate('/follow_up_leads')
          break;
        case 'New Lead':
            console.info(page)
          navigate('/new_leads')
          break;  
        default:
          navigate('/')
      }
  };


  return (
    <AppBar style={{height:'70px'}} position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
            

            {/* For the petspot header  */}
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/dashboard"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            PETSPOT
          </Typography>

          
          
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={()=>handleLink(page)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))}
            <Button
            onClick={()=>{cargo_agents()}}
            sx={{ my: 2, color: 'white', display: 'block' }}>Cargo Agents</Button>
            <Button
            onClick={()=>{inventory()}}
            sx={{ my: 2, color: 'white', display: 'block' }}>PetSpot Inventory</Button>
            <Button
            onClick={()=>{expenses()}}
            sx={{ my: 2, color: 'white', display: 'block' }}>PetSpot Expenses</Button>
            <Button
            onClick={()=>{transactions()}}
            sx={{ my: 2, color: 'white', display: 'block' }}>PetSpot Finances</Button>
            <Button
            onClick={()=>{emailer()}}
            sx={{ my: 2, color: 'white', display: 'block' }}>Mail Template</Button>
            {/* <Button
            onClick={()=>{refreshData()}}
            sx={{ my: 2, color: 'white', display: 'block' }}>Refresh Data</Button> */}
            
          </Box>

          
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;