import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { global_url } from '../../Global/GlobalUrl';
import axios from 'axios';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button } from '@mui/material';
import './NewMailer.css';
import NewMail from '../../Components/NewMail/NewMail';
import TemplateMail from '../../Components/TemplateMail/TemplateMail';
import ViewEmail from '../../Components/ViewEmail/ViewEmail';
import { getGmailMailsURL } from '../../Global/GlobalVariables';

const buttonStyles = {
  borderRadius: '0',
  borderColor: '#E0E0E0',
  color: '#333',
  fontSize: '1rem',
  fontFamily: 'Raleway',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '140%',
  padding: '0.5rem 1rem',
};

function parseDate(dateString) {
  const parts = dateString.split(' ');
  const datePart = parts.slice(1, 4).join(' ');
  const timePart = parts[4];

  return `${datePart} ${timePart}`;
}

function formatDate(dateString, format, timeZone) {
  return new Intl.DateTimeFormat('en-IN', {
    timeZone,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  }).format(new Date(parseDate(dateString)));
}

const EmailList = ({ pets_info, parent_info }) => {
  const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [viewEmail, setViewEmail] = useState(false);
  const [loading, setLoading] = useState(true); // Added loading state
  console.log("Emails", emails);
  const user = useSelector((state) => state.global.user);
  

  const fetchEmails = async () => {
    setTransactionType("Inbox");
    setLoading(true);
    try {
      
      const url = `${global_url}/${getGmailMailsURL}/${user.email}`;
  
      const response = await axios.post(url);
      if (response.status !== 200) {
        throw new Error('Failed to fetch emails');
      }
  
      const data = response.data;
      setEmails(data); // Replace the old emails with the new ones
      setViewEmail(true);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching emails:', error);
      setEmails([]); // Clear the email list in case of an error
      setLoading(false);
    }
  };

  const displayEmail = (email) =>{
    setSelectedEmail(email);
    setTransactionType("View Mail")

  }

  const [transactionType, setTransactionType] = useState("Inbox");

  // Use useEffect to fetch emails when the component mounts
  useEffect(() => {
    fetchEmails();
  }, []); // Empty dependency array means this effect runs only once on mount

  return (
    <div className='email-container'>
      <div className='email-button-holder'>
        <div className='email-buttons'>
          <Button
            onClick={() => fetchEmails()}
            sx={{
              ...buttonStyles,
              background: transactionType === "Inbox" ? '#E0E0E0' : 'none',
              "&:hover": { background: transactionType === "Inbox" ? '#E0E0E0' : 'none' }
            }}
            variant='outlined'
          >
            MailBox
          </Button>
          <Button
            onClick={() => setTransactionType("Compose")}
            sx={{
              ...buttonStyles,
              background: transactionType === "Compose" ? '#E0E0E0' : 'none',
              "&:hover": { background: transactionType === "Compose" ? '#E0E0E0' : 'none' }
            }}
            variant='outlined'
          >
            Compose
          </Button>
          <Button
            onClick={() => setTransactionType("Use Template")}
            sx={{
              ...buttonStyles,
              background: transactionType === "Use Template" ? '#E0E0E0' : 'none',
              "&:hover": { background: transactionType === "Use Template" ? '#E0E0E0' : 'none' }
            }}
            variant='outlined'
          >
            Use Template
          </Button>
        </div>
      </div>

      {loading ? ( // Display loading state
        <div>Loading...</div>
      ) : (
        <div className='email-content-container'>
          <div>
          {transactionType === "Inbox" && (
            <div className="table-container">
             <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
                <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                
                <TableBody>
                  {viewEmail && 
                    emails
                      ?.sort((a, b) => {
                        // Convert date strings to Date objects for comparison
                        const dateA = new Date(a.date);
                        const dateB = new Date(b.date);

                        // Compare the Date objects (latest to oldest)
                        return dateB - dateA;
                      })
                      .map((email) => (
                        <TableRow key={email.message_id}>
                          <TableCell
                            align="center"
                            sx={{
                              fontWeight: 500,
                              fontFamily: 'Raleway',
                              marginLeft: '50px',
                              textAlign: 'left',
                            }}
                          >
                            Date:
                            {formatDate(email.date, 'dd-MM-yyyy hh:mm a', 'Asia/Kolkata')}
                            <br />
                            <br />
                            Subject: {email.subject} <br />
                            <br />
                            From: {email.from}
                            <br />
                            <br />
                            To: {email.to}
                            <br />
                            <br />
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={{
                              fontWeight: 500,
                              fontFamily: 'Raleway',
                              maxWidth: '400px',
                              alignContent: 'flex-end',
                            }}
                          >
                            <Button
                              onClick={() => displayEmail(email)}
                              sx={{ fontSize: '13px' }}
                            >
                              View Email
                            </Button>
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={{
                              fontWeight: 500,
                              fontFamily: 'Raleway',
                              maxWidth: '400px',
                              alignContent: 'flex-end',
                            }}
                          >
                            {email.snippet}
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>


                </Table>
              </TableContainer>

            </div>
          )}


            {transactionType === "Compose" && <NewMail parent_info={parent_info} />}
            {transactionType === "Use Template" && <TemplateMail parent_info={parent_info} />}
            {transactionType === "View Mail" && <ViewEmail email={selectedEmail} setTransactionType ={setTransactionType} />}
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailList;
