import React, { useState,useRef, useEffect } from 'react'
import './TemplateMail.css';
import {useNavigate} from 'react-router-dom';
import AttachFileSharpIcon from '@mui/icons-material/AttachFileSharp';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import LinkIcon from '@mui/icons-material/Link';
import ClearIcon from '@mui/icons-material/Clear';

import { useDispatch, useSelector } from 'react-redux';
import { getEmailTemplates } from '../../Store/actions';
import {Button,FormControl,InputLabel,Select,MenuItem,IconButton, TextField} from '@mui/material';

const editStyles = {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    color: 'black',
    fontFamily: 'Raleway',
    position: 'relative',
}

const TemplateMail = ({parent_info}) => {
    const textEditorRef = useRef();
    const navigate = useNavigate();
    
    const [ccbcc, setCcbcc] = useState(false);
    const [files, setFiles] = useState([]);
    
    const existing_email_templates = useSelector((state)=>state.global.emailTemplates)
    const [selectedOption, setSelectedOption] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        if (existing_email_templates.length > 0) {
          setSelectedOption(existing_email_templates[0].templateName);
        }
      }, [existing_email_templates]);
      
    useEffect(()=>{
        dispatch(getEmailTemplates())
    },[])

    const [mailData, setmailData] = useState({from:'harshitgupta@gmail.com'});
    
    const user = useSelector((state)=>state.global.user)

    const handleInsert = (e) => {
        const list = e.target.files;
        console.log(list);
        console.log("array", Object.values(list));
        // Use the spread operator to concatenate the new files with the existing ones
        setFiles((prevFiles) => [...prevFiles, ...Object.values(list)]);
      }

    const handleTemplateChange = (e) => {
        const selectedValue = e.target.value;
        const foundTemplate = existing_email_templates.find((template) => template.templateName === selectedValue);
      
        console.log("select Template", foundTemplate)       
        if (foundTemplate) {
          setSelectedOption(selectedValue);
          setmailData({
            ...mailData,
            subject: foundTemplate.subject, // Set subject from the found template
            message: foundTemplate.body, // Set message from the found template
          });
        } else {
          // Handle the case where no template is found with the selected name
          setSelectedOption('');
          setmailData({
            ...mailData,
            subject: '', // Clear subject
            message: '', // Clear message
          });
        }
      };
     
      console.log("mail data", mailData)
      
    
    const handleSubmit = (e) => {
        console.log(mailData);
        console.log(files);
        navigate('/petspot_mailer/inbox')
    }
  return (
    <div className='inbox-container'>
        
        <div>
            <FormControl fullWidth sx={{ marginTop: 3 }}>
                <InputLabel id="select-option-label">Select the mail template</InputLabel>      
                <Select
                    labelId="select-option-label"
                    variant='standard'
                    id="select-option"
                    value={selectedOption}
                    // fullWidth
                    onChange={(e) => handleTemplateChange(e)}
                            
                >
                            
                        {existing_email_templates.map((option) => (
                            <MenuItem key={option.id} value={option.templateName} defaultValue={option.templateName}>
                                {option.templateName}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>            
        </div>    

        <div className='template-mail'>
            <span className='new-mail-input'>
                <label>From : </label>
                <input readOnly value={user?.email} />
            </span>
            <hr />
            <span className='new-mail-input'>
                <label>To : </label>
                <input onChange={(e) => setmailData({...mailData,to:e.target.value})} />
                <IconButton onClick={()=>setCcbcc(true)} size='small' sx={{marginRight:'1rem',display: ccbcc ? 'none':'inline'}}>CC/BCC</IconButton>
            </span>
            {ccbcc && <div>
                <hr />
                <span className='new-mail-input'>
                    <label>CC : </label>
                    <input onChange={(e) => setmailData({...mailData,cc:e.target.value})} />
                </span>
                <hr />
                <span className='new-mail-input'>
                    <label>BCC : </label>
                    <input onChange={(e) => setmailData({...mailData,bcc:e.target.value})} />
                </span>
            </div>}
            <hr />
            <input onChange={(e) => setmailData({...mailData,subject:e.target.value})} value={mailData.subject || ''} className='new-mail-subject' placeholder='Subject' />
            <hr />
            <div className='new-mail-files'>
                {files?.map((file,index) => (
                    <div className='new-mail-file-card'>
                        <p><LinkIcon />{file?.name}<IconButton sx={{height:'20px'}} onClick={(e)=>setFiles(files?.filter((file,ind)=> index!=ind))}><ClearIcon /></IconButton></p>
                    </div>
                ))}
            </div>
            <div>
            <TextField
                    id="body-textfield"
                    
                    multiline
                    fullWidth
                    value={mailData.message}
                    minRows={15}
                    onChange={(e) => setmailData({ ...mailData, message: e.target.value })}
                    sx={{
                      width: '100%',
                      fontFamily: 'Raleway',
                      color: '#000',
                      fontSize: '1.1rem',
                      fontWeight: 200,
                      minHeight: '10em',
                      padding: '10px',
                    }}
                    inputProps={{
                      style: {
                        overflow: 'hidden',
                      },
                    }}
                  />
                
                
            </div>
            
        </div>
        <div className='new-mail-functions'>
            <div className='new-mail-edit'>
                <Button sx={editStyles}><AttachFileSharpIcon />Insert Files<input onChange={handleInsert} multiple type='file' className='upload-button' /></Button>
                
            </div>
            <Button onClick={handleSubmit} sx={{...editStyles,fontWeight: '700'}}>Send <SendRoundedIcon /></Button>
        </div>
    </div>
  )
}

export default TemplateMail