import React, { useState } from 'react';
import { TextField, InputAdornment, Button } from '@mui/material';

const buttonStyles = {
  width: '160px',
  margin: '0 1rem',
  color: 'white',
  fontFamily: 'Raleway',
  fontSize: '1rem',
  fontWeight: 700,
  letterSpacing: '0.1px',
  height: '35px',
  borderRadius: '10px'
};

const ExpensePurchaseForm = ({ props }) => {
  const [fileURL, setFileURL] = useState(null);
  const { data, handlePurchaseFormSubmit, selectedItemId } = props;
  const [formData, setFormData] = useState({
    qty: 0,
    date: '',
    total_amt: 0,
    amount: 0,
    gst: 0,
    image: null,
    pdf: null
  });

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handlePurchaseFormSubmit(formData);
  };

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   setAddForm({...addForm,file:file,image:null}); 
  //   const file_url = URL.createObjectURL(file);
  //   setFileURL(file_url);
  //   const file = e.target.files[0];
  //   setFormData({ ...formData, [e.target.name]: file });
  // };

  return (
    <form className='inventory-edit-form'>
      <h3>Name: {data?.filter((it) => it?.item?._id === selectedItemId)?.[0]?.item?.item_name}</h3>
      <div className='edit-form-qty'>
        <span className='edit-form-cell'>
          <label>Quantity</label>
          <TextField
            type='number'
            size='small'
            value={formData.qty}
            onChange={(e) => setFormData({ ...formData, qty: e.target.value })}
            inputProps={{ min: 0 }}
            sx={{ width: '300px' }}
          />
        </span>
        <span className='edit-form-cell'>
          <label>Date</label>
          <TextField
            type='date'
            size='small'
            value={formData.date}
            onChange={(e) => setFormData({ ...formData, date: e.target.value })}
            sx={{ width: '300px' }}
          />
        </span>
      </div>
      <span className='edit-form-cell2'>
        <label>Total Amount (Excluding GST)</label>
        <TextField
          type='number'
          size='small'
          value={formData.amount}
          onChange={(e) => setFormData({ ...formData, amount: e.target.value })}
          sx={{ width: '300px' }}
          InputProps={{
            endAdornment: <InputAdornment position='end'>₹ (INR)</InputAdornment>
          }}
        />
      </span>
      <span className='edit-form-cell2'>
        <label>GST</label>
        <TextField
          type='number'
          size='small'
          value={formData.gst}
          onChange={(e) => setFormData({ ...formData, gst: e.target.value })}
          sx={{ width: '300px' }}
          InputProps={{
            endAdornment: <InputAdornment position='end'>₹ (INR)</InputAdornment>
          }}
        />
      </span>
      <span className='edit-form-cell2'>
        <label>Total Amount</label>
        <TextField
          type='number'
          size='small'
          value={formData.total_amt}
          onChange={(e) => setFormData({ ...formData, total_amt: e.target.value })}
          sx={{ width: '300px' }}
          InputProps={{
            endAdornment: <InputAdornment position='end'>₹ (INR)</InputAdornment>
          }}
        />
      </span>
      
      <span className='edit-form-cell2'>
        <label>Mode of Payment</label>
        <select
          value={formData.mode_of_payment}
          onChange={(e) => setFormData({ ...formData, mode_of_payment: e.target.value })}
        >
          <option value=''>--Please choose an option--</option>
          <option value='Cash'>Cash</option>
          <option value='UPI'>UPI</option>
          <option value='Online'>Online</option>
          <option value='Card'>Card</option>
        </select>
      </span>
      <div className='edit-form-buttons'>
        <div className='add-form-file'>
          <Button variant='contained' component='label' sx={{ ...buttonStyles, background: '#76BFF3' }}>
            Upload Image
            <input
              type='file'
              name='image'
              accept='image/*'
              hidden
              onChange={(e)=>{
                const file = e.target.files[0];
                setFormData({...formData,file:file,image:null}); 
                const file_url = URL.createObjectURL(file);
                setFileURL(file_url);
              }} />
          </Button>
          <h3>OR</h3>
          <Button variant='contained' component='label' sx={{ ...buttonStyles, background: '#76BFF3' }}>
            Upload PDF
            <input
              type='file'
              name='file'
              accept='application/pdf'
              hidden
              onChange={(e)=>{
                const file = e.target.files[0];
                setFormData({...formData,file:file,image:null}); 
                const file_url = URL.createObjectURL(file);
                setFileURL(file_url);
              }} />
          </Button>
        </div>
        <Button
          onClick={handleFormSubmit}
          variant='contained'
          sx={{ ...buttonStyles, background: '#76F4B5' }}
        >
          Submit
        </Button>
      </div>
      <div className='add-item-preview'>
        <h3>Preview</h3>
        <embed src={fileURL} type="application/pdf" />
      </div>
    </form>
  );
};

export default ExpensePurchaseForm;