import React,{useEffect, useState} from 'react'
import './ActionableItems.css';
import { Button,TextField,InputAdornment } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import PopUp from '../../PopUp/PopUp';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getExpense, getPaymentsForLead, postPayment } from '../../../Store/actions';
import { format } from 'date-fns';
import { MenuItem, Select } from '@mui/material';

const button_styles = {borderRadius: '1rem',
    border: '1px solid #000',
    width: '94%',
    padding: '0.75rem',
    color: '#000',
    textAlign: 'center',
    fontSize: '0.9rem',
    fontFamily: 'Raleway',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal'
};

const button_styles2 = {width:'150px',margin:'0 1rem',color:'white',fontFamily:'Raleway',fontSize:'1rem',fontWeight:700,letterSpacing:'0.1px',height:'35px',borderRadius:'10px'};

const openInNewWindow=(parent_data,pets_data,url)=>{
    const dataToPass = {
        parent_info: {parent_data},
        pet_info: {pets_data},
      };
    
      // Store the data in localStorage before opening the new tab
      localStorage.setItem('leadData', JSON.stringify(dataToPass));
    window.open(url, '_blank');
}

const PaymentPop = (props) => { // Remove the 'setPayment' from function argument
    const dispatch = useDispatch();
    const leadId = useSelector((state) => state.global.leadId);
    const [formData, setFormData] = useState({ date: '', amount: '',payment_mode:'Cash', lead_id: leadId });
  
    const handleFormSubmit = (e) => {
      e.preventDefault();
      console.log(formData);
  
      // Convert the date string to a datetime object before sending to the backend
      const formattedDate = formData.date ? format(new Date(formData.date), "yyyy-MM-dd'T'HH:mm:ss") : null;
  
      dispatch(postPayment({ ...formData, date: formattedDate }));
      setFormData({ date: '', amount: '',payment_mode:'Cash', lead_id: leadId });
      dispatch(getPaymentsForLead(leadId))
      props.setPayment(false); // Call 'setPayment' from props to close the popup
    };

    return (
        <div>
            
            <form className='register-payment-form'>
                <span className='edit-form-cell'>
                    <label>Date</label>
                    <TextField
                        type='date' // Use 'datetime-local' input type to get the date and time
                        size='small'
                        value={formData.date}
                        onChange={(e) => {
                            setFormData({ ...formData, date: e.target.value });
                        }}
                        sx={{ width: '300px' }}
                    />
                </span>
                <span className='edit-form-cell'>
                    <label>Total Amount</label>
                    <TextField
                        type='text'
                        size='small'
                        value={formData.amount}
                        onChange={(e) => {
                            setFormData({ ...formData, amount: e.target.value });
                        }}
                        sx={{ width: '300px' }}
                        InputProps={{
                            endAdornment: <InputAdornment>₹ (INR)</InputAdornment>,
                        }}
                    />
                </span>
                <span className='edit-form-cell'>
                    <label>Payment Mode</label>
                    <Select
                        labelId="payment_pop_payment_mode"
                        id="payment_mode"
                        value={formData.payment_mode}
                        label="Payment Mode"
                        onChange={(e) => {
                            setFormData({ ...formData, payment_mode: e.target.value });
                        }}
                        sx={{ width: '300px' }}
                    >
                        <MenuItem value={'Cash'}>Cash</MenuItem>
                        <MenuItem value={'Online'}>Online</MenuItem>
                        <MenuItem value={'UPI'}>UPI</MenuItem>
                        <MenuItem value={'Card'}>Card</MenuItem>
                    </Select>
                </span>
                <Button onClick={handleFormSubmit} variant='contained' sx={{ ...button_styles2, background: '#76F4B5', '&:hover': { background: '#76F4B5' } }}>
                    Submit
                </Button>
            </form>
        </div>
    );
};


const CargoPop = () => {
    return (
        <div className='lead-info-actionable-items-cell'>
            <p clickable="true">Get Query for the cargo details<ArrowRightIcon /></p>
            <hr />
            <p clickable="true">Send Booking on the cargo<ArrowRightIcon /></p>
        </div>
    )
}
const FailPop = () => {
    return (
        <div className='lead-info-actionable-items-cell'>
            <p clickable="true">Fail Lead<ArrowRightIcon /></p>
            <hr />
            <p clickable="true">Completed Lead<ArrowRightIcon /></p>
        </div>
    )
}



const ActionableItems = ({parent_data,pets_data}) => {

    const navigate = useNavigate();

    const [payment, setPayment] = useState(false);
    
    const [cargo, setCargo] = useState(false);
    const [failComplete, setFailComplete] = useState(false);


  return (
    <div className='lead-info-actionable-items'>
        <h2 className='lead-info-actionable-items-head'>Actionable Items</h2>
        <div className='lead-info-actionable-items-row'>
            <Button onClick={()=>openInNewWindow(parent_data,pets_data,'/invoice')} sx={button_styles}>Generate Performa Invoice</Button>
            <Button onClick={()=>setPayment(true)} sx={button_styles}>Register Payment</Button>
            
            <Button onClick={()=>setCargo(true)} sx={button_styles}>Cargo Activity</Button>
            <Button onClick={()=>setFailComplete(true)} sx={button_styles}>Fail/Completed Leads</Button>
        </div>
    
    {/* Basis the state, this will start the payment pop up */}
    {payment && (
        <PopUp
          heading="Register Payment"
          content={PaymentPop}
          close={() => setPayment(false)}
          setPayment={setPayment} // Pass 'setPayment' as a prop
        />
      )}
      
        {cargo && <PopUp heading="Cargo Activity" content={CargoPop} close={()=>setCargo(false)} />}
        {failComplete && <PopUp heading="Failed/Completed Leads" content={FailPop} close={()=>setFailComplete(false)} />}
    </div>
  )
}
export default ActionableItems