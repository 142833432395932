import React from 'react'
import './InventoryItem.css';

const InventoryItem = () => {
  return (
    <div className='inventory-item-container'>
      <span className='inventory-item-name'>
        <img alt='item' src='item-profile.png' />
        <h3>Item No. 1</h3>
      </span>
      <h3 className='inventory-item-status'>Out of Stock</h3>
    </div>
  )
}

export default InventoryItem