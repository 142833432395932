import React,{useState} from 'react'
import './AddItemForm.css';
import { Button, TextField } from '@mui/material';
const button_styles = {width:'160px',margin:'0 1rem',color:'white',fontFamily:'Raleway',fontSize:'1rem',fontWeight:700,letterSpacing:'0.1px',height:'35px',borderRadius:'10px'};

const AddItemForm = ({handleAddItem}) => {
    const [fileURL, setFileURL] = useState(null);
    const [addForm, setAddForm] = useState({item_name:"",description:"",company_name:"",qty:0})
    return (
    <div className='add-item-form-container'>
        <div className='add-item-preview'>
            <h3>Preview</h3>
            <embed src={fileURL} type="application/pdf" />
        </div>
        <form className='add-item-form'>
            <span className='add-form-cell2'>
                <label>Item Name</label>
                <TextField 
                    type='text'
                    size='small'
                    value={addForm.item_name}
                    onChange={(e)=>{setAddForm({...addForm,item_name:e.target.value})}}
                    sx={{width:'300px'}}
                />
            </span>
            <span className='add-form-cell2'>
                <label>Description</label>
                <TextField 
                    type='text'
                    size='small'
                    value={addForm.description}
                    onChange={(e)=>{setAddForm({...addForm,description:e.target.value})}}
                    sx={{width:'300px'}}
                />
            </span>
            <span className='add-form-cell2'>
                <label>Company Name</label>
                <TextField 
                    type='text'
                    size='small'
                    value={addForm.company_name}
                    onChange={(e)=>{setAddForm({...addForm,company_name:e.target.value})}}
                    sx={{width:'300px'}}
                />
            </span>
            <span className='add-form-cell2'>
                <label>Quantity</label>
                <TextField 
                    type='number'
                    size='small'
                    value={addForm.qty}
                    onChange={(e)=>{setAddForm({...addForm,qty:e.target.value})}}
                    inputProps={{ min: 0}}
                    sx={{width:'300px'}}
                />
            </span>
            <span className='add-form-cell2'>
                <label>Sale Amount</label>
                <TextField 
                    type='number'
                    size='small'
                    value={addForm.sale_price}
                    onChange={(e)=>{setAddForm({...addForm,sale_price:e.target.value})}}
                    inputProps={{ min: 0}}
                    sx={{width:'300px'}}
                />
            </span>
            <span className='add-form-cell2'>
                <label>GST</label>
                <TextField 
                    type='number'
                    size='small'
                    value={addForm.gst}
                    onChange={(e)=>{setAddForm({...addForm,gst:e.target.value})}}
                    inputProps={{ min: 0}}
                    sx={{width:'300px'}}
                />
            </span>
            <span className='add-form-cell2'>
                <label>Base Amount</label>
                <TextField 
                    required
                    type='number'
                    size='small'
                    value={addForm.sale_price_wo_gst}
                    onChange={(e)=>{setAddForm({...addForm,sale_price_wo_gst:e.target.value})}}
                    inputProps={{ min: 0}}
                    sx={{width:'300px'}}
                />
            </span>
            <div className='add-form-buttons'>
                <div className='add-form-file'>
                    <Button variant='contained' sx={{...button_styles,background:'#76BFF3',"&:hover":{background:'#76BFF3'}}}>
                        <input className='upload-button' onChange={(e) => {
                            const file = e.target.files[0];
                            setAddForm({...addForm,image:file,pdf:null}); 
                            const file_url = URL.createObjectURL(file);
                            setFileURL(file_url);
                            }} type='file' accept='image/*' 
                            files = {[addForm.image]} />
                        Upload Image
                    </Button>
                    <h3>OR</h3>
                    <Button variant='contained' sx={{...button_styles,background:'#76BFF3',"&:hover":{background:'#76BFF3'}}}>
                        <input className='upload-button' onChange={(e) => {
                            const file = e.target.files[0];
                            setAddForm({...addForm,pdf:file,image:null}); 
                            const file_url = URL.createObjectURL(file);
                            setFileURL(file_url);
                            }} type='file' accept='application/pdf'
                            files = {[addForm.file]} />
                        Upload Pdf
                    </Button>
                </div>
                <Button onClick={(e) => handleAddItem(e,addForm)} variant='contained' sx={{...button_styles,background:'#76F4B5',"&:hover":{background:'#76F4B5'}}}>Submit</Button>
            </div>
        </form>
    </div>
  )
}

export default AddItemForm