  import React, { useEffect, useState } from 'react';
  import './ItemInventory.css';
  import AddIcon from '@mui/icons-material/Add';
  import { Button, IconButton, Select, MenuItem, TextField } from '@mui/material';
  import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
  import { useDispatch, useSelector } from 'react-redux';
  import { getItems, deleteItemSale, postItemSale, getExpense, postExpenseSale, deleteExpenseSale } from '../../../Store/actions';
  import PopUp from '../../PopUp/PopUp';

  const ItemInventory = () => {
    const item_sale = useSelector((state) => state.global.itemSalesTransactions);
    const expense_sale = useSelector((state) => state.global.expenseSalesTransactions);
    const leadId = useSelector((state) => state.global.leadId);
    const dispatch = useDispatch();
    const [showAddItemPopup, setShowAddItemPopup] = useState(false);
    const [showAddExpensePopup, setShowAddExpensePopup] = useState(false);
    
    
    useEffect(() => {
      dispatch(getItems());
      dispatch(getExpense());
    }, []);

    const handleAddItem = () => {
      setShowAddItemPopup(true);
    };

    const handleAddExpense = () => {
      setShowAddExpensePopup(true);
    };

    const handleItemDelete = (e, id) => {
      e.preventDefault();
      console.log(e.target);
      console.log("Delete activated");
      dispatch(deleteItemSale(id))
    };

    const handleExpenseDelete = (e, id) => {
      e.preventDefault();
      console.log(e.target);
      console.log("Delete activated");
      dispatch(deleteExpenseSale(id))
    };

    const ItemCard = ({ name, add, date, qty, id, sale_price, gst }) => {
      return (
        <div onClick={add ? handleAddItem : undefined} add={add ? 'true' : 'false'} className='lead-info-item-inventory-item'>
          {add ? <AddIcon sx={{ fontSize: '50px' }} /> : null}
          <h4>{name}</h4>
          <div className='lead-info-item-inventory-item-info'>
            {!add && (
              <div>
                <h5>Sale : {date}</h5>
                <h5>Quantity : {qty}</h5>
                <h5>Sale Price : {sale_price}</h5>
                <h5>GST : {gst}</h5>
              </div>
            )}
            {!add && (
              <IconButton onClick={(e) => handleItemDelete(e, id)}>
                <DeleteForeverIcon sx={{ fontSize: '20px' }} />
              </IconButton>
            )}
          </div>
        </div>
      );
    };

    const ExpenseCard = ({ name, add, date, id, sale_price, petspot_expense }) => {
      return (
        <div onClick={add ? handleAddExpense : undefined} add={add ? 'true' : 'false'} className='lead-info-item-inventory-item'>
          {add ? <AddIcon sx={{ fontSize: '50px' }} /> : null}
          <h4>{name}</h4>
          <div className='lead-info-item-inventory-item-info'>
            {!add && (
              <div>
                <h5>Sale : {date}</h5>
                <h5>Sale Price : {sale_price}</h5>
                <h5>Petspot Expense : {petspot_expense}</h5>
              </div>
            )}
            {!add && (
              <IconButton onClick={(e) => handleExpenseDelete(e, id)}>
                <DeleteForeverIcon sx={{ fontSize: '20px' }} />
              </IconButton>
            )}
          </div>
        </div>
      );
    };

    return (
      
      <div>

        {/* Item Section */}
          <div className='lead-info-item-inventory'>
            <h2 className='lead-info-item-inventory-head'>Items used from the inventory for this lead</h2>
            <div className='lead-info-item-inventory-container'>
              {item_sale ? (
                item_sale?.map((item) => (
                  <ItemCard
                    key={item.id}
                    name={item.item_name}
                    date={item.created_at?.split('T')[0].split('-').reverse().join('-')}
                    qty={item.quantity}
                    sale_price={item.sale_price}
                    gst={item.gst}
                    id={item._id}
                  />
                ))
              ) : null}
              <ItemCard add name="Add Item" />
            </div>

            {/* Popup for adding item sale */}
            {showAddItemPopup && (
            <PopUp
            heading="Add Item Sale"
            content={() => <AddItemSaleForm close={() => setShowAddItemPopup(false)} setShowAddItemPopup={setShowAddItemPopup} />}
            close={() => setShowAddItemPopup(false)}
          />
          )}
          </div>

          {/* Service Section */}

          <div className='lead-info-expense-inventory'>
            <h2 className='lead-info-expense-inventory-head'>Expenses used from the inventory for this lead</h2>
            <div className='lead-info-expense-inventory-container'>
              {expense_sale ? (
                expense_sale?.map((expense) => (
                  <ExpenseCard
                    key={expense?._id}
                    name={expense?.expense_name}
                    date={expense?.created_at?.split('T')[0].split('-').reverse().join('-')}
                    sale_price={expense?.sale_price}
                    petspot_expense={expense?.petspot_expense}
                    id={expense?._id}
                  />
                ))
              ) : null}
              <ExpenseCard add name="Add Expense" />
            </div>

            {/* Popup for adding expense sale */}
            {showAddExpensePopup && (
            <PopUp
            heading="Add Expense Sale"
            content={() => <AddExpenseSaleForm close={() => setShowAddExpensePopup(false)} setShowAddExpensePopup={setShowAddExpensePopup} />}
            close={() => setShowAddExpensePopup(false)}
          />
          )}
          </div>
      </div>
    );
  };

  const AddItemSaleForm = ({ close, setShowAddItemPopup }) => {
    const items = useSelector((state) => state.global.items);
    const [selectedItem, setSelectedItem] = useState('');
    const [quantity, setQuantity] = useState(0);
    const leadId = useSelector((state) => state.global.leadId);

    
    const dispatch = useDispatch()

    const handleAddItemSale = (props) => {
      // Create the item sale data to send to the backend
      const itemSaleData = {
        item_id: selectedItem._id,
        user_id: 1, // Replace with the actual user_id
        quantity: parseInt(quantity, 10),
        lead_id: leadId,
      };

      console.log('item sale data',itemSaleData)
      // Dispatch action to post the item sale data to the backend
      dispatch(postItemSale(itemSaleData));
      setShowAddItemPopup(false);
    };

    return (
      <div className='register-new-lead-form'>
        {items?(
          <form className='register-payment-form'>
              <span className='edit-form-cell'>
                <label>Select Item</label>
                <Select
                    value={selectedItem}
                    size='small'
                    onChange={(e) => setSelectedItem(e.target.value)}
                    sx={{ width: '300px' }}
                  >
                    {items.map((item) => (
                      <MenuItem key={item._id} value={item}>
                        {item.item_name}
                      </MenuItem>
                    ))}
              </Select>  
              </span>
            
            <p>There is only {selectedItem.qty?selectedItem.qty:0} units available. So choose wisely </p> 
            
             

            <TextField 
            type="number" 
            value={quantity} 
            onChange={(e) => setQuantity(e.target.value)}
            sx={{ width: '300px' }} />
            <Button onClick={handleAddItemSale}>Add Item Sale</Button>
            </form>)
            :
              null
        }
        
      </div>
    );
  };

  const AddExpenseSaleForm = ({ close, setShowAddExpensePopup }) => {
    const expenses = useSelector((state) => state.global.expenses);
    const [selectedExpense, setSelectedExpense] = useState('');
    const [salePrice, setSalePrice] = useState(0);
    const [petspotCost, setPetspotCost] = useState(0);
    const leadId = useSelector((state) => state.global.leadId);

    console.log("expenses",expenses)  

    const dispatch = useDispatch()

    const handleAddExpenseSale = (props) => {
      // Create the expense sale data to send to the backend
      const expenseSaleData = {
        expense_id: selectedExpense._id,
        user_id: 1, // Replace with the actual user_id
        sale_price: parseFloat(salePrice),
        petspot_expense: parseFloat(petspotCost),
        lead_id: leadId,
      };

      console.log('expense sale data',expenseSaleData)
      // Dispatch action to post the expense sale data to the backend
      dispatch(postExpenseSale(expenseSaleData));
      setShowAddExpensePopup(false);
    };

    return (
      <div className='register-new-lead-form'>
        {expenses?(
          <form className='register-payment-form'>
              <span className='edit-form-cell'>
                <label>Select Expense</label>
                <Select
                    value={selectedExpense}
                    size='small'
                    onChange={(e) => setSelectedExpense(e.target.value)}
                    sx={{ width: '300px' }}
                  >
                    {expenses?.map((expense) => (
                      <MenuItem key={expense._id} value={expense}>
                        {expense.expense_name}
                      </MenuItem>
                    ))}
              </Select>  
              </span>
            
            <p>Please add the sale price for the customer </p> 
            
            <TextField 
            type="number" 
            value={salePrice} 
            onChange={(e) => setSalePrice(e.target.value)}
            sx={{ width: '300px' }} />

            <p>Please add the amount incurred by Petspot </p> 
            
            <TextField 
            type="number" 
            value={petspotCost} 
            onChange={(e) => setPetspotCost(e.target.value)}
            sx={{ width: '300px' }} />         


            <Button onClick={handleAddExpenseSale}>Add Expense Sale</Button>
            </form>)
            :
              null
        }
        
      </div>
    );
  };

  export default ItemInventory;
