import React,{useEffect, useState} from 'react'
import './Transactions.css';
import { Button, Table, TableContainer,TableCell,TableRow,TableBody,TableHead,Paper, IconButton } from '@mui/material';
import MenuItem from '../../Components/MenuItem/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import Navbar from '../../Components/Navbar/Navbar';
import { getExpenseTransaction, getItemSaleTransaction, getItemTransaction, getPayments, } from '../../Store/actions';
import { useDispatch, useSelector } from 'react-redux';



const buttonStyles= {
    borderRadius: '0',
    borderColor: '#E0E0E0',
    color: '#333',
    fontSize: '1rem',
    fontFamily: 'Raleway',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '140%',
    padding: '0.5rem 1rem'
};

const Transaction = ({row}) => {
    return (
        <TableRow key={row._id}>
            <TableCell align='center' sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.date}</TableCell>
            <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.type}</TableCell>
            <TableCell align='center' sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.send}</TableCell>
            <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.fee}</TableCell>
            <TableCell align='center' sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.recieve}</TableCell>
            <TableCell align="center" ><p style={{padding:'0.2rem 0rem',fontWeight:500,fontFamily:'Raleway',color:row?.status==="Pending" ? '#CB8A14' : '#359A73',borderRadius: '0.5rem',background: row?.status==="Pending" ? '#FFFAF1' : '#F5FBF8'}}>{row?.status}</p></TableCell>
            <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway'}}><IconButton><ModeEditOutlineOutlinedIcon /></IconButton></TableCell>
        </TableRow>
    )
}

const ExpenseTable = (props) =>{
    const data = props.data
    console.log("data in ExpenseTable",data)

    return(
        <TableContainer component={Paper} sx={{maxHeight:610}}>
            <Table stickyHeader  aria-label="sticky table" sx={{}}>
            <TableHead>
                <TableRow>
                    <TableCell component="th" align='center' sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Transaction Date</TableCell>
                    <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Type</TableCell>
                    <TableCell component="th" align='center' sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Expense Name</TableCell>
                    <TableCell component="th" align='center' sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Payment Mode</TableCell>
                    <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Amount</TableCell>
                    <TableCell component="th" align='center' sx={{fontWeight:'bold',fontFamily:'Raleway'}}>GST</TableCell>
                    <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Total Amount</TableCell>
                    <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data?.map((row) => (
                    <TableRow key={row._id}>
                        <TableCell align='center' sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.date?.split('T')[0].split('-').reverse().join('-')}</TableCell>
                        <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.purchase_flag}</TableCell>
                        <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.expense_name}</TableCell>
                        <TableCell align='center' sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.mode_of_payment}</TableCell>
                        <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.amount}</TableCell>
                        <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.gst}</TableCell>
                        <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.total_amt}</TableCell>
                        <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway'}}><IconButton><ModeEditOutlineOutlinedIcon /></IconButton></TableCell>
                    </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
    )
}

const PurchaseTable = (props) =>{
    const data = props.data
    console.log("data in PurchaseTable",data)
    
    return(
        <TableContainer component={Paper} sx={{maxHeight:610}}>
            <Table stickyHeader  aria-label="sticky table" sx={{}}>
            <TableHead>
                <TableRow>
                    <TableCell component="th" align='center' sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Transaction Date</TableCell>
                    <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Type</TableCell>
                    <TableCell component="th" align='center' sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Expense Name</TableCell>
                    <TableCell component="th" align='center' sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Payment Mode</TableCell>
                    <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Amount</TableCell>
                    <TableCell component="th" align='center' sx={{fontWeight:'bold',fontFamily:'Raleway'}}>GST</TableCell>
                    <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Total Amount</TableCell>
                    <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data?.map((row) => (
                    <TableRow key={row._id}>
                    <TableCell align='center' sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.date?.split('T')[0].split('-').reverse().join('-')}</TableCell>
                    <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.purchase_flag}</TableCell>
                    <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.item_name}</TableCell>
                    <TableCell align='center' sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.mode_of_payment}</TableCell>
                    <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.amount}</TableCell>
                    <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.gst}</TableCell>
                    <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.total_amt}</TableCell>
                    <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway'}}><IconButton><ModeEditOutlineOutlinedIcon /></IconButton></TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
    )
}

const SalesTable = (props) =>{
    const data = props.data
    console.log("data in SalesTable",data)

    return(
        <TableContainer component={Paper} sx={{maxHeight:610}}>
            <Table stickyHeader  aria-label="sticky table" sx={{}}>
            <TableHead>
                <TableRow>
                    <TableCell component="th" align='center' sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Transaction Date</TableCell>
                    <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Lead Name</TableCell>
                    <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Item Name</TableCell>
                    <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Type</TableCell>
                    <TableCell component="th" align='center' sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Amount</TableCell>
                    <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>GST</TableCell>
                    <TableCell component="th" align='center' sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Total Amount</TableCell>
                    <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data?.map((row) => (
                    <TableRow key={row._id}>
                        <TableCell align='center' sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.created_at?.split('T')[0].split('-').reverse().join('-')}</TableCell>
                        <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.lead_name}</TableCell>
                        <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.item_name}</TableCell>
                        <TableCell align='center' sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.status}</TableCell>
                        <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.sale_price_wo_gst}</TableCell>
                        <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.gst}</TableCell>
                        <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.sale_price}</TableCell>
                        <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway'}}><IconButton><ModeEditOutlineOutlinedIcon /></IconButton></TableCell>
                    </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
    )
}

const PaymentsTable = (props) =>{
    
    const data = props.data
    console.log("data in PaymentsTable",data)

    return(
        <TableContainer component={Paper} sx={{maxHeight:610}}>
            <Table stickyHeader  aria-label="sticky table" sx={{}}>
            <TableHead>
                <TableRow>
                    <TableCell component="th" align='center' sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Payment Date</TableCell>
                    <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Lead ID</TableCell>
                    <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Lead Name</TableCell>
                    <TableCell component="th" align='center' sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Payment Mode</TableCell>
                    <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Amount</TableCell>
                    <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data?.map((row) => (
                    <TableRow key={row._id}>
                        <TableCell align='center' sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.date?.split('T')[0].split('-').reverse().join('-')}</TableCell>
                        <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.lead_id}</TableCell>
                        <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.lead_name}</TableCell>
                        <TableCell align='center' sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.payment_mode}</TableCell>
                        <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway'}}>{row?.amount}</TableCell>
                        <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway'}}><IconButton><ModeEditOutlineOutlinedIcon /></IconButton></TableCell>
                    </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
    )
}

const Transactions = () => {
    const [transactionType, setTransactionType] = useState("Payments")
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getItemTransaction())
        dispatch(getExpenseTransaction())
        dispatch(getPayments())
        dispatch(getItemSaleTransaction())
    },[])

    const finance_expenses = useSelector((state)=>state.global.finance_expenses)
    const finance_payments = useSelector((state)=>state.global.finance_payments)
    const finance_purchases = useSelector((state)=>state.global.finance_purchases)
    const finance_sales = useSelector((state)=>state.global.finance_sales)


  return (
    <div>
        
    <div className='transactions'>
        <div className='transactions-heading'>
            <h1>{transactionType}</h1>
            <span className='transactions-head-buttons'>
                <Button onClick={() => setTransactionType("Payments")} sx={{...buttonStyles,background: transactionType==="Payments" ? '#E0E0E0':'none',"&:hover":{background: transactionType==="Payments" ? '#E0E0E0':'none'}}} variant='outlined'>Payments</Button>
                <Button onClick={() => setTransactionType("Sales")} sx={{...buttonStyles,background: transactionType==="Sales" ? '#E0E0E0':'none',"&:hover":{background: transactionType==="Sales" ? '#E0E0E0':'none'}}} variant='outlined'>Sales</Button>
                <Button onClick={() => setTransactionType("Purchases")} sx={{...buttonStyles,background: transactionType==="Purchases" ? '#E0E0E0':'none',"&:hover":{background: transactionType==="Purchases" ? '#E0E0E0':'none'}}} variant='outlined'>Purchase</Button>
                <Button onClick={() => setTransactionType("Expenses")} sx={{...buttonStyles,background: transactionType==="Expenses" ? '#E0E0E0':'none',"&:hover":{background: transactionType==="Expenses" ? '#E0E0E0':'none'}}} variant='outlined'>Expense</Button>
            </span>
            {/* <span className='transactions-head-options'>
                <MenuItem options={["This Week","This Month","This Year"]} icon={KeyboardArrowDownIcon} text={"Sorted By"} />
                <MenuItem options={["This Week","This Month","This Year"]} icon={KeyboardArrowDownIcon} text={"This Month"} />
            </span> */}
        </div>
        
        {transactionType==="Sales" && <SalesTable data={finance_sales}/>}
        {transactionType==="Purchases" && <PurchaseTable data={finance_purchases}/>}
        {transactionType==="Payments" && <PaymentsTable data={finance_payments}/>}
        {transactionType==="Expenses" && <ExpenseTable data={finance_expenses}/>}
    </div>
    </div>
  )
}

export default Transactions