import React, { useEffect,useState } from "react";
import "./LeadInformation.css";
import { useDispatch,useSelector } from "react-redux";
import {
  getLeadParentInformationByID,
  getLeadPetInformation,
  postComment,
  getPaymentsForLead,
  getUserActions,
  getItemSales,
  getPetDocuments,
  getExpenseSales
} from "../../Store/actions";
import { Button, TextField } from "@mui/material";

import ParentInfo from "../../Components/lead_information_components/ParentInfo/ParentInfo";
import PetInfo from "../../Components/lead_information_components/PetInfo/PetInfo";
import ActionableItems from "../../Components/lead_information_components/ActionableItems/ActionableItems";
import DocumentsRequired from "../../Components/lead_information_components/DocumentsRequired/DocumentsRequired";
import ItemInventory from "../../Components/lead_information_components/ItemInventory/ItemInventory";
import ClientActions from "../../Components/lead_information_components/ClientActions/ClientActions";
import Payments from "../../Components/lead_information_components/Payments/Payments";
import Navbar from "../../Components/Navbar/Navbar";

import EmailList from "../Mailer/NewMailer";

const buttonStyles= {
  borderRadius: '0',
  borderColor: '#E0E0E0',
  color: '#333',
  fontSize: '1rem',
  fontFamily: 'Raleway',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '140%',
  padding: '0.5rem 1rem'
};


function LeadInformation() {
  const dispatch = useDispatch();

  const lead_id = useSelector((state)=> state.global.leadId);
  const lead_parent_info = useSelector((state) => state.global.parentInformation);
  const pets_info = useSelector((state) => state.global.petsInformation?state.global.petsInformation:[]);
  const pets_documents = useSelector((state)=>state.global.petDocuments);
  const total_pets_flag = useSelector((state)=>state.global.totalPetsFlag);
  
  console.log("lead_id",lead_id)
  console.log("total_pets_flag",total_pets_flag)

  const [comment, setComment] = useState("");
  const leadParentData = lead_id === 0 ? {} : lead_parent_info?.[0] || {};
  const petsData = lead_id === 0 ? [] : pets_info || [];
  console.log("petsData in Lead Information", pets_info);
  const [transactionType,setTransactionType] = useState("Lead Information")
  
  useEffect(() => {
    if (lead_id!==0){
    dispatch(getLeadParentInformationByID(lead_id))
    dispatch(getLeadPetInformation(lead_id));
    dispatch(getPaymentsForLead(lead_id))
    dispatch(getUserActions(lead_id))
    dispatch(getItemSales(lead_id))
    dispatch(getExpenseSales(lead_id))
    console.info("UseEffect Checker")
  }
    
  }, []);

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    dispatch(postComment({
      user_id:1,
      lead_id:lead_id,
      comment:comment
    }))
    console.log("Comment submitted: ",comment);
    
    setComment("");
    dispatch(getLeadParentInformationByID(lead_id))
  }


  console.log("total_pets_flag.flag",total_pets_flag.flag)
  console.log("total_pets_flag.parent_id",total_pets_flag.parentId)
  console.log("lead_id",lead_id)
  

  

  

  

  return (
    <div>
      { (lead_id===leadParentData?.id)&&lead_parent_info&&( total_pets_flag.parentId ==lead_id)&&
      <div>
      
    <div className="lead-information-container">
      <div className="lead_information_details_container">
        {/* <h1 className="lead-info-heading-text">Lead Information</h1> */}
        
        {/* <InfoEdit/> */}

        <div className='lead-information-heading'>
            <h1>{transactionType}</h1>
            <span className='lead-information-head-buttons'>
                <Button onClick={() => setTransactionType("Lead Information")} sx={{...buttonStyles,background: transactionType==="Lead Information" ? '#E0E0E0':'none',"&:hover":{background: transactionType==="Lead Information" ? '#E0E0E0':'none'}}} variant='outlined'>Lead Information</Button>
                <Button onClick={() => setTransactionType("Documents")} sx={{...buttonStyles,background: transactionType==="Documents" ? '#E0E0E0':'none',"&:hover":{background: transactionType==="Documents" ? '#E0E0E0':'none'}}} variant='outlined'>Documents</Button>
                <Button onClick={() => setTransactionType("Emails")} sx={{...buttonStyles,background: transactionType==="Emails" ? '#E0E0E0':'none',"&:hover":{background: transactionType==="Emails" ? '#E0E0E0':'none'}}} variant='outlined'>Emails</Button>
                <Button onClick={() => setTransactionType("Team Actions")} sx={{...buttonStyles,background: transactionType==="Team Actions" ? '#E0E0E0':'none',"&:hover":{background: transactionType==="Team Actions" ? '#E0E0E0':'none'}}} variant='outlined'>Team Actions</Button>
                <Button onClick={() => setTransactionType("Item/Service Record")} sx={{...buttonStyles,background: transactionType==="Item/Service Record" ? '#E0E0E0':'none',"&:hover":{background: transactionType==="Item/Service Record" ? '#E0E0E0':'none'}}} variant='outlined'>Item/Service Record</Button>
                <Button onClick={() => setTransactionType("Payments")} sx={{...buttonStyles,background: transactionType==="Payments" ? '#E0E0E0':'none',"&:hover":{background: transactionType==="Payments" ? '#E0E0E0':'none'}}} variant='outlined'>Payments</Button>
            </span>
        </div>
        
        {transactionType==="Lead Information"&&<div>
          <p className="lead-info-remark">
            Latest Remark : {lead_parent_info[0]?.petspot_comment}
          </p>
        
        {leadParentData && <ParentInfo lead_parent_info={leadParentData} />}

        
        {petsData && <PetInfo pets_info={petsData} lead_id={lead_id}/>}
        <ActionableItems parent_data={lead_parent_info} pets_data={petsData}/>
        <h2 className="lead-info-comment-heading">Comment Section for the Stakeholder</h2>
        <TextField
          id="outlined-multiline-flexible"
          label="Add Comment"
          multiline
          maxRows={4}
          minRows={4}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          sx = {{width:'100%',fontFamily:'Raleway',color: '#000',fontSize: '1.1rem',fontWeight: 300}}
        />
        
        <Button onClick={handleCommentSubmit} variant="contained" sx={{marginTop: '1rem',padding: '0.5rem 3rem',borderRadius: '6px',color:'white',fontFamily:'Raleway',fontSize:'0.9rem',fontWeight:700,letterSpacing:'0.1px',height:'35px'}}>Submit</Button>
        
        </div>}
        {transactionType==="Emails"&&<EmailList pets_info={petsData} parent_info={leadParentData}/>}
        {transactionType==="Payments"&&<Payments />}
        {transactionType==="Documents"&&<DocumentsRequired pets_info={petsData} parent_info={leadParentData} pet_documents={pets_documents}/>}
        {transactionType==="Item/Service Record"&&<ItemInventory />}
        {transactionType==="Team Actions"&&<ClientActions />}
        
      </div>
    </div>
    </div>}
    </div>
  );
}

export default LeadInformation;



