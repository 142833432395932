import React from 'react';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const PetTableCell = ({
  isEditMode,
  selectedCell,
  field,
  petId,
  value,
  editedCells,
  inputRefs,
  editableData,
  handleCellDoubleClick,
  handleInputChange,
  handleInputBlur,
  handleSaveClick,
  handleInputKeyDown,
}) => {
  const fieldValue = editableData[petId]?.[field];



  return (
    <TableCell
      align="center"
      sx={{
        fontFamily: 'Raleway',
        fontWeight: 500,
        color: editedCells[petId]?.includes(`${petId}_${field}`) ? 'red' : 'inherit',
      }}
      onDoubleClick={() => handleCellDoubleClick(field, petId)}
    >
      {isEditMode && selectedCell?.petId === petId && selectedCell?.cellData === field ? (
        <TextField
          inputRef={(el) => {
            if (el) {
              if (!inputRefs.current[petId]) {
                inputRefs.current[petId] = {};
              }
              inputRefs.current[petId][field] = el;
            }
          }}
          multiline
          name={field}
          value={value}
          onChange={(event) => handleInputChange(event, petId)}
          onBlur={() => handleInputBlur(petId, field)}
          onKeyDown={(event) => handleInputKeyDown(event, petId, field)}
          style={{ width: '100%', minWidth: '300px' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CheckCircleIcon
                  color="primary"
                  onClick={() => handleSaveClick(petId, field)}
                  style={{ cursor: 'pointer' }}
                />
              </InputAdornment>
            ),
          }}
        />
      ) : (
        value || fieldValue
      )}
    </TableCell>
  );
};

export default PetTableCell;
