import React, { useEffect, useState } from 'react';
import './CargoAgents.css'
import { Paper, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    TextField,  
    Button} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCargoAgent, getCargoAgents,putCargoAgents } from '../../Store/actions';





function CargoAgents() {

    const cargo_agents = useSelector((state)=> state.global.cargoAgents);

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getCargoAgents())
    },[dispatch]);

    const submit_cargo_agent = () => {
        console.info(agentName,agentEmail,agentLocation,agentType);

        dispatch(putCargoAgents({
            name:agentName,
            email:agentEmail,
            location:agentLocation,
            type:agentType
        }));
        setAgentName("");
        setAgentEmail("");
        setAgentLocation("");
        setAgentType("");
        dispatch(getCargoAgents());
    }

    const delete_cargo_agent = (id) => {
        console.info(id)
        dispatch(deleteCargoAgent(id));
        dispatch(getCargoAgents());
    }


    const [agentName,setAgentName] = useState("");
    const [agentEmail,setAgentEmail] = useState("");
    const [agentLocation,setAgentLocation] = useState("");
    const [agentType, setAgentType] = useState("");


    return (
    <div>
        
 
    
    <div className='cargo-agent-container'>
        <div>
    <p/>
    <TableContainer sx={{ minWidth: 650, maxWidth:1500 }} component={Paper}>
        
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Agent Name</TableCell>
            <TableCell align="center">Email</TableCell>
            <TableCell align="center">Location</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="right">Remove Agent</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
        <TableRow key={0}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {<TextField 
                    required 
                    fullWidth 
                    label='Name'
                    value={agentName}
                    variant="filled"
                    margin='normal'
                    onChange={(event)=>{setAgentName(event.target.value )}}
                    />}
              </TableCell>
        
              <TableCell align="center">{<TextField 
                    required 
                    fullWidth 
                    label='Email of Agent'
                    value={agentEmail }
                    variant="filled"
                    margin='normal'
                    onChange={(event)=>{setAgentEmail(event.target.value )}}
                    />}</TableCell>
              <TableCell align="center">{ <TextField 
                    required 
                    fullWidth 
                    label='Location of Agent'
                    value={agentLocation }
                    variant="filled"
                    margin='normal'
                    onChange={(event)=>{setAgentLocation(event.target.value )}}
                    />}</TableCell>
              <TableCell align="right">{ <TextField 
                    required 
                    fullWidth 
                    label='Type of Agent'
                    value={agentType }
                    variant="filled"
                    margin='normal'
                    onChange={(event)=>{setAgentType(event.target.value )}}
                    />}</TableCell>
              <TableCell align="right">
                {<Button onClick={()=>submit_cargo_agent()} variant="outlined"  startIcon={<SendIcon />}/>}
              </TableCell>


        </TableRow>
        </TableBody>


        {(cargo_agents)&&<TableBody>
          {cargo_agents.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="center">{row.email}</TableCell>
              <TableCell align="center">{row.location}</TableCell>
              <TableCell align="center">{row.type}</TableCell>
              <TableCell align="right">
                {<Button variant="outlined" onClick={()=>delete_cargo_agent(row._id)} startIcon={<DeleteIcon />}/>}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>}
        
      </Table>
    </TableContainer>
    </div>
    
    
    





    </div>
    </div>
  )
}

export default CargoAgents