import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { global_url } from "../Global/GlobalUrl";

import {
  postUserURL,
  getCompletedLeadsURL,
  getFailedLeadsURL,
  getToStartLeadsURL,
  getInProgressLeadsURL,
  getConfirmedLeadsURL,
  getLeadSummaryURL,
  getLeadParentInformationURL,
  getLeadParentInformationByIdURL,
  getPetsInformationURL,
  getPetsInformationByIdURL,
  refreshLeadsData,
  getCargoAgentsURL,
  putCargoAgentsURL,
  deleteCargoAgentURL,
  postNewLeadURL,
  getFollowUpLeadsURL,
  editFollowUpLeadURL,
  postNewItemURL,
  getItemsURL,
  deleteItemsURL,
  postNewExpenseURL,
  getExpensesURL,
  deleteExpenseURL,
  postItemTxnURL,
  getItemTxnURL,
  postExpenseTxnURL,
  getExpenseTxnURL,
  editparentleadsURL,
  editpetleadsURL,
  deletepetleadsURL,
  postitemsaleURL,
  getitemsaleURL,
  deleteitemsaleURL,
  postexpensesaleURL,
  getexpensesaleURL,
  deleteexpensesaleURL,
  commentURL,
  postpaymentsURL,
  getpaymentsbyleadURL,
  updatepaymentURL,
  getactionbyidURL,
  createTemplateURL,
  deleteEmailTemplateURL,
  getEmailTemplateURL,
  getPetDocumentsURL,
  uploadPetDocumentURL,
  getImageDocumentForPetURL,
  getPDFDocumentForPetURL,
  getItemSaleTxnURL,
  getpaymentsURL,
  postNewLeadParentDataURL,
  postNewLeadPetDataURL,
  postNewMailURL



} from '../Global/GlobalVariables';

export const postUser = createAsyncThunk(
  "global/postUser", async (access_token) => {
  console.info("going to dispatch the completed leads")
  try {
    const url = `${global_url}/${postUserURL}/${access_token}`;
    const response = await axios.post(url);

    const data  =  await response.data;
    console.info("going to print the user info",response.data)
    return  data ;
  } catch (error) {
    console.error("Unable to get any user info", error);
  }
});


export const getCompletedLeads = createAsyncThunk(
    "global/getCompletedLeads", async (params) => {
    console.info("going to dispatch the completed leads")
    try {
      const url = `${global_url}/${getCompletedLeadsURL}`;
      const response = await axios.get(url,params);

      const data  =  await response.data;
      console.info("going to print the completed leads",response.data)
      return  data ;
    } catch (error) {
      console.error("Unable to get any completed leads", error);
    }
  });

  export const getFailedLeads = createAsyncThunk(
    "global/getFailedLeads", async () => {
    try {
      const url = `${global_url}/${getFailedLeadsURL}`;
      const response = await axios.get(url);

      const data  =  await response.data;
      console.info("going to print the failed leads",response.data)
      
      return  data ;
    } catch (error) {
      console.error("Unable to get any failed leads", error);
    }
  });

  export const getToStartLeads = createAsyncThunk(
    "global/getToStartLeads", async () => {
    try {
      const url = `${global_url}/${getFailedLeadsURL}`;
      const response = await axios.get(url);

      const data  =  await response.data;
      console.info("going to print the to start leads",response.data)
      
      return  data ;
    } catch (error) {
      console.error("Unable to get any to start leads", error);
    }
  });

  export const getInProgressLeads = createAsyncThunk(
    "global/getInProgressLeads", async () => {
    try {
      const url = `${global_url}/${getInProgressLeadsURL}`;
      
      const response = await axios.get(url);

      const data  =  await response.data;
      console.info("going to print the in progress leads",response.data)
      
      return  data ;
    } catch (error) {
      console.error("Unable to get any in progress leads", error);
    }
  });

  export const getConfirmedLeads = createAsyncThunk(
    "global/getConfirmedLeads", async () => {
    try {
      const url = `${global_url}/${getConfirmedLeadsURL}`;
      
      const response = await axios.get(url);

      const data  =  await response.data;
      console.info("going to print the in confirmed leads",response.data)
      
      return  data ;
    } catch (error) {
      console.error("Unable to get any in confirmed leads", error);
    }
  });

  export const getLeadSummary = createAsyncThunk(
    "global/getLeadSummary", async () => {
    try {
      const url = `${global_url}/${getLeadSummaryURL}`;
      
      const response = await axios.get(url);

      const data  =  await response.data;
      console.info("going to print the in leads summary",response.data)
      
      return  data ;
    } catch (error) {
      console.error("Unable to get any in leads summary", error);
    }
  });

  export const getLeadParentInformation = createAsyncThunk(
    "global/getLeadParentInformation", async () => {
    try {
      const url = `${global_url}/${getLeadParentInformationURL}`;
      // console.info(params)
      const response = await axios.get(url);

      const data  =  await response.data;
      
      return  data ;
    } catch (error) {
      console.error("Unable to get any in leads information", error);
    }
  });

  export const getLeadParentInformationByID = createAsyncThunk(
    "global/getLeadParentInformationByID", async (lead_id) => {
    try {
      const url = `${global_url}/${getLeadParentInformationByIdURL}/${lead_id}`;
      // console.info(params)
      const response = await axios.get(url);

      const data  =  await response.data;

      
      return  data ;
    } catch (error) {
      console.error("Unable to get any in leads information", error);
    }
  });

  export const getPetsInformation = createAsyncThunk(
    "global/getPetsInformation", async () => {
    try {
      const url = `${global_url}/${getPetsInformationURL}`;
      // console.info(params)
      const response = await axios.get(url);

      const data  =  await response.data;
      
      
      return  data ;
    } catch (error) {
      console.error("Unable to get any in pet summary", error);
    }
  });

  export const getLeadPetInformation = createAsyncThunk(
    "global/getLeadPetInformation", async (parent_id) => {
    try {
      const url = `${global_url}/${getPetsInformationByIdURL}/${parent_id}`;
      // console.info(params)
      const response = await axios.get(url);

      const data  =  await response.data;
      
      
      return  {data, parent_id} ;
    } catch (error) {
      console.error("Unable to get any in pet summary", error);
      return { parent_id, error };
    }
  });

  export const updateBaseDataFromGoogleSheet = createAsyncThunk(
    'global/updateBaseDataFromGoogleSheet', async (params) => {
      try {
        const url = `${global_url}/${refreshLeadsData}`; 
  
        console.log('params for the refresh leads', params)
      
        const response = await axios.post(url, params);
        const data = await response.data;
        console.info('Data from the server:', data);

      } catch (error) {
        console.error('Unable to get data from the server', error);
        throw error;  // Propagate the error to be caught by the calling code
      }
    }
  );

  export const getCargoAgents = createAsyncThunk(
    "global/getCargoAgents", async () => {
    try {
      const url = `${global_url}/${getCargoAgentsURL}`;
      
      const response = await axios.get(url);
      const data = await response.data;
      console.info("what cargo agents are we getting", data)

      return data;
     
    } catch (error) {
      console.error("Unable to get cargo agents", error);
    }
  });

  export const putCargoAgents = createAsyncThunk(
    "global/putCargoAgents", async (params) => {
    try {
      const url = `${global_url}/${putCargoAgentsURL}`;
      await axios.post(url,params);

     
    } catch (error) {
      console.error("Unable to put cargo agents", error);
    }
  });

  export const deleteCargoAgent = createAsyncThunk(
    "global/deleteCargoAgent", async (params) => {
    try {
      const url = `${global_url}/${deleteCargoAgentURL}/${params}`;
      console.log("agent url",url);    
      await axios.delete(url);
     
    } catch (error) {
      console.error("Unable to put cargo agents", error);
    }
  });
  
  export const postNewLead = createAsyncThunk(
    "global/postNewLead", async (params) => {
    try {
      const url = `${global_url}/${postNewLeadURL}`;
      const response = await axios.post(url,params);
      const data = await response.data;
      console.info("post data recieved", data)
      return data;
    } catch (error) {
      console.error("Unable to post lead", error);
    }
  });
  export const getFollowUpLeads = createAsyncThunk(
    "global/getFollowUpLeads", async () => {
    try {
      const url = `${global_url}/${getFollowUpLeadsURL}`;
      const response = await axios.get(url);
      const data = await response.data;
      console.info("Follow Up Leads recieved", data)
      return data;
    } catch (error) {
      console.error("Unable to get follow Up lead", error);
    }
  });
  export const editFollowUpLead = createAsyncThunk(
    "global/editFollowUpLead", async (params) => {
    try {
      const url = `${global_url}/${editFollowUpLeadURL}`;
      console.info(params)
      const response = await axios.post(url,params);
      const data = await response.data;
      console.info("Editted Follow Up Lead", data)
      return data;
    } catch (error) {
      console.error("Unable to edit follow Up lead", error);
    }
  });

  export const addItem = createAsyncThunk(
    "global/addItem",
    async (params) => {
      try {
        console.info("params", params);
  
        const { item_name, description, company_name, qty,sale_price,gst,sale_price_wo_gst, image, pdf } = params;
  
        const formData = new FormData();
        formData.append("item_name", item_name);
        formData.append("description", description);
        formData.append("company_name", company_name);
        formData.append("qty", qty);
        formData.append("sale_price", sale_price);
        formData.append("gst", gst);
        formData.append("sale_price_wo_gst", sale_price_wo_gst);
  
        for (const entry of formData.entries()) {
          const [key, value] = entry;
          console.log(key, value);
        }
        
        
        if (image) {
          formData.append("image", image, image.name);
        }
  
        if (pdf) {
          formData.append("pdf", pdf,pdf.name);
        }
  
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
  
        const url = `${global_url}/${postNewItemURL}`;
        const response = await axios.post(url, formData, config);
        
  
        const data = await response.data;
        console.info("Post data received", data);
        return data;
      } catch (error) {
        console.error("Unable to post item", error);
        throw error;
      }
    }
  );

  export const getItems = createAsyncThunk(
    "global/getItems", async () => {
    try {
      const url = `${global_url}/${getItemsURL}`;
      const response = await axios.get(url);
      const data = await response.data;
      console.info("Items recieved", data)
      return data;
    } catch (error) {
      console.error("Unable to get items", error);
    }
  });
  export const deleteItem = createAsyncThunk(
    "global/deleteItem", async (params) => {
    try {
      const req = {id:params.id,company_name:params.company_name,item_name:params.item_name,qty:params.qty,description:params.description};
      console.log(req);
      console.log("id deleted",params._id);
      const url = `${global_url}/${deleteItemsURL}/${params._id}`;
      await axios.delete(url);
     
    } catch (error) {
      console.error("Unable to delete item", error);
    }
  });

  export const addExpense = createAsyncThunk(
    "global/addExpense",
    async (params) => {
      try {
        console.info("params in addExpense", params);
  
        const { expense_name, expense_description, expense_business, image, pdf } = params;
  
        const formData = new FormData();
        formData.append("expense_name", expense_name);
        formData.append("expense_description", expense_description);
        formData.append("expense_business", expense_business);
  
        for (const entry of formData.entries()) {
          const [key, value] = entry;
          console.log(key, value);
        }
        
        
        if (image) {
          formData.append("image", image, image.name);
        }
  
        if (pdf) {
          formData.append("pdf", pdf,pdf.name);
        }
  
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        console.log("form data in the add expense", formData)
  
        const url = `${global_url}/${postNewExpenseURL}`;
        const response = await axios.post(url, formData, config);
        
  
        const data = await response.data;
        console.info("Post data received", data);
        return data;
      } catch (error) {
        console.error("Unable to post item transaction", error);
        throw error;
      }
    }
  );

  
  export const getExpense = createAsyncThunk(
    "global/getExpense", async () => {
    try {
      const url = `${global_url}/${getExpensesURL}`;
      const response = await axios.get(url);
      const data = await response.data;
      console.info("Expenses recieved", data)
      return data;
    } catch (error) {
      console.error("Unable to get expenses", error);
    }
  });

  export const deleteExpense = createAsyncThunk(
    "global/deleteExpense", async (params) => {
      try {
        console.log(params);
        const url = `${global_url}/${deleteExpenseURL}/${params}`; 
        await axios.delete(url);
        
      } catch (error) {
        console.error("Unable to delete expense", error);
    }
  });


  
  export const addItemTransaction = createAsyncThunk(
    "global/addItemTransaction",
    async (params) => {
      try {
        console.info("params", params);
  
        const { item_id, qty, mode_of_payment, purchase_flag, amount, gst, total_amt, date, image, pdf } = params;
  
        const formData = new FormData();
        formData.append("item_id", item_id);
        formData.append("qty", qty);
        formData.append("mode_of_payment", mode_of_payment);
        formData.append("purchase_flag", purchase_flag);
        formData.append("amount", amount);
        formData.append("gst", gst);
        formData.append("total_amt", total_amt);
        formData.append("date", date);
  
        for (const entry of formData.entries()) {
          const [key, value] = entry;
          console.log(key, value);
        }
        
        
        if (image) {
          formData.append("image", image, image.name);
        }
  
        if (pdf) {
          formData.append("pdf", pdf,pdf.name);
        }
  
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const url = `${global_url}/${postItemTxnURL}`;
        const response = await axios.post(url, formData, config);
        
  
        const data = await response.data;
        console.info("Post data received", data);
        return data;
      } catch (error) {
        console.error("Unable to post item transaction", error);
        throw error;
      }
    }
  );
  
  
  export const getItemTransaction = createAsyncThunk(
    "global/getItemTransaction", async () => {
    try {
      const url = `${global_url}/${getItemTxnURL}`;
      const response = await axios.get(url);
      const data = await response.data;
      console.info("item transactions recieved", data)
      return data;
    } catch (error) {
      console.error("Unable to get item transactions", error);
    }
  });

  export const addExpenseTransaction = createAsyncThunk(
    "global/addEpenseTransaction",
    async (params) => {
      try {
        console.info("params", params);
  
        const { expense_id, qty, mode_of_payment, purchase_flag, amount, gst, total_amt, date, image, pdf } = params;
  
        const formData = new FormData();
        formData.append("expense_id", expense_id);
        formData.append("qty", qty);
        formData.append("mode_of_payment", mode_of_payment);
        formData.append("purchase_flag", purchase_flag);
        formData.append("amount", amount);
        formData.append("gst", gst);
        formData.append("total_amt", total_amt);
        formData.append("date", date);
  
        for (const entry of formData.entries()) {
          const [key, value] = entry;
          console.log(key, value);
        }
        
        
        if (image) {
          formData.append("image", image, image.name);
        }
  
        if (pdf) {
          formData.append("pdf", pdf,pdf.name);
        }
  
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const url = `${global_url}/${postExpenseTxnURL}`;
        const response = await axios.post(url, formData, config);
        
  
        const data = await response.data;
        console.info("Post data received", data);
        return data;
      } catch (error) {
        console.error("Unable to post expense transaction", error);
        throw error;
      }
    }
  );
  // export const addEpenseTransaction = createAsyncThunk(
  //   "global/addExpenseTransaction", async (params) => {
  //   try {
  //     const url = `http://localhost:3001/be/newExpenseTransaction`;
  //     const response = await axios.post(url,params);
  //     const data = await response.data;
  //     console.info("post data recieved", data)
  //     return data;
  //   } catch (error) {
  //     console.error("Unable to post expense transaction", error);
  //   }
  // });
  export const getExpenseTransaction = createAsyncThunk(
    "global/getExpenseTransaction", async () => {
    try {
      const url = `${global_url}/${getExpenseTxnURL}`;
      const response = await axios.get(url);
      const data = await response.data;
      console.info("Expenses transactions recieved", data)
      return data;
    } catch (error) {
      console.error("Unable to get expense transactions", error);
    }
  });

  export const editParentInformation = createAsyncThunk(
    "global/editParentInformation", async (params) => {
    try {
      const url = `${global_url}/${editparentleadsURL}`;
      
      // const user_id = useSelector((state)=>state.global.user)
      // const headers = {
      //   Authorization: `Bearer ${user_id}`,
      // };  

      // const response = await axios.post(url,params, { headers });
      const response = await axios.post(url,params);
      const data = await response.data;
      console.info("post editParentInformation recieved", data)
      return data;
    } catch (error) {
      console.error("Unable to post editParentInformation", error);
    }
  });

  export const editPetInformation = createAsyncThunk(
    "global/editPetInformation", async (params) => {
    try {
      const url = `${global_url}/${editpetleadsURL}`;
      const response = await axios.post(url,params);
      const data = await response.data;
      console.info("post editPetInformation recieved", data)
      return data;
    } catch (error) {
      console.error("Unable to post editPetInformation", error);
    }
  });

  export const deletePetInformation = createAsyncThunk(
    "global/deletePetInformation", async (id) => {
    try {
      const url = `${global_url}/${deletepetleadsURL}/${id}`;
      console.log("agent url",url);    
      const data = await axios.delete(url);
      return data;
    } catch (error) {
      console.error("Unable to put cargo agents", error);
    }
  });


  export const postItemSale = createAsyncThunk(
    "global/postItemSale", async (params) => {
    try {
      const url = `${global_url}/${postitemsaleURL}`;
      const response = await axios.post(url,params);
      const data = await response.data;
      console.info("post postItemSale recieved", data)
      return data;
    } catch (error) {
      console.error("Unable to post postItemSale", error);
    }
  });

  export const getItemSales = createAsyncThunk(
    "global/getItemSales", async (lead_id) => {
    try {
      const url = `${global_url}/${getitemsaleURL}/${lead_id}`;
      const response = await axios.get(url);
      const data = await response.data;
      console.info("getItemSales recieved", data)
      return data;
    } catch (error) {
      console.error("Unable to getItemSales", error);
      throw(error)
    }
  });

  export const deleteItemSale = createAsyncThunk(
    "global/deleteItemSale", async (item_sale_id) => {
    try {
      
      const url = `${global_url}/${deleteitemsaleURL}/${item_sale_id}`;
      const response = await axios.delete(url);
      const data = await response.data;
      console.info("deleteItemSale recieved", data)
      return data;
    } catch (error) {
      console.error("Unable to deleteItemSale", error);
    }
  });

  export const postExpenseSale = createAsyncThunk(
    "global/postExpenseSale", async (params) => {
    try {
      const url = `${global_url}/${postexpensesaleURL}`;
      console.log("Params in postExpenseSale", params)
      const response = await axios.post(url,params);
      const data = await response.data;
      console.info("post postExpenseSale recieved", data)
      return data;
    } catch (error) {
      console.error("Unable to post postExpenseSale", error);
    }
  });

  export const getExpenseSales = createAsyncThunk(
    "global/getExpenseSales", async (lead_id) => {
    try {
      const url = `${global_url}/${getexpensesaleURL}/${lead_id}`;
      const response = await axios.get(url);
      const data = await response.data;
      console.info("getExpenseSales recieved", data)
      return data;
    } catch (error) {
      console.error("Unable to getExpenseSales", error);
      throw(error)
    }
  });

  export const deleteExpenseSale = createAsyncThunk(
    "global/deleteExpenseSale", async (expense_sale_id) => {
    try {
      
      const url = `${global_url}/${deleteexpensesaleURL}/${expense_sale_id}`;
      const response = await axios.delete(url);
      const data = await response.data;
      console.info("deleteExpenseSale recieved", data)
      return data;
    } catch (error) {
      console.error("Unable to deleteExpenseSale", error);
    }
  });




  export const postComment = createAsyncThunk(
    "global/postComment", async (params) => {
    try {
      const url = `${global_url}/${commentURL}`;
      const response = await axios.post(url, params);
      const data = await response.data;
      console.info("deleteItemSale recieved", data)
      return data;
    } catch (error) {
      console.error("Unable to deleteItemSale", error);
    }
  });

  export const postPayment = createAsyncThunk(
    "global/postPayment", async (params) => {
    try {
      const url = `${global_url}/${postpaymentsURL}`;
      const response = await axios.post(url, params);
      const data = await response.data;
      console.info("postPayment recieved", data)
      return data;
    } catch (error) {
      console.error("Unable to postPayment", error);
    }
  });


  export const getPaymentsForLead = createAsyncThunk(
    "global/getPaymentsForLead", async (lead_id) => {
    try {
      const url = `${global_url}/${getpaymentsbyleadURL}/${lead_id}`;
      const response = await axios.get(url);
      const data = await response.data;
      console.info("getPaymentsForLead recieved", data)
      return data;
    } catch (error) {
      console.error("Unable to getPaymentsForLead", error);
      throw error;
    }
  });

  export const updatePaymentForLead = createAsyncThunk(
    "global/updatePaymentForLead", async (params) => {
    try {
      const url = `${global_url}/${updatepaymentURL}`;
      const response = await axios.post(url,params);
      const data = await response.data;
      console.info("updatePaymentForLead recieved", data)
      return data;
    } catch (error) {
      console.error("Unable to updatePaymentForLead", error);
      throw error;
    }
  });

  export const getUserActions = createAsyncThunk(
    "global/getUserActions", async (lead_id) => {
    try {
      const url = `${global_url}/${getactionbyidURL}/${lead_id}`;
      console.info(url)
      const response = await axios.get(url);
      const data = await response.data;
      console.info("getUserActions recieved", data)
      return data;
    } catch (error) {
      console.error("Unable to getUserActions", error);
      throw error;
    }
  });

  export const sendCreateTemplate = createAsyncThunk(
    "global/sendCreateTemplate", async (params) => {
    try {
      const url = `${global_url}/${createTemplateURL}`;
      console.info(url)
      const response = await axios.post(url,params);
      const data = await response.data;
      console.info("getUserActions recieved", data)
      return data;
    } catch (error) {
      console.error("Unable to getUserActions", error);
      throw error;
    }
  });

  export const deleteEmailTemplate = createAsyncThunk(
    "global/deleteEmailTemplate", async (id) => {
    try {
      const url = `${global_url}/${deleteEmailTemplateURL}/${id}`;
      
      const response = await axios.delete(url);
      const data = await response.data;
      
      return data;
    } catch (error) {
      console.error("Unable to deleteEmailTemplate", error);
      throw error;
    }
  });


  export const getEmailTemplates = createAsyncThunk(
    "global/getEmailTemplates", async (params) => {
    console.info("going to dispatch the getEmailTemplates")
    try {
      const url = `${global_url}/${getEmailTemplateURL}`;
      const response = await axios.get(url,params);

      const data  =  await response.data;
      console.info("going to print the getEmailTemplates ",response.data)
      return  data ;
    } catch (error) {
      console.error("Unable to get any getEmailTemplates ", error);
    }
  });


  export const getPetDocuments = createAsyncThunk(
    "global/getPetDocuments", async (params) => {
    console.info("going to dispatch the getEmailTemplates")
    try {
      console.log("pet_id in params", params.pet_id)
      console.log("params in getPetDocument",params.lead_id,params.pet_id)
      const url = `${global_url}/${getPetDocumentsURL}/${params.lead_id}/${params.pet_id}`;

      const response = await axios.get(url);

      const data  =  await response.data;
      console.info("going to print the getEmailTemplates ",response.data)
      return  data ;
    } catch (error) {
      console.error("Unable to get any getEmailTemplates ", error);
    }
  });

  
  export const postPetDocument = createAsyncThunk(
    "global/postPetDocument",
    async (params) => {
      try {
        console.info("params in post pet document", params);
  
        const { doc_id, lead_id, pet_id, document_name, image_id, pdf_id, image, pdf} = params;
  
        const formData = new FormData();
        formData.append("doc_id", doc_id);
        formData.append("lead_id", lead_id);
        formData.append("pet_id", pet_id);
        formData.append("document_name", document_name);
        formData.append("image_id", image_id);
        formData.append("pdf_id", pdf_id);
        
        for (const entry of formData.entries()) {
          const [key, value] = entry;
          console.log(key, value);
        }
        
        
        if (image) {
          formData.append("image", image, image.name);
        }
  
        if (pdf) {
          formData.append("pdf", pdf,pdf.name);
        }
  
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const url = `${global_url}/${uploadPetDocumentURL}`;
        const response = await axios.post(url, formData, config);
        
  
        const data = await response.data;
        console.info("Post data received", data);
        return data;
      } catch (error) {
        console.error("Unable to post item transaction", error);
        throw error;
      }
    }
  );

  export const getImagePetDocument = createAsyncThunk(
    "global/getImagePetDocument", async (params) => {
    console.info("going to dispatch the getEmailTemplates")
    try {
      const url = `${global_url}/${getImageDocumentForPetURL}/${params}`;

      const response = await axios.get(url);

      const data  =  await response.data;
      console.info("going to print the getEmailTemplates ",response.data)
      return  data ;
    } catch (error) {
      console.error("Unable to get any getEmailTemplates ", error);
    }
  });

  export const getPDFPetDocument = createAsyncThunk(
    "global/getPDFPetDocument", async (params) => {
    console.info("going to dispatch the getEmailTemplates")
    try {
      const url = `${global_url}/${getPDFDocumentForPetURL}/${params}`;

      const response = await axios.get(url);

      const data  =  await response.data;
      console.info("going to print the getEmailTemplates ",response.data)
      return  data ;
    } catch (error) {
      console.error("Unable to get any getEmailTemplates ", error);
    }
  });

  export const getItemSaleTransaction = createAsyncThunk(
    "global/getItemSaleTransaction", async () => {
    console.info("going to dispatch the getEmailTemplates")
    try {
      const url = `${global_url}/${getItemSaleTxnURL}`;

      const response = await axios.get(url);

      const data  =  await response.data;
      console.info("going to print the getEmailTemplates ",response.data)
      return  data ;
    } catch (error) {
      console.error("Unable to get any getEmailTemplates ", error);
    }
  });


  export const getPayments = createAsyncThunk(
    "global/getPayments", async () => {
    console.info("going to dispatch the getEmailTemplates")
    try {
      const url = `${global_url}/${getpaymentsURL}`;

      const response = await axios.get(url);

      const data  =  await response.data;
      console.info("going to print the getEmailTemplates ",response.data)
      return  data ;
    } catch (error) {
      console.error("Unable to get any getEmailTemplates ", error);
    }
  });

  export const postNewLeadParentData = createAsyncThunk(
    "global/postNewLeadParentData", async (params) => {
    try {
      const url = `${global_url}/${postNewLeadParentDataURL}`;
      // console.info(params)
      const response = await axios.post(url,params);

      const data  =  await response.data;
      
      return  data ;
    } catch (error) {
      console.error("Unable to get any in leads information", error);
    }
  });


  export const postNewLeadPetData = createAsyncThunk(
    "global/postNewLeadPetData", async (params) => {
    try {
      const url = `${global_url}/${postNewLeadPetDataURL}`;
      console.log("params for the pets info storage")
      if (Array.isArray(params)) {
        console.log("params is an array");
      } else {
        console.log("params is not an array");
      }
      console.info(params)
      const response = await axios.post(url,params);

      const data  =  await response.data;
      
      return  data ;
    } catch (error) {
      console.error("Unable to get any in leads information", error);
      throw error
    }
  });


  export const postNewMail = createAsyncThunk(
    "global/postNewMail", async (params) => {
    try {
      const url = `${global_url}/${postNewMailURL}`;
      const response = await axios.post(url,params);

      const data  =  await response.data;
      
      return  data ;
    } catch (error) {
      console.error("Unable to post new mail", error);
      throw error
    }
  });

