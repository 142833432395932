import React, { useEffect, useState } from 'react';
import './ItemInventory.css';
import { Button } from '@mui/material';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import Navbar from '../../Components/Navbar/Navbar';
import { useDispatch } from 'react-redux';
import { addItem, addItemTransaction, deleteItem, getItemTransaction, getItems } from '../../Store/actions';
import { useSelector } from 'react-redux';
import AddItemForm from '../../Components/InventoryComponents/AddItemForm/AddItemForm';
import PopUp from '../../Components/PopUp/PopUp';
import ItemPurchaseForm from '../../Components/InventoryComponents/ItemPurchaseForm/ItemPurchaseForm';
import ItemCard from '../../Components/InventoryComponents/ItemCard/ItemCard';

const addButtonStyles = {
  padding: '1rem 2rem',
  background: '#76BFF3',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '250px',
  borderRadius: '30px',
  height: '60px',
  color: 'white',
  fontFamily: 'Raleway',
  fontSize: '1.3rem',
  fontWeight: '600',
};

const ItemInventory = () => {
  const dispatch = useDispatch();

  const [selectedItemId, setSelectedItemId] = useState(null);
  const [addItemStatus, setAddItemStatus] = useState(false);
  const [itemPurchaseStatus, setItemPurchaseStatus] = useState(false);

  const data = useSelector((state) => state.global.items);

  const handleDeleteItem = (e,row) => {
    console.log("deleted id: ", row);
    dispatch(deleteItem(row));
    dispatch(getItems());
  };

  const handleAddItem = (e,addForm) => {
    console.log("Add Item form submitted ", addForm);
    dispatch(addItem(addForm));
    setAddItemStatus(false);
    dispatch(getItems());
  };

  const handlePurchaseFormSubmit = (purchaseFormData) => {
    console.log(selectedItemId);
    purchaseFormData.item_id = selectedItemId;
    purchaseFormData.purchase_flag = "Purchase";
    console.log("Purchase Item form submitted ", purchaseFormData);
    dispatch(addItemTransaction(purchaseFormData));
    setItemPurchaseStatus(false);
    dispatch(getItemTransaction());
  };

  const handleAddPurchase = (id) => {
    console.log(id);
    setSelectedItemId(id);
    setItemPurchaseStatus(!itemPurchaseStatus);
  };

  useEffect(() => {
    dispatch(getItems());
  }, [dispatch]);

  return (
    <div>
      
      <div className='inventory'>
        <div className='inventory-heading'>
          <h1>INVENTORY</h1>
          <Button onClick={() => setAddItemStatus(!addItemStatus)} variant='contained' sx={addButtonStyles}>
            Add Item <LibraryAddOutlinedIcon />
          </Button>
        </div>
        {addItemStatus && <AddItemForm handleAddItem={handleAddItem} />}
        {itemPurchaseStatus && (
          <PopUp content={ItemPurchaseForm} heading="Add Purchase" close={setItemPurchaseStatus} props={{ data, handlePurchaseFormSubmit, selectedItemId }} />
        )}
        <div className='inventory-items'>
          {data?.map((row) => (
            <ItemCard key={row?._id} row={row} del={handleDeleteItem} addPurchase={() => handleAddPurchase(row?._id)} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ItemInventory;
