import React, { useEffect, useState } from 'react'
import './DocumentsRequired.css';
import { useDispatch } from "react-redux";
import { Button, IconButton } from '@mui/material';

import DocumentScannerTwoToneIcon from '@mui/icons-material/DocumentScannerTwoTone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {  Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import {  postPetDocument } from '../../../Store/actions';
import { getImageDocumentForPetURL,getPDFDocumentForPetURL } from '../../../Global/GlobalVariables';
import { global_url } from '../../../Global/GlobalUrl';
import axios from "axios";
import { Document, Page } from 'react-pdf';

const DocumentRow = ({name,title,parent_info,selectedPet, petDocuments,draft_name}) => {
  
    const [fileURL, setFileURL] = useState({}); 
    const [addForm, setAddForm] = useState({});
    const [open, setOpen] = useState(false);
    const [documentData, setDocumentData] = useState(null);
    const [isPdf, setIsPdf] = useState(false);
    const [disable, setDisable] = useState(false);
    
    const dispatch = useDispatch();
    console.log("document data", documentData)
    
    const petDocument = petDocuments?.find(doc => doc.document_name === name);
    const petDocumentDraft = petDocuments?.find(doc => doc.document_name === name+"-draft");
    
    console.log("petDocument in DocumentRow",petDocument)
    console.log("petDocumentDraft in DocumentRow",petDocumentDraft)
  
    const handleOpen = async (doc) => {
        try {
          let response;
          let isDocumentPdf = false; // Initialize for each document
          if (doc.pdf_id) {
            response = await axios.get(`${global_url}/${getPDFDocumentForPetURL}/${doc.pdf_id}`, { responseType: 'arraybuffer' });
            isDocumentPdf = true; // Set to true for PDF documents
          } else if (doc.image_id) {
            response = await axios.get(`${global_url}/${getImageDocumentForPetURL}/${doc.image_id}`, { responseType: 'arraybuffer' });
            isDocumentPdf = false; // Set to false for image documents
          }
          
          if (response && response.data) {
            const blob = new Blob([response.data], { type: isDocumentPdf ? 'application/pdf' : 'image/*' });
            const url = URL.createObjectURL(blob);
            setDocumentData(url);
            setIsPdf(isDocumentPdf); // Update isPdf state based on the current document type
            setOpen(true);
            setDisable(true);
          }
        } catch (error) {
          console.error("Error fetching document:", error);
        }
      };
  
    const handleClose = () => {
      setOpen(false);
      setDisable(false)
    };

    const handleDownloadImage = () => {
        const a = document.createElement('a');
        a.href = documentData; // Set the image URL
        a.download = 'image.jpg'; // Set the desired filename
        a.click();
      };
      
      const handlePrintImage = () => {
        const img = new Image();
        img.src = documentData; // Set the image URL
        img.onload = () => {
          const printWindow = window.open('', '_blank');
          printWindow.document.write(`<html><head><title>Print Image</title></head><body><img src="${documentData}" /></body></html>`);
          printWindow.document.close();
          printWindow.print();
        };
      };
  
    
  
  
      return (
          <div className='lead-info-docs-req-item'>
            <h4 className='lead-info-docs-req-item-head'>{title}</h4>
            <div className='document-divider'>
              {draft_name && <div className='document'>
                <p>Draft Document</p>
  
                {/* this is for the draft documents and will open in new tab */}
                <IconButton color="primary" onClick={()=>{
                    const dataToPass = {
                      parent_info: {parent_info},
                      pet_info: {selectedPet},
                    };
                  
                    // Store the data in localStorage before opening the new tab
                    localStorage.setItem('leadData', JSON.stringify(dataToPass));
                    
                    window.open(draft_name, '_blank')}}
                  >
                    <CloudUploadIcon />
                  </IconButton>
                
                {/* This is to view the draft document */}
              { petDocumentDraft&&
                <IconButton color="primary" onClick={()=>handleOpen(petDocumentDraft)} disabled={disable}>
                  <DocumentScannerTwoToneIcon />
                </IconButton>}
              
              </div>}
  
              <div className='document'>
                <p>Final Document</p>
  
                {/* This is to upload other final documents */}
                <label htmlFor={`file-upload-${name}`} className="upload-button-label">
                    <IconButton color="primary" component="span">
                        <CloudUploadIcon />
                    </IconButton>
                    </label>
                    <input
                    id={`file-upload-${name}`}
                    className="upload-button"
                    type="file"
                    accept="application/pdf, image/*"
                    onChange={async (e) => {
                        const file = e.target?.files?.[0];
                        if (file) {
                        console.log("Yes, the file is there with", name);
                        setAddForm({ ...addForm, [name]: file });
                        const file_url = URL.createObjectURL(file);
                        setFileURL({ ...fileURL, [name]: file_url });

                        // Dispatch the action here
                        try {
                            const formData = new FormData();
                            if (file.type === "application/pdf") {
                            dispatch(
                                postPetDocument({
                                doc_id: null,
                                lead_id: selectedPet?.parent_id,
                                pet_id: selectedPet?.pet_id,
                                document_name: name,
                                pdf: file,
                                image_id: null,
                                pdf_id: null,
                                })
                            );
                            } else if (file.type.startsWith("image/")) {
                            dispatch(
                                postPetDocument({
                                doc_id: null,
                                lead_id: selectedPet?.parent_id,
                                pet_id: selectedPet?.pet_id,
                                document_name: name,
                                image: file,
                                image_id: null,
                                pdf_id: null,
                                })
                            );
                            }
                        } catch (error) {
                            console.error("Error dispatching postPetDocument:", error);
                        }
                        }
                    }}
                    style={{ display: "none" }}
                    />
  
                {  petDocument&&
                <IconButton color="primary" onClick={()=>handleOpen(petDocument)} disabled={disable}>
                  <DocumentScannerTwoToneIcon />
                </IconButton>}
  
                <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
                  <DialogTitle>
                    Your Document 
                  </DialogTitle>
                  {!isPdf&&<div style={{ display: 'flex', alignItems: 'center', marginLeft:10 }}>
                    <Button
                        variant="outlined"
                        onClick={() => handleDownloadImage()}
                        color="primary"
                        style={{ width: '250px', marginRight: '10px' }} // Adjust margin as needed
                    >
                        Download Image
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => handlePrintImage()}
                        color="primary"
                        style={{ width: '250px' }}
                    >
                        Print Image
                    </Button>
                    </div>}
                  <DialogContent>
                        {documentData && (
                        <>
                            {isPdf ? (
                            <iframe
                                src={documentData}
                                title="PDF Document"
                                width="100%"
                                height="500px"
                            />
                            ) : (
                            <>
                            
                            
                            <img src={documentData} alt="Image Document" style={{ maxWidth: '100%', height: 'auto' }} />
                               
                            </>
                            )}
                        </>
                        )}
                        {!documentData && 'No document available.'}
                    </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          </div>
      )
  }
  

export default DocumentRow