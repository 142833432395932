import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import './MenuItem.css';

const ITEM_HEIGHT = '300px';
const buttonStyles = {
  borderRadius: '8px',
  border: '2px solid #E0E0E0',
  background: '#FFF',
  color: '#333',
  fontSize: '0.8rem',
  fontFamily: 'Raleway',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '140%',
  width: '180px',
  display:'flex',
  justifyContent: 'space-between'
};

export default function LongMenu({options,icon: Icon,text,set,category}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (option) => {
    set(option);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-label="more"
        id="long-button"
        sx={buttonStyles}
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<Icon />}
      >
        {category}
      </Button>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '170px',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} selected={option === category} onClick={()=>handleClose(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}