import React from 'react';
import './Expense.css';
import { IconButton,Button } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const purchaseButtonStyles = {
    padding: '0.5rem 1rem',
    background: '#76BFF3',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '30px',
    width: '200px',
    height: '40px',
    color: 'white',
    fontFamily: 'Raleway',
    fontSize: '1.3rem',
    fontWeight: '600'
};

const Expense = ({del,addPurchase,row}) => {
    return (
        <div className='inventory-item'>
            <div className='inventory-item-info'>
                <img src={row?.src} alt='item' />
                <div className='inventory-item-grid'>
                    <div className='inventory-item-col'>
                        <h2>{row?.expense_name}</h2>
                        <h3>{row?.expense_description}</h3>
                    </div>
                    <div className='inventory-item-col'>
                        <h3>{row?.expense_business}</h3>
                    </div>
                </div>
            </div>
            <div className='inventory-item-options'>
                <div className='inventory-item-buttons'>
                    <span className='inventory-item-edit'>
                        <IconButton><EditOutlinedIcon /></IconButton>
                        <IconButton onClick={(e)=>del(e,row)}><DeleteOutlineOutlinedIcon /></IconButton>
                    </span>
                    <Button onClick={(e)=>addPurchase(e,row._id)} sx={purchaseButtonStyles} variant='contained'>Add Purchase</Button>
                </div>
            </div>
        </div>
    )
}

export default Expense




