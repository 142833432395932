export const postUserURL = "api/user";
export const getCompletedLeadsURL = "completed_leads";
export const getFailedLeadsURL = "failed_leads";
export const getToStartLeadsURL = "to_start_leads";
export const getInProgressLeadsURL = "in_progress_leads";
export const getConfirmedLeadsURL = "confirmed_leads";
export const getLeadSummaryURL = "leadCount";
export const getLeadParentInformationURL = "parentleads";
export const getLeadParentInformationByIdURL = "parentleadsbyid";
export const getPetsInformationURL = "petleads";
export const getPetsInformationByIdURL = "petleadsbyparentid";
export const refreshLeadsData = "refresh_leads_data";
export const getCargoAgentsURL = "getagent";
export const putCargoAgentsURL = "newagent";
export const deleteCargoAgentURL = "deleteAgent";
export const postNewLeadURL = "followUpLeads";
export const getFollowUpLeadsURL = "getfollowUpLeads";
export const editFollowUpLeadURL = "editfollowupLeads";
export const postNewItemURL = "newitem";
export const getItemsURL = "getitem";
export const deleteItemsURL = "deleteItem";
export const postNewExpenseURL = "newexpense";
export const getExpensesURL = "getexpense";
export const deleteExpenseURL = "deleteExpense";
export const postItemTxnURL = "newItemTransaction";
export const getItemTxnURL = "getitemtransaction";
export const getItemSaleTxnURL = "getitemsales";
export const postExpenseTxnURL = "newExpenseTransaction";
export const getExpenseTxnURL = "getexpensetransaction";
export const editparentleadsURL = "editparentleads";
export const editpetleadsURL = "editpetleads";
export const deletepetleadsURL = "deletePetFromLead"
export const postitemsaleURL = "postitemsale";
export const getitemsaleURL = "getitemsale";
export const deleteitemsaleURL = "deleteitemsale";
export const postexpensesaleURL = "postexpensesale";
export const getexpensesaleURL = "getexpensesale";
export const deleteexpensesaleURL = "deleteexpensesale";
export const commentURL = "comment";
export const postpaymentsURL = "postpayments";
export const getpaymentsbyleadURL = "getpaymentsbylead";
export const getpaymentsURL = "getpayments";
export const updatepaymentURL = "updatepayment";
export const getactionbyidURL = "getactionbyid";
export const createTemplateURL = "createtemplate";
export const deleteEmailTemplateURL ="deletetemplate"
export const getEmailTemplateURL = "getemailtemplates";
export const getPetDocumentsURL = "getdocumentsforpet";
export const uploadPetDocumentURL = "uploadpetdocument";
export const getImageDocumentForPetURL = "getimagedocumentforpet";
export const getPDFDocumentForPetURL = "getpdfdocumentforpet";
export const postNewLeadParentDataURL = "new_lead_parent_data";
export const postNewLeadPetDataURL = "new_lead_pet_data";
export const getGmailMailsURL = "getGmailMails";
export const getGmailAttachmentURL = "getGmailAttachment";
export const postNewMailURL = "newGmailMail";
export const postGoogleSignInAuth = "api/auth1";
