import React from 'react';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import {  LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import deLocale from 'date-fns/locale/de';
import { startOfDay, parseISO, format, isValid } from 'date-fns';

const PetTableDateCell = ({
  isEditMode,
  selectedCell,
  field,
  petId,
  value,
  editedCells,
  inputRefs,
  editableData,
  handleCellDoubleClick,
  handleInputChange,
  handleInputBlur,
  handleInputKeyDown,
  handleSaveClick,
}) => {
  const fieldValue = editableData[petId]?.[field];

  const formatDateForPicker = (dateString) => {
    const dateObject = parseISO(dateString);
    return isValid(dateObject) ? dateObject : null;
  };

  return (
    <TableCell
      align="center"
      sx={{
        fontFamily: 'Raleway',
        fontWeight: 500,
        color: editedCells[petId]?.includes(`${petId}_${field}`) ? 'red' : 'inherit',
      }}
      onDoubleClick={() => handleCellDoubleClick(field, petId)}
    >
      {isEditMode && selectedCell?.petId === petId && selectedCell?.cellData === field ? (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
            <DatePicker
              name={field}
              
              onChange={(newValue) => {
                if (newValue) {
                  newValue = startOfDay(newValue);
                }
                handleInputChange(
                  {
                    target: {
                      name: field,
                      value: newValue ? format(newValue, 'yyyy-MM-dd', new Date()) : '',
                    },
                  },
                  petId
                );
              }}
              onBlur={() => handleInputBlur(petId, field)}
              onKeyDown={(event) => handleInputKeyDown(event, petId, field)}
              renderInput={(params) => <TextField {...params} />}
              style={{ flex: 1, minWidth: '300px' }}
              inputFormat="yyyy-MM-dd"
              placeholder={fieldValue}
              // Set the inputRef using the inputRefs object
              inputRef={(el) => {
                if (el) {
                  if (!inputRefs.current[petId]) {
                    inputRefs.current[petId] = {};
                  }
                  inputRefs.current[petId][field] = el;
                }
              }}
            />
          </LocalizationProvider>
          <IconButton onClick={() => handleSaveClick(petId, field)} style={{ marginLeft: '5px' }}>
            <CheckCircleIcon color="primary" />
          </IconButton>
        </div>
      ) : (
        fieldValue
      )}
    </TableCell>
  );
};

export default PetTableDateCell;
