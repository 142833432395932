import React, { useEffect, useState } from 'react';
import './MyLeads.css';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Select } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getLeadParentInformation } from '../../Store/actions';
import MenuItem from '../../Components/MenuItem/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Navbar from '../../Components/Navbar/Navbar';
import { updateLeadID, updateMyLeadsFlag } from '../../Store/reducers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; // Correct import


const leadTypeDataMap = {
  'All Leads': ['All Leads', 'All Leads'],
  'New': ['','New Leads'],
  'Invoice Mail Sent': ['Invoice Mail Sent','Invoice Sent Leads' ],
  'Lead Confirmed': ['Lead Confirmed','Booking Confirm Leads'],
  'Complete': ['Complete','Completed Leads'],
  'Failed': ['Failed','Failed Leads'],
  'AQCS Done': ['AQCS Done','AQCS Done Leads'],
  'Lead Types New':['','New Leads'],
  'Booking Confirm':['Lead Confirmed','Booking Confirm Leads'],
  'Completed Leads':['Complete','Completed Leads'],
  'Failed Leads':['Failed','Failed Leads'],
};



const options = [
  'All Leads',
  'New',
  'Invoice Mail Sent',
  'Lead Confirmed',
  'Complete',
  'Failed', 
  'AQCS Done', 
];




function MyLeads() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const leads_data = useSelector((state) => state.global?.leadParentInformation || []);
  const myLeadsFlag = useSelector((state) => state.global?.myleadsflag);
  const [myLeadFlagToUse,myLeadFlagToShow] = leadTypeDataMap[myLeadsFlag];

  const [status, setstatus] = useState('');

  const goToLead = (Lead_ID) => {
    dispatch(updateLeadID(Lead_ID));
    navigate('/lead_information');
  };

  useEffect(() => {
    dispatch(getLeadParentInformation());
  }, [dispatch]);

  useEffect(() => {
    if (status!= '') {
      dispatch(updateMyLeadsFlag(status))
    }
  },[status]);

  const [sortConfig, setSortConfig] = useState({
    key: '',
    direction: 'ascending',
  });

  const handleSort = (column) => {
    let direction = 'ascending';
    if (sortConfig.key === column && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key: column, direction });
  };

  const sortedData = [...leads_data].sort((a, b) => {
    if (sortConfig.direction === 'ascending') {
      return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
    } else {
      return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
    }
  });

  
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (newDate) => {
    setStartDate(newDate);
  };

  const handleEndDateChange = (newDate) => {
    setEndDate(newDate);
  };
  
  if(myLeadFlagToUse != 'All Leads'){
    console.log(typeof(startDate))
    console.log(typeof(endDate?.$d))
    console.log(sortedData.filter((lead) => lead?.created_time >= startDate?.toISOString() && lead?.created_time <= endDate?.toISOString()));
  }
  

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        <div className='my-leads-container'>
          <div className='my-leads-heading'>
            <h2 className='my-leads-heading-text'>Lead Dashboard</h2>
            
              <span className='my-leads-menu'>
                
                  <div>                
                    <p>Select Lead Type</p>
                    
                    <MenuItem options={options} category={status} set={setstatus} label='Select' text='Select Lead Type' icon={KeyboardArrowDownIcon} />
                  </div>

              
                  <DesktopDatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={handleStartDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <DesktopDatePicker
                    label="End Date"
                    value={endDate}
                    onChange={handleEndDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    // openTo='date'
                    disableFuture
                    
                  />
               

              </span>
            
          </div>
          <div className='my_leads_container'>
            {myLeadFlagToShow && <h1 className='my_leads_header'>{myLeadFlagToShow}</h1>}
            <div className='table-container'>
              <TableContainer component={Paper} sx={{ maxHeight: 630 }}>
                <Table stickyHeader aria-label='sticky table' sx={{}}>
                  <TableHead>
                    <TableRow>
                      <TableCell component='th' align='center' sx={{ fontWeight: 'bold', fontFamily: 'Raleway' }} onDoubleClick={() => handleSort('id')}>
                        Lead ID
                      </TableCell>
                      <TableCell component='th' align='center' sx={{ fontWeight: 'bold', fontFamily: 'Raleway' }} onDoubleClick={() => handleSort('lead_type')}>
                        Lead Type
                      </TableCell>
                      <TableCell component='th' align='center' sx={{ fontWeight: 'bold', fontFamily: 'Raleway' }} onDoubleClick={() => handleSort('name')}>
                        Name
                      </TableCell>
                      <TableCell component='th' align='center' sx={{ fontWeight: 'bold', fontFamily: 'Raleway' }} onDoubleClick={() => handleSort('petspot_comment')}>
                        Remark
                      </TableCell>
                      <TableCell component='th' align='center' sx={{ fontWeight: 'bold', fontFamily: 'Raleway' }} onDoubleClick={() => handleSort('travel_date')}>
                        Travel Date
                      </TableCell>
                      <TableCell component='th' align='center' sx={{ fontWeight: 'bold', fontFamily: 'Raleway' }} onDoubleClick={() => handleSort('created_time')}>
                        Creation Date
                      </TableCell>
                      <TableCell component='th' align='center' sx={{ fontWeight: 'bold', fontFamily: 'Raleway' }} onDoubleClick={() => handleSort('port_of_exit')}>
                        Exit Port
                      </TableCell>
                      <TableCell component='th' align='center' sx={{ fontWeight: 'bold', fontFamily: 'Raleway' }} onDoubleClick={() => handleSort('port_of_entry')}>
                        Entry Port
                      </TableCell>
                      <TableCell component='th' align='center' sx={{ fontWeight: 'bold', fontFamily: 'Raleway' }}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(sortedData) &&
                      (myLeadFlagToUse === 'All Leads' ? sortedData : sortedData.filter((lead) => lead?.status === myLeadFlagToUse))
                      .filter((lead) =>
                          (startDate && endDate && // Apply date range filter only if both dates are not null
                          new Date(lead?.created_time) >= startDate &&
                          new Date(lead?.created_time) <= endDate) ||
                          (startDate && !endDate && new Date(lead?.created_time) >= startDate)|| // Apply start date filter only if start date is not null))||
                        // Otherwise, include the lead
                        !startDate && !endDate
                        )
                      .map((row) => (
                        <TableRow key={row?.id}>
                          <TableCell align='center' sx={{ fontWeight: 500, fontFamily: 'Raleway' }}>{row?.id}</TableCell>
                          <TableCell align='center' sx={{ fontWeight: 500, fontFamily: 'Raleway' }}>{row?.lead_type}</TableCell>
                          <TableCell align='center' sx={{ fontWeight: 500, fontFamily: 'Raleway' }}>{row?.name}</TableCell>
                          <TableCell align='center' sx={{ fontWeight: 500, fontFamily: 'Raleway' }}>{row?.petspot_comment}</TableCell>
                          <TableCell align='center' sx={{ fontWeight: 500, fontFamily: 'Raleway' }}>{row?.travel_date}</TableCell>
                          <TableCell align='center' sx={{ fontWeight: 500, fontFamily: 'Raleway' }}>{new Date(row?.created_time).toLocaleDateString('en-IN')}</TableCell>
                          <TableCell align='center' sx={{ fontWeight: 500, fontFamily: 'Raleway' }}>{row?.port_of_exit}</TableCell>
                          <TableCell align='center' sx={{ fontWeight: 500, fontFamily: 'Raleway' }}>{row?.port_of_entry}</TableCell>
                          <TableCell align='center' sx={{ fontWeight: 500, fontFamily: 'Raleway' }}>
                            <Button variant='contained' onClick={() => goToLead(row?.id)}>
                              View
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
}

export default MyLeads;
