import React, { useEffect, useState } from 'react'
import './DocumentsRequired.css';
import { useDispatch,useSelector } from "react-redux";
import {  Select, MenuItem } from '@mui/material';

import {  getPetDocuments } from '../../../Store/actions';
import DocumentRow from './DocumentRow';





const DocumentsRequired = ({pets_info,parent_info}) => {
  
  const [selectedPet, setSelectedPet] = useState(pets_info[0] || null); // Set to null initially or the first element of pets_info if available
  
  const pet_documents = useSelector((state)=>state.global.petDocuments);
  const dispatch = useDispatch();

  

  useEffect(()=>{
      // This will refresh the page whenever, this is changes to the global states which are mentioned in dependencies
      
        console.info("UseEffect Checker in pets Document")
        const currentTime = new Date();
        const lead_id = selectedPet.parent_id
        const pet_lead_id = selectedPet.pet_id
        console.log("lead_id for getPetDocuments", lead_id)
        console.log("pet_id for getPetDocuments", pet_lead_id)
        console.log("time right now:", currentTime.toLocaleTimeString())
        dispatch(getPetDocuments({"lead_id":lead_id,"pet_id":pet_lead_id}))
      
    },[selectedPet])  

  

  console.log("selected pet", selectedPet)
 
  const setTheSelectedPet=(e)=>{
    setSelectedPet(pets_info.find((pet) => pet._id === e.target.value))
  }

  return (
    <div className='lead-info-docs-req'>
        <span className='lead-info-docs-heading'>
          <h2 className='lead-info-docs-req-head'>Status of Documents Required</h2>
          


          <Select
            value={selectedPet._id} // Set the default value for the Select component
            size='small'
            onChange={(e) => setTheSelectedPet(e)}
            sx={{ width: '300px', height: '35px', margin: '20px' }}
          >
            {pets_info.map((pet) => (
              <MenuItem key={pet._id} value={pet._id}>
                {pet.pet_name}
              </MenuItem>
            ))}
        </Select>
          
        </span> 
        <DocumentRow name="microchip" title="Microchip - Microchip Number" parent_info={parent_info} selectedPet={selectedPet} petDocuments={pet_documents} draft_name='/microchip' />
        <DocumentRow name="dhppil" title="Vaccination - Rabies and DHPPIL"  parent_info={parent_info} selectedPet={selectedPet} petDocuments={pet_documents}/>
        <DocumentRow name="rabies-vaccination" title="Rabies Vaccination Certificate" parent_info={parent_info} selectedPet={selectedPet} petDocuments={pet_documents} draft_name='/rabies-vaccination' />
        <DocumentRow name="lab-tests" title="Lab Tests - Date of Sampling & Result Value" parent_info={parent_info} selectedPet={selectedPet} petDocuments={pet_documents}/>
        <DocumentRow name="crates" title="Crates - Dimensions Number" parent_info={parent_info} selectedPet={selectedPet} petDocuments={pet_documents}/>
        <DocumentRow name="flight-bookings" title="Flight Bookings" parent_info={parent_info} selectedPet={selectedPet} petDocuments={pet_documents}/>
        <DocumentRow name="import-permit" title="Import Permit" parent_info={parent_info} selectedPet={selectedPet} petDocuments={pet_documents}/>
        <DocumentRow name="health-certificate" title="Health Certificates" parent_info={parent_info} selectedPet={selectedPet} petDocuments={pet_documents} draft_name='/health-certificate' />
        <DocumentRow name="export-permit" title="Export Permit/AQCS" parent_info={parent_info} selectedPet={selectedPet} petDocuments={pet_documents} />
    </div>
  )
}

export default DocumentsRequired