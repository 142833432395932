import React from 'react'
import './LogIn.css';
import GoogleSignIn from '../../Components/GoogleSignIn/GoogleSignIn';

const LogIn = () => {
  return (
    <div className='login'>
        <img src='login.png' alt='landing' />
        <span><GoogleSignIn /></span>
    </div>
  )
}

export default LogIn