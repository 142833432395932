import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { updateAccessToken } from '../../Store/reducers';
import { postUser } from '../../Store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import jwtDecode from "jwt-decode";
import { updateUser } from '../../Store/reducers';
import { global_url } from '../../Global/GlobalUrl';
import { postGoogleSignInAuth } from '../../Global/GlobalVariables';

const GoogleSignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.global.accessToken);

  const user = useSelector((state) => state.global.user)

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");

    if (token) {
      try {
        // Verify and decode the JWT token using the secret key
        const decoded = jwtDecode(token, { secret: "PETSPOT_CRM_2023_VIZAL" });
        
        // You can now access user_data from the decoded JWT payload
        const { email, name, _id } = decoded;

        console.log("Details for the user", email, name, _id)
        // Dispatch actions to store user_data in Redux store
        dispatch(updateUser({ email, name, _id }));

        navigate('/dashboard');
        console.log("Going to navigate to the dashboard")
      } catch (error) {
        console.error("Failed to decode/verify JWT:", error);
      }
    }
  }, []);

  const handleAuthorization = async () => {
    try {

      const headers = {};
      if (user && user.email) {
        headers.email = user.email;
      }

      
      
      const url = `${global_url}/${postGoogleSignInAuth}`;

      // Make an API request to your FastAPI backend to initiate OAuth flow
      const response = await axios.get(url, {
        headers: headers,
      });
      const { authorization_url, message } = response.data;

      if (message === 'User already authorized.') {
        // User is already authorized, navigate to /dashboard
        navigate('/dashboard');
        return;
      }
      // Redirect the user to the authorization URL returned by the backend
      window.location.href = authorization_url;
    } catch (error) {
      console.error('Error initiating OAuth flow:', error);
    }
  };

  return (
    <div>
      <h1>Gmail API Authorization</h1>
      {accessToken ? (
        <div>
          <p>Access Token: {accessToken}</p>
          {/* Use the access token to make authorized requests to Gmail API */}
        </div>
      ) : (
        <button onClick={handleAuthorization}>Login with Google</button>
      )}
    </div>
  );
};

export default GoogleSignIn;
