import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TableContainer, TableBody, TableRow, TableHead, TableCell, Paper, Table, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Select, MenuItem } from '@mui/material';
import './Payments.css';
import EditIcon from '@mui/icons-material/Edit';
import { updatePaymentForLead, getPaymentsForLead } from '../../../Store/actions';

function PaymentAmount({ amount, onEdit }) {
  return (
    <div className='lead-info-payment-amount-entry'>
      <p>{amount}</p>
      <EditIcon onClick={onEdit} />
    </div>
  );
  }


function Payments() {

    
  const payments_data = useSelector((state) => state.global.leadPayments);
  console.info("payments_data",payments_data)
    
  const [open, setOpen] = useState(false);
  const [editedAmount, setEditedAmount] = useState(0);
  const [editedPaymentMode, setEditedPaymentMode] = useState('');
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    // This useEffect will run whenever payments_data changes
    // No need for setPaymentsData as we directly use payments_data
  }, [payments_data]);

  // Handle the opening of the dialog for editing payment amount
  const handleEdit = (row, currentAmount,payment_mode) => {
    setOpen(true);
    setSelectedRowId(row._id);
    setSelectedPayment(row);
    setEditedAmount(currentAmount);
    setEditedPaymentMode(payment_mode);
  };

  // Handle submitting the edited amount
  const handleSubmit = () => {
    // Dispatch the updatePayment action with the new amount
    
    dispatch(updatePaymentForLead({
       id:selectedRowId,
       date:selectedPayment.date,
       amount:editedAmount,
       payment_mode:editedPaymentMode,
       lead_id:selectedPayment.lead_id 
    }))
    
    dispatch(getPaymentsForLead(selectedPayment.lead_id))
    setOpen(false);
  };

  // Sample function to close the dialog without saving changes
  const handleCancel = () => {
    setOpen(false);
  };

    return (
        <div className='lead-info-client-payment'>
            <h2 className='lead-info-payment-head'>Payments done by the client</h2>
            {payments_data?(
                <>
            <TableContainer component={Paper} sx={{maxHeight:250,borderRadius:'20px'}}>
                <Table stickyHeader  aria-label="sticky table" sx={{}}>
                <TableHead>
                    <TableRow>
                        <TableCell component="th" align='center' sx={{fontWeight:'bold',fontFamily:'Raleway',width:'25%',border:'1px solid #bab7b7'}}>Payment Date</TableCell>
                        <TableCell component="th" align='center' sx={{fontWeight:'bold',fontFamily:'Raleway',width:'25%',border:'1px solid #bab7b7'}}>Payment Mode</TableCell>
                        <TableCell component="th" align="center" sx={{fontWeight:'bold',color:'green' ,fontFamily:'Raleway',width:'75%',border:'1px solid #bab7b7'}}>{payments_data.reduce((acc, row) => acc + row.amount, 0)}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {payments_data.map((row) => (
                        <TableRow key={row._id}>
                            <TableCell align='center' sx={{fontWeight:500,fontFamily:'Raleway',width:'25%',border:'1px solid #bab7b7'}}>{row.date.split('T')[0].split('-').reverse().join('-')}</TableCell>
                            <TableCell align='center' sx={{fontWeight:500,fontFamily:'Raleway',width:'25%',border:'1px solid #bab7b7'}}>{row.payment_mode}</TableCell>
                            <TableCell align="center" sx={{fontWeight:500,fontFamily:'Raleway',border:'1px solid #bab7b7'}}>{
                                <PaymentAmount amount={row.amount} onEdit={() => handleEdit(row, row.amount, row.payment_mode)} />
                            }</TableCell>
                        </TableRow>
                    ))}
                    
                </TableBody>
                </Table>
            </TableContainer>

            {/* Dialog for editing payment amount */}
          <Dialog open={open} onClose={handleCancel}>
            <DialogTitle>Edit Payment Amount</DialogTitle>
            <DialogContent>
              <TextField
                label="New Amount"
                variant="outlined"
                fullWidth
                value={editedAmount}
                onChange={(e) => setEditedAmount(Number(e.target.value))}
                sx={{marginTop:.7}}
              />
            </DialogContent>
            <DialogContent>
            <span className='edit-form-cell'>
                    <label>Payment Mode</label>
                    <Select
                        labelId="payment_pop_payment_mode"
                        id="payment_mode"
                        value={editedPaymentMode}
                        label="Payment Mode"
                        onChange={(e) => {
                            setEditedPaymentMode(e.target.value);
                        }}
                        sx={{ width: '300px' }}
                    >
                        <MenuItem value={'Cash'}>Cash</MenuItem>
                        <MenuItem value={'Online'}>Online</MenuItem>
                        <MenuItem value={'UPI'}>UPI</MenuItem>
                        <MenuItem value={'Card'}>Card</MenuItem>
                    </Select>
                </span>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
            </>):
            <p>Well, we are still waiting for payment on this lead</p>}
        </div>
      )
}

export default Payments

