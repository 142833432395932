import React, { useState, useRef, useEffect } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, MenuItem } from '@mui/material';
import { Button } from '@mui/material';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { updateNewPetsData } from '../../../Store/reducers';
import { useDispatch, useSelector } from 'react-redux';
import './NewPetInfo.css'
import PetTableCell from './PetTableCell';
import PetTableDateCell from './PetTableDateCell';
import { postNewLeadPetData } from '../../../Store/actions';
import { useNavigate } from 'react-router-dom';
import { updateLeadID } from '../../../Store/reducers';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


const NewPetInfo = ({setParentSubmitted}) => {    

  const petsInfoFromStore = useSelector((state) => state.global.newPetsInfo || []);

  const [editableData, setEditableData] = useState(
    petsInfoFromStore.length > 0 ? Object.fromEntries(petsInfoFromStore.map(pet => [pet.pet_id, pet])) : {}
  );

  console.log("pets data",petsInfoFromStore)

  const [isEditMode, setIsEditMode] = useState(false);
  const [editedCells, setEditedCells] = useState({});
  const [selectedCell, setSelectedCell] = useState({});
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const inputRefs = useRef({});

  console.log("editableData",editableData)
  console.log("Info of petsInfoFromStore",(petsInfoFromStore))

  const newLeadParentData = useSelector((state)=>state.global.newParentInfo)

  
  // Set the initial value for editableData when the component mounts or pets_info changes
  const extractAndDispatchData = () => {
    // Extract the required data from editableData
    const newData = Object.values(editableData);
    // Dispatch the action with the extracted data
    dispatch(updateNewPetsData(newData));
  };
  
  useEffect(() => {
    // Call the function when editableData changes
    extractAndDispatchData();
  }, [editableData]);


  const [newPetData, setNewPetData] = useState({
    pet_id:'',
    pet_name: '',
    pet_species: '',
    pet_breed: '',
    pet_color: '',
    pet_sex: '',
    pet_birth_date_str: '',
    microchip_number: '',
    crate_flag: '',
    anti_rabies_vaccination_date: '',
    dhppil_vaccination_date: '',
    pet_weight: 0.0, 
    pet_length: 0.0, 
    pet_height: 0.0, 
    pet_elbow_height: 0.0, 
    pet_width: 0.0, 
    pet_and_crate_weight: 0.0,
    parent_id: newLeadParentData?.id
  });

  const [newPetId, setNewPetId] = useState('1');
  

  const handleAddRow = () => {
    // Convert the newPetId to a number for incrementing
    const nextPetIdNumber = parseInt(newPetId) + 1;
    
  
    // Add the new pet data to the state.
    // You can perform validation here if required before adding the data.
    setEditableData((prevEditableData) => ({
      ...prevEditableData,
      [newPetId]: { ...newPetData, id: newPetId },
    }));
  
    // Increment the pet id for the next row and convert back to a string
    setNewPetId(String(nextPetIdNumber));

    
  };


  const handleInputChange = (event, petId) => {
    const { name, value } = event.target;

    let modifiedValue = value

    if (["pet_weight", "pet_length", "pet_height", "pet_elbow_height", "pet_width", "pet_and_crate_weight"].includes(name)) {
      const floatValue = parseFloat(value);
      if (!isNaN(floatValue)) {
        modifiedValue = floatValue;
      }
    }

    setEditableData((prevEditableData) => ({
      ...prevEditableData,
      [petId]: {
        ...prevEditableData[petId],
        [name]: modifiedValue,
      },
    }));

    // Update the edited cell positions for the specific pet ID
    setEditedCells((prevEditedCells) => ({
      ...prevEditedCells,
      [petId]: [...(prevEditedCells[petId] || []), `${petId}_${name}`],
    }));
  };
  

  const handleSubmitInfo = () =>{
    console.log("will dispatch the changes")
    console.log('editableData', editableData)
    const dataArray = Object.values(editableData); // Convert the object to an array

    // Check if dataArray is not already an array (for a single row)
    if (!Array.isArray(dataArray)) {
      // Wrap it in an array
      dispatch(postNewLeadPetData([dataArray]));
    } else {
      dispatch(postNewLeadPetData(dataArray));
    }

    setEditedCells([]);
  

  }

  const handleLeadView=()=>{
    dispatch(updateLeadID(newLeadParentData?.id));
    navigate('/lead_information');
  }


  const handleCellDoubleClick = (cellData, petId) => {
    setSelectedCell({ cellData: cellData, petId: petId });
    setIsEditMode(true);
  };
  useEffect(() => {
    if (isEditMode && selectedCell) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [isEditMode, selectedCell]);

  

  
  


  const handleInputBlur = () => {
    setIsEditMode(false);
    setSelectedCell(null);
  };

  const handleInputKeyDown = (event, petId, fieldName) => {
    // Allow Enter key to trigger save and exit edit mode
    if (event.key === "Enter") {
      event.preventDefault();
      setIsEditMode(false);
      setSelectedCell(null);
    }
  
    // Handle Escape key press to exit edit mode
    if (event.key === "Escape") {
      event.preventDefault();
      setIsEditMode(false);
      setSelectedCell(null);
    }
  
    // Handle tab key press
    if (event.key === "Tab") {
      event.preventDefault();
  
      // Get the current field's index
      const fieldIndex = Object.keys(inputRefs.current[petId]).indexOf(fieldName);
  
      // Get the next field name
      const nextFieldName = Object.keys(inputRefs.current[petId])[fieldIndex + 1];
  
      // Check if there is a next field
      if (nextFieldName) {
        // Programmatically focus on the next input element
        inputRefs.current[petId][nextFieldName].focus();
      } else {
        // If there is no next field, exit edit mode and deselect the cell
        setIsEditMode(false);
        setSelectedCell(null);
      }
    }
  };

  
  

  const handleSaveClick = () => {
    // Here, you can perform any action or API call to save the updated data.
    // For now, we'll simply update the state with the edited data and exit the edit mode.
    setIsEditMode(false);
    setSelectedCell(null);
  };

  const handleDeleteRow = (petId) => {
    // Create a copy of the current editableData
    const updatedData = { ...editableData };
    // Delete the row with the specified petId
    delete updatedData[petId];
    // Update the state with the new data
    setEditableData(updatedData);
  };

  const goToNewParentData = () => {
    confirmAlert({
      title: 'Confirm',
      message: 'Are you sure you want to leave this page? Unsaved Pets data will be removed ',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            // Execute setParentSubmitted(false) if 'Yes' is clicked
            setParentSubmitted(false);
          },
        },
        {
          label: 'No',
          onClick: () => {
            // Do nothing if 'No' is clicked
          },
        },
      ],
    });
  };


  

  return (
    <div>
      
      {newLeadParentData&&<div>
      <div className='new-pet-button-container'></div>
        <div className='new-pet-go-back-button'>
            <Button variant='outlined' onClick={()=>goToNewParentData()}>View Parent Data</Button>
        </div>
        <div className='new-pet-add-button-container'>
            <Button variant='contained' onClick={handleAddRow} sx={{ margin: '1rem 0' }}>Add Row</Button>
        </div>
        
        <TableContainer component={Paper} sx={{padding: '0px 0px',border: '2px solid black',backgroundColor: 'lightgreen',fontSize: '1.1rem',margin: '1rem 0'}}>
            <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                <TableCell component="th" align='center' sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Pet Name</TableCell>
                <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Species</TableCell>
                <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Breed</TableCell>
                <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Color</TableCell>
                <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Sex-Neutered? </TableCell>
                <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>DOB</TableCell>
                <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Microchip No.</TableCell>
                <TableCell component="th" align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Crate</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.values(editableData).map((row) => (
                    <TableRow key={row.id}>

                        
                        
                    
                        <PetTableCell field="pet_name" value={editableData[row.id]?.pet_name}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />
                        <PetTableCell field="pet_species" value={editableData[row.id]?.pet_species}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />
                        <PetTableCell field="pet_breed" value={editableData[row.id]?.pet_breed}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />
                        <PetTableCell field="pet_color" value={editableData[row.id]?.pet_color}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />
                        <PetTableCell field="pet_sex" value={editableData[row.id]?.pet_sex}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />
                        <PetTableDateCell field="pet_birth_date_str" value={editableData[row.id]?.pet_birth_date_str}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />            
                        <PetTableCell field="microchip_number" value={editableData[row.id]?.microchip_number}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />
                        <PetTableCell field="crate_flag" value={editableData[row.id]?.crate_flag}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />


                        <TableCell align="center">
                            <IconButton color="black" onClick={() => handleDeleteRow(row.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>

                    </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
        <TableContainer component={Paper} sx={{padding: '0px 0px',border: '2px solid black',backgroundColor: 'lightgreen',fontSize: '1.1rem',margin: '1rem 0'}}>
            <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell align='center' sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Rabies Vaccination</TableCell>
                    <TableCell align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>DHPPIL Vaccination</TableCell>
                    <TableCell align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Weight</TableCell>
                    <TableCell align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Length</TableCell>
                    <TableCell align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Height</TableCell>
                    <TableCell align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Elbow Height</TableCell>
                    <TableCell align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Width</TableCell>
                    <TableCell align="center" sx={{fontWeight:'bold',fontFamily:'Raleway'}}>Travel Weight</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.values(editableData).map((row) => (
                    <TableRow key={row.id}>
                        {/* For the rabies vaccine */}
                        <PetTableDateCell field="anti_rabies_vaccination_date" value={editableData[row.id]?.anti_rabies_vaccination_date}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />            
                        <PetTableDateCell field="dhppil_vaccination_date" value={editableData[row.id]?.dhppil_vaccination_date}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />            
                        <PetTableCell field="pet_weight" value={editableData[row.id]?.pet_weight}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />
                        <PetTableCell field="pet_length" value={editableData[row.id]?.pet_length}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />
                        <PetTableCell field="pet_height" value={editableData[row.id]?.pet_height}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />
                        <PetTableCell field="pet_elbow_height" value={editableData[row.id]?.pet_elbow_height}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />
                        <PetTableCell field="pet_width" value={editableData[row.id]?.pet_width}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />
                        <PetTableCell field="pet_and_crate_weight" value={editableData[row.id]?.pet_and_crate_weight}  isEditMode={isEditMode} selectedCell={selectedCell} petId={row.id} editedCells={editedCells} inputRefs={inputRefs} editableData={editableData}  setIsEditMode={setIsEditMode}  setSelectedCell={setSelectedCell}  handleCellDoubleClick={handleCellDoubleClick} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} handleInputKeyDown={handleInputKeyDown} handleSaveClick={handleSaveClick} />

                    </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
        <Button variant='contained' onClick={handleSubmitInfo}>Submit Pet Information</Button>
        <Button variant='contained' onClick={handleLeadView}>View Full Lead Information</Button>
        </div>}
    </div>
  )
}

export default NewPetInfo


