import React, { useState,useRef } from 'react'
import './NewMail.css';
import {useNavigate} from 'react-router-dom';
import { Button, IconButton, TextField } from '@mui/material';
import AttachFileSharpIcon from '@mui/icons-material/AttachFileSharp';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import LinkIcon from '@mui/icons-material/Link';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { useDispatch, useSelector } from 'react-redux';
import { postNewMail } from '../../Store/actions';

const editStyles = {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    color: 'black',
    fontFamily: 'Raleway',
    position: 'relative',
}

const NewMail = ({parent_info}) => {
    const textEditorRef = useRef();
    const navigate = useNavigate();
    const [ccbcc, setCcbcc] = useState(false);
    const [files, setFiles] = useState([]);
    const [mailData, setmailData] = useState({from_email:'ashwini.11.chauhan@gmail.com'});
    const dispatch = useDispatch();    

    const user = useSelector((state)=>state.global.user)

    const handleInsert = (e) => {
        const list = e.target.files;
        console.log(list);
        console.log("array", Object.values(list));
        // Use the spread operator to concatenate the new files with the existing ones
        setFiles((prevFiles) => [...prevFiles, ...Object.values(list)]);
      }

    
      const handleSubmit = async (e) => {
        e.preventDefault();
      
        // Create a FormData object
        const formData = new FormData();
      
        // Append mail data fields to the FormData
        for (const key in mailData) {
          formData.append(key, mailData[key]);
        }
      
        // Append files to the FormData
        for (const file of files) {
          formData.append('files', file);
        }

        try {

            formData.forEach((value, key) => {
                console.log(key, value);
              });
            // Dispatch the asynchronous action to send the mail
            dispatch(postNewMail(formData));
            
            // Handle success (e.g., show a success message or redirect)
            console.log('Mail sent successfully');
          } catch (error) {
            console.error('Error sending mail:', error);
            // Handle error here (e.g., show an error message)
          }


    }


  return (
    <div className='inbox-container'>
        
        <div className='new-mail'>
            <span className='new-mail-input'>
                <label>From : </label>
                {/* <input readOnly value={user?.email} /> */}
                <input onChange={(e) => setmailData({...mailData,from_email:e.target.value})} />
            </span>
            <hr />
            <span className='new-mail-input'>
                <label>To : </label>
                <input onChange={(e) => setmailData({...mailData,to:e.target.value})} />
                <IconButton onClick={()=>setCcbcc(true)} size='small' sx={{marginRight:'1rem',display: ccbcc ? 'none':'inline'}}>CC/BCC</IconButton>
            </span>
            {ccbcc && <div>
                <hr />
                <span className='new-mail-input'>
                    <label>CC : </label>
                    <input onChange={(e) => setmailData({...mailData,cc:e.target.value})} />
                </span>
                <hr />
                <span className='new-mail-input'>
                    <label>BCC : </label>
                    <input onChange={(e) => setmailData({...mailData,bcc:e.target.value})} />
                </span>
            </div>}
            <hr />
            <input onChange={(e) => setmailData({...mailData,subject:e.target.value})} className='new-mail-subject' placeholder='Subject' />
            <hr />
            <div className='new-mail-files'>
                {files?.map((file,index) => (
                    <div className='new-mail-file-card'>
                        <p><LinkIcon />{file?.name}<IconButton sx={{height:'20px'}} onClick={(e)=>setFiles(files?.filter((file,ind)=> index!=ind))}><ClearIcon /></IconButton></p>
                    </div>
                ))}
            </div>
            <div
                ref={textEditorRef}
                contentEditable
                className='new-mail-message'
                onInput={(e) => setmailData({...mailData,message:e.target.innerHTML})}
            >
                {/* Default content goes here */}
                
            </div>
            
        </div>
        <div className='new-mail-functions'>
            <div className='new-mail-edit'>
                <Button sx={editStyles}><AttachFileSharpIcon />Insert Files<input onChange={handleInsert} multiple type='file' className='upload-button' /></Button>
                
            </div>
            <Button onClick={handleSubmit} sx={{...editStyles,fontWeight: '700'}}>Send <SendRoundedIcon /></Button>
        </div>
    </div>
  )
}

export default NewMail