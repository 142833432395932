import React from 'react'
import './LeadCard.css';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { getCompletedLeads,getFollowUpLeads, getFailedLeads, getConfirmedLeads, getToStartLeads, getInProgressLeads } from '../../Store/actions';
import { updateMyLeadsFlag } from '../../Store/reducers';
import { useNavigate } from 'react-router-dom';

const LeadCard = ({heading,value,color}) => {

const dispatch = useDispatch();
const navigate = useNavigate();


const lead_forwarding_button = () =>{
  // Only for completed leads
  switch (heading) {
    case "Completed Leads":
      dispatch(getCompletedLeads())
      break;
  
    default:
      break;
  }
  
  // Update heading in localStorage
  dispatch(updateMyLeadsFlag(heading));
  
  // Navigate to the respective page
  heading==="Follow Up Leads" ? navigate('/follow_up_leads') : navigate('/my_leads');
  heading==="Follow Up Leads" && dispatch(getFollowUpLeads());

}


  return (
    <div style={{background: color}} className='lead-card-container'>
      <h3>{heading}</h3>
      <Button onClick={()=>lead_forwarding_button()} >{value}</Button>
    </div>
  )
}

export default LeadCard