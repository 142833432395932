import React, { useEffect, useState } from 'react'
import InventoryItem from '../../Components/InventoryItem/InventoryItem';
import LeadCard from '../../Components/LeadCard/LeadCard';
import './Dashboard.css';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../Components/Navbar/Navbar';
import { useDispatch,useSelector } from 'react-redux';
import { getLeadSummary,postNewLead,getFollowUpLeads, postNewLeadParentData, getEmailTemplates, postNewMail, updateBaseDataFromGoogleSheet } from '../../Store/actions';
import { resetNewParentData, updateLeadID } from '../../Store/reducers';

const Dashboard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    var leads_summary = useSelector((state) => state.global.leadSummary);
    var user = useSelector((state) => state.global.user);
    var followUpCount = useSelector((state)=> state.global.followUpLeads)?.length;
    const existing_email_templates = useSelector((state)=>state.global.emailTemplates)
    const [formData, setFormData] = useState({name:"",mobile:"",email:""});
    
    

    const [mailData, setmailData] = useState({from_email:'ashwini.11.chauhan@gmail.com', cc:'vizal@petspot.in'});

    const handleFormSubmit = async (e) => {
        e.preventDefault();
      
        // Assuming formData is an object containing your form data
        const welcomeMailTemplate = existing_email_templates?.find((template) => template.templateName === "WelcomeMail");
      
        if (!welcomeMailTemplate) {
          console.error("WelcomeMail template not found");
          return;
        }
      
        const updatedBody = welcomeMailTemplate.body.replace(/{name}/g, formData.name);
      
        const newFormData = new FormData();
        newFormData.append('from_email', 'ashwini.11.chauhan@gmail.com');
        newFormData.append('cc', 'vizal@petspot.in');
        newFormData.append('to', formData.email);
        newFormData.append('message', updatedBody);
        newFormData.append('subject', welcomeMailTemplate.subject);
      
        try {
          console.log("Going to send the new mail now");
          newFormData.forEach((value, key) => {
            console.log(key, value);
          });
      
          // Dispatch the asynchronous action to send the mail
          await dispatch(postNewMail(newFormData));
      
          // Handle success (e.g., show a success message or redirect)
          console.log('Mail sent successfully');
        } catch (error) {
          console.error('Error sending mail:', error);
          // Handle error here (e.g., show an error message)
        }
      
        dispatch(postNewLead(formData));
        dispatch(getFollowUpLeads());
        // setFormData({name:"",mobile:"",email:""});
      }
      
      

    const goToNewLeads = () => {
        dispatch(updateLeadID(0));
        navigate('/new_lead_form');
    };

    const refreshLeads = () =>{
        // alert('going to refresh the data in the backend')
        console.log('user refreshing the leads',user)

        dispatch(updateBaseDataFromGoogleSheet({
            email:'ashwini.11.chauhan@gmail.com',
        }))
       }

    useEffect(()=>{
        dispatch(getLeadSummary());
        dispatch(getFollowUpLeads());
        dispatch(getEmailTemplates()) 
    },[dispatch]);




  return (
    <div>
        
    <div className='dashboard-container'>
        <div className='dashboard-heading-container'>
            <div className='dashboard-heading'>
                <h2>DASHBOARD</h2>
                <div>
                    <button onClick={refreshLeads} className='dashboard-button'>Refresh Leads</button>
                    <button onClick={goToNewLeads} className='dashboard-button'>New Lead</button>
                </div>
            </div>
            <div className='dashboard-leads'>
                <LeadCard heading="Follow Up Leads" value={followUpCount} color="#E5ECF6" />
                <LeadCard heading="Lead Types New" value={leads_summary?.[""]} color="#E3F5FF" />
                <LeadCard heading="Invoice Mail Sent" value={leads_summary?.["Invoice Mail Sent"]} color="#E5ECF6" />
                <LeadCard heading="Booking Confirm" value={leads_summary?.["Lead Confirmed"]} color="#E3F5FF" />
                <LeadCard heading="AQCS Done" value={leads_summary?.["AQCS Done"]} color="#E5ECF6" />
                <LeadCard heading="Completed Leads" value={leads_summary?.["Complete"]} color="#BCF0C8" />
                <LeadCard heading="Failed Leads" value={leads_summary?.["Failed"]} color="#EEBFBF" />
            </div>
            <hr />
        </div>
        <div className='dashboard-grid'>
            <img className='dashboard-graph' alt='graph' src='graph_template.png' />
            <div className='dashboard-liveInventory'>
                <h3 className='dashboard-liveInventory-heading'>Live Inventory</h3>
                <div className='dashboard-liveInventory-items'>
                    <InventoryItem />
                    <InventoryItem />
                    <InventoryItem />
                    <InventoryItem />
                    <InventoryItem />
                </div>
            </div>
            <div className='dashboard-form-container'>
                <h3>New Registration Form</h3>
                <form className='dashboard-form'>
                    <span className='dashboard-form-input'>
                        <label>Name</label>
                        <input
                            type='text'
                            value={formData.name}
                            onChange={(e)=>setFormData({...formData,name:e.target.value})}
                            placeholder='Enter Name'
                        />
                    </span>
                    <span className='dashboard-form-input'>
                        <label>Mobile</label>
                        <input
                            type='text'
                            value={formData.mobile}
                            onChange={(e)=>setFormData({...formData,mobile:e.target.value})}
                            placeholder='Enter Mobile No.'
                        />
                    </span>
                    <span className='dashboard-form-input'>
                        <label>Email</label>
                        <input
                            type='text'
                            value={formData.email}
                            onChange={(e)=>setFormData({...formData,email:e.target.value})}
                            placeholder='Enter Your Email'
                        />
                    </span>
                    <button onClick={handleFormSubmit} style={{background:'#76F3B5'}} className='dashboard-button'>Submit</button>
                </form>
            </div>
            <div className='dashboard-cargoAgentFees'>
                <h3 className='dashboard-cargoAgentFees-heading'>Cargo Agent Fees</h3>
                <div className='dashboard-cargoAgentFees-items'>
                    <InventoryItem />
                    <InventoryItem />
                    <InventoryItem />
                    <InventoryItem />
                    <InventoryItem />
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Dashboard