import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar/Navbar'
import { useDispatch,useSelector } from 'react-redux';
import { getFollowUpLeads,editFollowUpLead } from '../../Store/actions';
import './FollowUpLeads.css';
import { Button, TextField } from '@mui/material';
import PopUp from '../../Components/PopUp/PopUp';

const button_styles = {width:'150px',margin:'0',color:'white',fontFamily:'Raleway',fontSize:'0.9rem',fontWeight:700,letterSpacing:'0.1px',height:'35px',borderRadius:'3px'};


const EditFollowLead = ({props}) => {
  const {formData,setFormData,handleFormSubmit,leadId} = props;
  return (
    <div className='add-item-form-container'>
      <form className='add-item-form'>
        <h2 className='follow-item-head'>Name: {formData.name}</h2>
        <span className='add-form-cell2'>
            <label>Comment</label>
            <TextField
                type='text'
                value={formData?.comment}
                onChange={(e)=>setFormData({...formData,comment:e.target.value})}
                placeholder='Comment'
                size='small'
                sx={{width:'300px'}}
                multiline
            />
        </span>
        <span className='add-form-cell2'>
            <label>Status</label>
            <TextField
                type='text'
                value={formData?.status}
                onChange={(e)=>setFormData({...formData,status:e.target.value})}
                placeholder='Updated Status'
                size='small'
                sx={{width:'300px'}}
            />
        </span>
        <button disabled={leadId===null} onClick={handleFormSubmit} style={{background:'#76F3B5'}} className='dashboard-button'>Submit</button>
      </form>
    </div>
  )
}


function FollowUpLeads() {

  const dispatch = useDispatch();
  const [formData, setFormData ] = useState(null);
  const [leadId, setLeadId] = useState(null)

  const follow_up_leads = useSelector(state=>state.global.followUpLeads);

  useEffect(()=>{
        dispatch(getFollowUpLeads());
        
    },[dispatch]);


  const handleGetLeads = () => {
    dispatch(getFollowUpLeads());
  }
  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("editedLead",formData);
    dispatch(editFollowUpLead({...formData,id:formData._id}));
    setFormData(null);
    dispatch(getFollowUpLeads())
  }
  

  return (
    <div className='follow-up-leads'>
      
      <div className='follow-up-leads-head-container'>
        <h1 className='follow-up-leads-head'>Follow Up Leads</h1>
        <Button sx={{...button_styles,background:'#76BFF3',"&:hover":{background:'#76BFF3'}}} variant='contained' onClick={handleGetLeads}>Get Leads</Button>
      </div>
      {formData && <PopUp content={EditFollowLead} heading="Edit Lead" close={setFormData} props={{formData,setFormData,handleFormSubmit,leadId}} />}
      <div className='follow-up-lead-items'>
        {
          follow_up_leads?.map((lead) => (
            <div className='follow-up-lead-item'>
              <span className='follow-item-row'>
                <h2>Name :       {lead.name}</h2>
                <h3>Email :      {lead.email}</h3>
              </span>
              <span className='follow-item-row'>
                <h3>Mobile :      {lead.mobile}</h3>
                <h3>Status :      {lead.status}</h3>
              </span>
              <span className='follow-item-row'>
                <h3>Comment : {lead.comment}</h3>
                <Button sx={{...button_styles,background:'#76BFF3',"&:hover":{background:'#76BFF3'}}} variant='contained' onClick={() => {setLeadId(lead._id);setFormData(lead)}}>Edit lead</Button>
              </span>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default FollowUpLeads