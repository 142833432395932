import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import MyLeads from './Pages/MyLeads/MyLeads';
import B2BLeads from './Pages/B2BLeads/B2BLeads';
import FollowUpLeads from './Pages/FollowUpLeads/FollowUpLeads';
import LeadInformation from './Pages/LeadInformation/LeadInformation';
import AddLeads from './Pages/AddLeads/AddLeads';
import CargoAgents from './Pages/CargoAgents/CargoAgents';
import Dashboard from './Pages/Dashboard/Dashboard';
import './global.css';
import ItemInventory from './Pages/ItemInventory/ItemInventory';
import Expenses from './Pages/Expenses/Expenses';
import Transactions from './Pages/Transactions/Transactions';
import Microchip from './Pages/Certificates/Microchip/Microchip';
import EmailList from './Pages/Mailer/NewMailer';
import Mailer from './Pages/Mailer/Mailer';
import Homepage from './Pages/Homepage/Homepage';
import Invoice from './Pages/Certificates/Invoice/Invoice';
import RabiesVaccine from './Pages/Certificates/RabiesVaccine/RabiesVaccine';
import VetCertificate from './Pages/Certificates/VetCertificate/VetCertificate';
import LogIn from './Pages/LogIn/LogIn';
import Inbox from './Components/Inbox/Inbox';
import NewMail from './Components/NewMail/NewMail';
import NewForm from './Pages/NewForm/NewForm';
import { useSelector } from 'react-redux';
import EmailTemplate from './Pages/EmailTemplate/EmailTemplate';
import Navbar from './Components/Navbar/Navbar';


function App() {

  
  const currentState = useSelector((state) => state);
  console.log('Global Redux store state:', currentState);

  return (
    <div className="App" style={{ height: '100%', width: '100%' }}>
      
      <Router>
      <Navbar/>
        <Routes>
          <Route path = '/'  element={<LogIn />} />
          <Route path = '/dashboard'  element={<Dashboard />} />
          <Route path = '/new_lead_form'  element={<NewForm/>} />
          <Route path = '/my_leads'  element={<MyLeads/>} />
          <Route path = '/b2b_leads'  element={<B2BLeads/>} />
          <Route path = '/add_leads'  element={<AddLeads/>} />
          <Route path = '/follow_up_leads'  element={<FollowUpLeads/>} />
          <Route path = '/lead_information'  element={<LeadInformation/>} />
          <Route path = '/cargo_agents'  element={<CargoAgents/>} />
          <Route path = '/inventory'  element={<ItemInventory />} />
          <Route path = '/expenses'  element={<Expenses />} />
          <Route path = '/finance'  element={<Transactions />} />
          <Route path = '/microchip'  element={<Microchip />} />
          <Route path = '/mail'  element={<EmailTemplate/>} />
          <Route path = '/invoice'  element={<Invoice />} />
          <Route path = '/rabies-vaccination'  element={<RabiesVaccine />} />
          <Route path = '/health-certificate'  element={<VetCertificate />} />
        </Routes>
        
      </Router>
      
    </div>
  );
}

export default App;
