import React, { useState } from 'react';
import { Button, IconButton, CircularProgress } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import './ViewEmail.css';
import axios from 'axios';
import { global_url } from '../../Global/GlobalUrl';
import { getGmailAttachmentURL } from '../../Global/GlobalVariables';
import { useSelector } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import DOMPurify from 'dompurify';

const ViewEmail = ({ email, setTransactionType }) => {
  const [attachmentData, setAttachmentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openAttachmentDialog, setOpenAttachmentDialog] = useState(false);

  console.log("attachmentData",attachmentData)

  const user = useSelector((state) => state.global.user);

  const fetchAttachmentDetail = async (attachmentId, attachment_name) => {
    try {
      setLoading(true);
  
      const url = `${global_url}/${getGmailAttachmentURL}`;
  
      const response = await axios.get(
        `${url}?message_id=${email.message_id}&attachment_id=${attachmentId}&attachment_name=${attachment_name}&email=${user.email}`
      );
  
      const attachmentDataStr = response?.data?.data; // Get the Base64-encoded string
  
      // Decode the Base64 data to a Uint8Array
      // Decode the Base64 data to a Uint8Array
        const standardBase64 = attachmentDataStr.replace(/-/g, '+').replace(/_/g, '/');
        const decodedData = Uint8Array.from(atob(standardBase64), c => c.charCodeAt(0));

        // Determine the MIME type based on the attachment's filename
        let mimeType = 'application/octet-stream'; // Default to octet-stream

        if (attachment_name.toLowerCase().endsWith('.pdf')) {
            mimeType = 'application/pdf';
        } else if (
            attachment_name.toLowerCase().endsWith('.jpg') ||
            attachment_name.toLowerCase().endsWith('.jpeg')
        ) {
            mimeType = 'image/jpeg';
        } else if (attachment_name.toLowerCase().endsWith('.png')) {
            mimeType = 'image/png';
        }

        const blob = new Blob([decodedData], { type: mimeType });
        const objectURL = URL.createObjectURL(blob);
        // Create a Blob from the Uint8Array with the determined MIME type
        //   const blob = new Blob([new Uint8Array(decodedData)], { type: mimeType });

        // Create a URL for the Blob
        //   const objectURL = URL.createObjectURL(blob);

        setAttachmentData({ data: objectURL, mimeType });
    } catch (error) {
      console.error('Error fetching attachment:', error);
      // Handle error here
    } finally {
      setLoading(false);
      setOpenAttachmentDialog(true)
    }
  };
  
  
  
  const sanitizedData = (data) => ({
    __html: DOMPurify.sanitize(data)
  })

  const closeAttachmentDialog = () => {
    setOpenAttachmentDialog(false);
  };

  if (!email) {
    return <div className="view-email-container">No email selected.</div>;
  }

  return (
    <div className="view-email-container">
      <Button className="view-email-back-button" onClick={() => setTransactionType("Inbox")}>
        Go Back
      </Button>
      <h1 className="view-email-email-subject">{email.subject}</h1>

      <h2 className="view-email-section-header">Attachments:</h2>

      <div className="view-email-files">
        {email.attachment_details.map((attachment, index) => (
          <div className="view-email-file-card" key={attachment.id}>
            <p>
              <LinkIcon />
              {attachment.attachment_name}
              <IconButton sx={{ height: '20px' }} onClick={() => fetchAttachmentDetail(attachment.id,attachment.attachment_name)}>
                <OpenInFullIcon />
              </IconButton>
            </p>
          </div>
        ))}
      </div>
      
      <h2 className="view-email-section-header">Email Body:</h2>
      <div className="view-email-email-body">
        <div
        dangerouslySetInnerHTML={sanitizedData(email.email_body)}
        />
        {/* {email.email_body} */}
        </div>

      {loading && <CircularProgress />}

      {attachmentData && (
        <Dialog open={openAttachmentDialog} onClose={closeAttachmentDialog} maxWidth="lg" fullWidth>
          <DialogTitle>Your Attachment</DialogTitle>
          <DialogContent>
            {attachmentData.mimeType && attachmentData.mimeType.startsWith('image/') ? (
              <img
                src={attachmentData.data}
                alt="Attachment"
                style={{ maxWidth: '100%', maxHeight: '100%' }}
              />
            ) : (
              <iframe
                src={attachmentData.data}
                title="Attachment"
                width="100%"
                height="500px"
              ></iframe>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAttachmentDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default ViewEmail;
