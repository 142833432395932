import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Toolbar,
  IconButton,
  Box,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import './EmailTemplate.css'
import { useDispatch, useSelector } from 'react-redux';
import { deleteEmailTemplate, getEmailTemplates, sendCreateTemplate } from '../../Store/actions';

const EmailTemplate = () => {
    const [emailTemplate, setEmailTemplate] = useState({
        id: '',
        templateName: '',
        subject: '',
        body: '',
      });
      
    const [textType, setTextType] = useState('Subject')
    const [selectedOption, setSelectedOption] = useState('');
    const [toSearchOption, setToSearchOption] = useState('')
    
    const dispatch = useDispatch();
  
    console.log("textType",textType)
    const [keys, setKeys] = useState([]);
    const parent_info = useSelector((state)=>state.global.parentInformation[0])
    
    const pet_info = useSelector((state)=>state.global.petsInformation)
    const existing_email_templates = useSelector((state)=>state.global.emailTemplates)
    

    useEffect(() => {
        // Update the keys array whenever parent_info or pet_info changes
        const updatedKeys = Array.from(new Set([...Object.keys(parent_info), ...Object.keys(pet_info[0])]));
        setKeys(updatedKeys);
        console.log(updatedKeys);
      }, [parent_info, pet_info]);
 
    useEffect(()=>{
        dispatch(getEmailTemplates())
    },[])

  
      const handleTemplateChange = (e) => {
        const selectedValue = e.target.value;
        const foundTemplate = existing_email_templates.find((template) => template.id === selectedValue);
        setEmailTemplate((prevTemplate) => ({
            ...prevTemplate,
            body: foundTemplate.body,
            id: foundTemplate.id,
            templateName: foundTemplate.templateName,
            subject: foundTemplate.subject,
          }));


      };

      const handleSelectedOption = (e) =>{
        console.log("Selected Option", e.target.value)
        setSelectedOption(e.target.value)
      }

      const handleAutomateTemplate = () => {
        if (textType === 'Subject') {
          setEmailTemplate((prevTemplate) => ({
            ...prevTemplate,
            subject: prevTemplate.subject.replaceAll(toSearchOption, '{' + selectedOption + '}'),
          }));
        } else if (textType === 'Body') {
          setEmailTemplate((prevTemplate) => ({
            ...prevTemplate,
            body: prevTemplate.body.replaceAll(toSearchOption, '{' + selectedOption + '}'),
          }));
        }
      };
      
      const handleSaveTemplate=()=>{
        console.log("going to send the template")
        dispatch(sendCreateTemplate({
            id: emailTemplate.id,
            templateName: emailTemplate.templateName,
            subject: emailTemplate.subject,
            body: emailTemplate.body,
        }))
      }

      const handleDeleteTemplate=()=>{
        console.log("going to send the template")
        console.log(emailTemplate)
        dispatch(deleteEmailTemplate(emailTemplate.id))
        //     id: emailTemplate.id,
        //     templateName: emailTemplate.templateName,
        //     subject: emailTemplate.subject,
        //     body: emailTemplate.body,
        // }))
      }
    
    return (
        <div>
          {parent_info && pet_info && (
            <div>
                <Grid container>
                    <Grid item xs={9}>
                <Typography variant="h4" gutterBottom className="email-template-header">
                    Create your Email Templates for easier usage
                  </Typography>
                  </Grid>
                  <Grid item xs={3}  sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                  <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleSaveTemplate()
                  }}
                >
                  Save Template
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleDeleteTemplate()
                  }}
                >
                  Delete Template
                </Button>
                  </Grid>

                  </Grid>
            <Grid container>
              {/* Container for TemplateName, Subject, and Body */}
              <Grid item xs={9} sx={{marginTop:2}}>
                <Box sx={{ width: '100%' }}>
                  
                  <TextField
                    label="Template Name"
                    fullWidth
                    value={emailTemplate.templateName}
                    onChange={(e) => setEmailTemplate({ ...emailTemplate, templateName: e.target.value })}
                    sx={{ padding: '10px' }}
                  />
                  <TextField
                    label="Subject"
                    fullWidth
                    value={emailTemplate.subject}
                    onChange={(e) => setEmailTemplate({ ...emailTemplate, subject: e.target.value })}
                    sx={{ padding: '10px' }}
                  />
                  <TextField
                    id="body-textfield"
                    label="Body"
                    multiline
                    fullWidth
                    value={emailTemplate.body}
                    minRows={15}
                    onChange={(e) => setEmailTemplate({ ...emailTemplate, body: e.target.value })}
                    sx={{
                      width: '100%',
                      fontFamily: 'Raleway',
                      color: '#000',
                      fontSize: '1.1rem',
                      fontWeight: 200,
                      minHeight: '10em',
                      padding: '10px',
                    }}
                    inputProps={{
                      style: {
                        overflow: 'hidden',
                      },
                    }}
                  />
                </Box>
              </Grid>
    
              {/* Container for Toolbar */}
              <Grid item xs={3} sx={{marginTop:2}}>
              <Toolbar sx={{ display: 'flex', alignItems: 'center',justifyContent:'space-evenly', flexDirection: 'column', height:0.6 }}>
                    <h2 className='toolbar-header'>Automate your template</h2>
                    <FormControl fullWidth sx={{ marginTop: 3 }}>
                        <InputLabel id="select-option-label">Select Text Type</InputLabel>
                        <Select
                            variant='standard'
                            labelId="select-option-label"
                            id="select-option"
                            fullWidth
                            value={textType}
                            onChange={(e) => setTextType(e.target.value)}
                        >
                            <MenuItem value="Subject">Subject</MenuItem>
                            <MenuItem value="Body">Body</MenuItem>
                        </Select>
                    </FormControl>
                        <TextField
                            label="To Change"
                            variant='standard'
                            fullWidth
                            value={toSearchOption}
                            onChange={(e) => setToSearchOption(e.target.value)}
                            sx={{padding:'10px'}}
                            />
                    <FormControl fullWidth sx={{ marginTop: 3 }}>
                        <InputLabel id="select-option-label">Select Form Eqvivalent</InputLabel>      
                        <Select
                            labelId="select-option-label"
                            variant='standard'
                            id="select-option"
                            value={selectedOption}
                            fullWidth
                            onChange={(e) => handleSelectedOption(e)}
                            
>
                            
                            {keys.map((option) => (
                            <MenuItem key={option} value={option} defaultValue={keys[3]}>
                                {option}
                            </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Button onClick={()=>handleAutomateTemplate()}>Automate Template</Button>
        
                        
                </Toolbar>
                {existing_email_templates&&
                <Toolbar sx={{ display: 'flex', alignItems: 'center',justifyContent:'space-evenly', flexDirection: 'column', height:0.25 }}>
                    <h2 className='toolbar-header'>Select existing template</h2>
                    <FormControl fullWidth sx={{ marginTop: 3 }}>
                        <InputLabel id="select-option-label">All Templates</InputLabel>      
                        <Select
                            labelId="select-option-label"
                            variant='standard'
                            id="select-option"
                            value={selectedOption}
                            fullWidth
                            onChange={(e) => handleTemplateChange(e)}
                            
                        >
                            
                            {existing_email_templates.map((option) => (
                            <MenuItem key={option.id} value={option.id} defaultValue={option.templateName}>
                                {option.templateName}
                            </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Toolbar>}
              </Grid>
    
              
                

            </Grid>
            </div>
          )}
        </div>
      );
    };
    
  export default EmailTemplate;
