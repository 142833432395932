import React from 'react'
import Navbar from '../../Components/Navbar/Navbar';

function B2BLeads() {
  return (
    <div className='b2b_leads'>
        <h1>B2B Leads</h1>
    </div>
  )
}

export default B2BLeads