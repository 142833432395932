import React, { useEffect, useState } from 'react'
import './Invoice.css';
import {Button, TableContainer,Paper,TableBody,TableCell,Table,TableRow, IconButton,TextField,Dialog, DialogTitle,DialogContent } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { postNewMail } from '../../../Store/actions';
import html2pdf from 'html2pdf.js';


const button_styles = {
    height: '70px',
    width: '190px',
    background:'yellow',
    color:'red',
    fontFamily: 'Raleway',
    fontSize: '1.8rem',
    fontWeight: '700',
    '&:hover':{background: 'yellow'}
}

const Invoice = (parent_data, pets_data) => {

    const [tableData, setTableData] = useState([
        [
            "Services - Break Up",
            "Yes/No",
            "Price Estimate for 'Manifest Cargo' 32 inch crate",
            "Price Estimate for 'Manifest Cargo' 36 inch crate",
        ],
        [
            "Procuring the crate",
            "",
            "9000",
            "11500"
        ],
        [
            "Water Sipper",
            "",
            "500",
            "500"
        ],
        [
            "Pre-Flight Boarding in origin country @₹1000 per day",
            "Optional",
            "",
            ""
        ],
        [
            "International Air freight charges - Dubai to Delhi",
            "",
            "63052",
            "68000"
        ],
        [
            "Pet-friendly taxi service (To Export office, vets, airport) ",
            "Optional",
            "",
            ""
        ],
        [
            "Microchip - Procurement & Insertion ",
            "",
            "Already Procured",
            "Already Procured"
        ],
        [
            "Origin services in India - Exportation Processing, Documentation, Government endorsed Vet Inspection, export permits, Clearance, test, Handling as applicable",
            "",
            "23600",
            "23600"
        ],
        [
            "Cargo booking and handling charges",
            "",
            "11800",
            "11800"
        ],
        [
            "Rabies Titer Tests to be conducted in India or Europe - Blood sample collection, dispatch to an approved testing lab, and obtain an approval clearance certificate",
            "",
            "12000",
            "12000"
        ],
        [
            "Health Certificate, health certificate , Defleaing, deworming (Vet Check) ",
            "",
            "2500",
            "2500"
        ],
        [
            "Destination Services - Documentation, Entry Port Processing, import permit, Declaration, Inspections, Duties/GST, Clearance till Pet At Animal Reception Centre (Dubai Agent Charges)",
            "",
            "AED 3950",
            "AED 3950"
        ],
        [
            "Domestic Relocation with in India",
            "",
            "",
            ""
        ],
        [
            "Total Estimated Amount Payable for Assignment",
            "",
            "Rs 120652 + AED 3950",
            "Rs 128100 + AED 3950"
        ],
    ])
    const [cols, setCols] = useState(4);
    const [currentSNo, setCurrentSNo] = useState(1);
    const [invoiceData, setInvoiceData] = useState(null);
    const existing_email_templates = useSelector((state)=>state.global.emailTemplates)
    
    const dispatch = useDispatch();

    useEffect(() => {
        // Retrieve the data from localStorage in the new tab
        const data = localStorage.getItem('leadData');
        if (data) {
            setInvoiceData(JSON.parse(data));
        }
    }, []);

    console.log("invoiceData", invoiceData)
    console.log("Email", invoiceData?.parent_info?.parent_data[0]?.email)
    
    
    console.log("Email", invoiceData?.pet_info?.pets_data[0]?.pet_name)

    const petNames = invoiceData?.pet_info?.pets_data?.map((pet) => pet.pet_name);
    const combinedNames = petNames?.join(', ');

    const [rowToDelete, setRowToDelete] = useState('');
    const [isDeleteRowDialogOpen, setIsDeleteRowDialogOpen] = useState(false);
    const [columnToDelete, setColumnToDelete] = useState('');
    const [isDeleteColumnDialogOpen, setIsDeleteColumnDialogOpen] = useState(false);

    console.log(combinedNames);

    
    const handleInvoiceButtonSubmit = async (e) => {
        e.preventDefault();
    
        // Get all elements with the class name "printable"
    const printableElements = document.querySelectorAll('.printable');

    // Create a container element to hold all the printable elements
    const container = document.createElement('div');

    // Append each printable element to the container
    printableElements.forEach((element) => {
        container.appendChild(element.cloneNode(true)); // Clone the element to avoid affecting the original DOM
    });

    

    setTimeout(() => {
        // Define PDF generation options
        const options = {
            margin: [5, 5, 5, 5],
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 }, // You can adjust this scale as needed
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };
    
            // Generate the PDF from the container element
            html2pdf().from(container).set(options).outputPdf('bloburl').then(pdfBlobURL => {
                fetch(pdfBlobURL)
                    .then(response => response.blob())
                    .then(pdfBlob => {
                        // Create a File object from the Blob
                        const filename = `Performa-Invoice-${invoiceData?.parent_info?.parent_data[0]?.id}.pdf`; // Set the desired filename
                        const file = new File([pdfBlob], filename, { type: 'application/pdf' });
    
                        // Create a download link for the file
                        const downloadLink = document.createElement('a');
                        downloadLink.href = URL.createObjectURL(file);
                        downloadLink.download = filename;
    
                        // Trigger a click event on the download link to start the download
                        downloadLink.click();
    
                        // Assuming formData is an object containing your form data
                        const invoiceMailTemplate = existing_email_templates?.find((template) => template.templateName === "InvoiceMail");
    
                        if (!invoiceMailTemplate) {
                            console.error("invoiceMailTemplate template not found");
                            return;
                        }
    
                        var updatedBody = invoiceMailTemplate.body.replace(/{name}/g, invoiceData?.parent_info?.parent_data[0]?.name);
                        updatedBody = updatedBody.replace(/{pet_name}/g, combinedNames);
                        const updatedsubject = invoiceMailTemplate.subject.replace(/{lead_id}/g, invoiceData?.parent_info?.parent_data[0]?.id);
    
                        const newFormData = new FormData();
                        newFormData.append('from_email', 'ashwini.11.chauhan@gmail.com');
                        newFormData.append('cc', 'vizal@petspot.in');
                        newFormData.append('to', invoiceData?.parent_info?.parent_data[0]?.email);
                        newFormData.append('message', updatedBody);
                        newFormData.append('subject', updatedsubject);
                        newFormData.append('files', file)
    
                        try {
                            console.log("Going to send the invoice mail now");
                            newFormData.forEach((value, key) => {
                                console.log(key, value);
                            });
    
                            // Dispatch the asynchronous action to send the mail
                            dispatch(postNewMail(newFormData));
    
                            // Handle success (e.g., show a success message or redirect)
                            console.log('Mail sent successfully');
                        } catch (error) {
                            console.error('Error sending mail:', error);
                            // Handle error here (e.g., show an error message)
                        }
                    })
                    .catch(error => {
                        // Handle any error that occurs during the conversion to Blob
                        console.error('Error converting to Blob:', error);
                    });
            });
        }, 1000);
    };
    
    


    const openDeleteRowDialog = () => {
        setIsDeleteRowDialogOpen(true);
    };
    const openDeleteColumnDialog = () => {
        setIsDeleteColumnDialogOpen(true);
    };

    const handleRowDelete = () => {
        const rowToDeleteNumber = parseInt(rowToDelete, 10);
        console.log("rowToDeleteNumber",rowToDeleteNumber)
        if (!isNaN(rowToDeleteNumber) && rowToDeleteNumber > 0 && rowToDeleteNumber <= tableData.length) {
            // Filter out the row with the given row number
            const updatedTableData = tableData.filter((_, index) => index !== rowToDeleteNumber);
            setTableData(updatedTableData);
            setCurrentSNo(currentSNo - 1);
            setIsDeleteRowDialogOpen(false);
        } else {
            // Handle invalid input, display an error message or alert
            alert('Please enter a valid row number to delete.');
        }
    };

    const handleAddColumn = () => {
        const updatedTableData = tableData.map((row, rowIndex) => {
            const newRow = [...row];
            newRow.push(row[cols - 1]); // Copy values from the last column
            console.log("Testing",row[row.length - 1])
            
            return newRow;
        });
        setTableData(updatedTableData);
        setCols(cols + 1);
    };
    
    const handleColumnDelete = () => {
        const columnToDeleteNumber = parseInt(columnToDelete, 10)-1;
        if (!isNaN(columnToDeleteNumber) && columnToDeleteNumber > 0 && cols > 1 && columnToDeleteNumber <= cols) {
            const updatedTableData = tableData.map((row) => {
                return row.filter((_, index) => index !== columnToDeleteNumber - 1);
            });
            setTableData(updatedTableData);
            setCols(cols - 1);
            setIsDeleteColumnDialogOpen(false);
        } else {
            alert('Please enter a valid column number to delete.');
        }
    };
      


  return (
    <> {invoiceData?
    <div className='invoice-container'>
        <div className='invoice-heading printable'>
            <div className='invoice-names'>
                <h3>{invoiceData?.parent_info?.parent_data[0]?.email}</h3>
                <h3>Invoice for {invoiceData?.parent_info?.parent_data[0]?.name}</h3>
                <h3 className='invoice-styled'>{combinedNames} Relocation from {invoiceData?.parent_info?.parent_data[0]?.port_of_exit} to {invoiceData?.parent_info?.parent_data[0]?.port_of_entry}</h3>
            </div>
            <div className='invoice-logo'>
                <img src='petspot-logo.png' alt='logo' />
                <Button onClick={handleInvoiceButtonSubmit} sx={button_styles} variant='contained'>Estimate</Button>
            </div>
        </div>
        <h3 className='invoice-table-head invoice-styled'>Cost Estimate</h3>
        <TableContainer component={Paper} sx={{padding: '0px 0px',border: '2px solid black',backgroundColor: 'lightyellow',fontSize: '1.1rem',margin: '1rem 0',display:'flex',alignItems:'center'}}>
            <Table className='lead-info-table printable' aria-label="simple table">
                <TableBody>
                    {tableData?.map((row, ind1) => (
                        <TableRow key={ind1}>
                            <TableCell align='center' sx={{ fontFamily: 'Raleway', fontWeight: 500, border: '1px solid black', padding: '0.7rem' }}>
                                {ind1 === 0 ? 'S.No' : ind1}
                            </TableCell>

                            {row?.map((col, ind2) => (
                                <TableCell align='center' sx={{ fontFamily: 'Raleway', fontWeight: 500, border: '1px solid black', padding: '0.7rem' }}>
                                    <textarea className='invoice-input' style={{ maxWidth: col ? `${(col?.toString()?.length) * 9}px` : '100%', fontWeight: ind1 === 0 ? '700' : '500' }} value={col} onChange={(e) => { var temp = [...tableData]; temp[ind1][ind2] = e.target.value; setTableData(temp) }} />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
           
        </TableContainer>

        <div className='add-delete-icons-container'>
            <div className='add-or-delete-column'>
                <h3>Modify Column from here</h3>
                <div className ='modify-icons-in-add-delete'>
                    <IconButton onClick={()=>{
                        const lastColumn = tableData.map(row => row[cols - 1]); // Extract values from the last column

                        // Create a new tableData array with the last column values appended as a new column
                        const updatedTableData = tableData.map((row, index) => [...row, lastColumn[index]]);
                    
                        // Update the tableData state with the new data and increment the cols count
                        setTableData(updatedTableData);
                        setCols(cols + 1);
                    }} sx={{width:'20%',height:'100%',borderRadius: '0', color:'green'}}><AddIcon /></IconButton>
                    <IconButton onClick={() => {
                       openDeleteColumnDialog()
                    }} sx={{width:'20%',height:'100%',borderRadius: '0',color:'red'}}><DeleteIcon /></IconButton>
                    </div>
                </div>

            <div className='add-or-delete-row'>
                <h3>Modify Row from here</h3>
                <div className ='modify-icons-in-add-delete'>
                    
                    <IconButton onClick={()=>{
                        const newRow = new Array(cols).fill("");
                        setTableData([...tableData,newRow])
                        }} sx={{width:'20%',height:'100%',borderRadius: '0',color: 'darkgreen'}}>
                            <AddIcon />
                    </IconButton>

                    <IconButton onClick={openDeleteRowDialog}
                    sx={{width:'20%',height:'100%',borderRadius: '0', color: 'red'}} >
                            <DeleteIcon />
                    </IconButton>    
                </div>      
            </div>
        </div>
        
        
        <div className='invoice-relocation printable'>
            <h3>Types of Relocation</h3>
            <ul>
                <li>Accompanied Baggage - Pet travels in the same flight as the owner/person responsible for the pet.</li>
                <li>Manifest Cargo - Pet travels alone as 'Cargo'. Could be a passenger flight or a cargo flight.</li>
            </ul>
        </div>  

        <div className='invoice-terms printable'>
            <h3>Payment Terms</h3>
            <ul>
                <li>Advance payment of 50% of Total estimate to be paid at the beginning of the assignment.</li>
                <li>Difference amount of estimate to actual assignment cost to be paid 5 business days before the departure date.</li>
                <li>For estimates in a currency other than INR, payment to be made in that particular currency. If paid in INR, the prevailing buying rate at an authorised forex at the time of departure will apply.</li>
                <li>This Cargo shipping cost is valid only for 30 days from the date of cost estimates </li>
                <li>Refunds - If any, will be paid 2 days before the departure date. </li>
                <li>GST on Service charge is 18% as applicable</li>
            </ul>
        </div>

        <Dialog open={isDeleteRowDialogOpen} onClose={() => setIsDeleteRowDialogOpen(false)}>
            <DialogTitle>Delete Row</DialogTitle>
            <DialogContent>
                <TextField
                    label="Row Number"
                    type="number"
                    value={rowToDelete}
                    onChange={(e) => setRowToDelete(e.target.value)}
                />
                <Button onClick={handleRowDelete} variant="contained" color="secondary">
                    Delete
                </Button>
            </DialogContent>
        </Dialog>

        <Dialog open={isDeleteColumnDialogOpen} onClose={() => setIsDeleteColumnDialogOpen(false)}>
            <DialogTitle>Delete Row</DialogTitle>
            <DialogContent>
                <TextField
                    label="Column Number"
                    type="number"
                    value={columnToDelete}
                    onChange={(e) => setColumnToDelete(e.target.value)}
                />
                <Button onClick={handleColumnDelete} variant="contained" color="secondary">
                    Delete
                </Button>
            </DialogContent>
        </Dialog>

        
    </div>:null}
    </>
  )
}

export default Invoice