import React,{useDebugValue, useEffect} from 'react'
import Navbar from '../../../Components/Navbar/Navbar'
import { Table, TableCell, TableContainer, TableRow, TableBody, Paper, TableHead, Button } from '@mui/material'
import './Microchip.css';
import html2pdf from 'html2pdf.js';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { postPetDocument } from '../../../Store/actions';


const button_styles = {width:'150px',margin:'0 4rem 0 0',color:'white',fontFamily:'Raleway',fontSize:'0.9rem',fontWeight:700,letterSpacing:'0.1px',height:'35px',borderRadius:'3px'};

const Microchip = () => {

  const [microchipData, setMicrochipData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    // Retrieve the data from localStorage in the new tab
    const data = localStorage.getItem('leadData');
    if (data) {
      setMicrochipData(JSON.parse(data));
    }
  }, []);

  
  console.log("microchip Data",microchipData)

  

  const handlePrint = (e) => {
    e.preventDefault();
    const options = {
      filename: 'microchip-certificate.pdf',
      margin: [0,0,0,0],
      image: { type: 'pdf', quality: 1 },
      html2canvas: { scale: 3 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    const element = document.querySelector('.print-content');
    html2pdf().from(element).set(options).save();
  }

  const [fileURL, setFileURL] = useState(null);

  const handleSave = () => {
    const options = {
      filename: 'microchip-certificate.pdf',
      margin: [0,0,0,0],
      image: { type: 'pdf', quality: 1 },
      html2canvas: { scale: 3 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    const parentId = microchipData?.pet_info?.selectedPet?.parent_id;
    const filename = parentId ? `${parentId}-microchip-certificate-draft.pdf` : "microchip-certificate-draft.pdf";

    const element = document.querySelector('.print-content');
    html2pdf().from(element).set(options).outputPdf('bloburl').then(pdfBlobURL => {
      fetch(pdfBlobURL)
      .then(response => response.blob())
      .then(pdfBlob => {
        var file = new File([pdfBlob], filename, { type: "application/pdf" });
        const fileUrl = URL.createObjectURL(file);
        setFileURL(fileUrl);
        dispatch(postPetDocument({
          "doc_id":null,
          "lead_id":microchipData?.pet_info?.selectedPet?.parent_id,
          "pet_id":microchipData?.pet_info?.selectedPet?.pet_id,
          "document_name":'microchip-draft',
          "pdf":file,
          "image_id": null, 
          "pdf_id": null, 
        }))
      })
      .catch(error => {
        // Handle any error that occurs during the conversion to Blob
        console.error('Error converting to Blob:', error);
      });
    });
  }


  return (
    <div>
      {microchipData && (
        <div>
              <div className='not-print'>
            
              </div>
              <div id='print' className='microchip print-content'>
                <h1 className='microchip-heading'>MICROCHIP CERTIFICATE</h1>
                <h3 className='microchip-text'>This is to certify that the CAT belonging to:</h3>
                <span className='microchip-input'>
                  <h2 className='microchip-head-2'>Name of the Owner :</h2>
                  <input defaultValue={microchipData?.parent_info?.parent_info?.name}/>
                </span>
                <span className='microchip-input'>
                  <h2 className='microchip-head-2'>Address of the Owner :</h2>
                  <input defaultValue={microchipData?.parent_info?.parent_info?.address_in_india}/>
                </span>
                <h3 className='microchip-text'>With the following description was presented to the below mentioned Veterinary Doctor/ Hospital for Microchip reading.</h3>
                <table>
                  <thead>
                    <tr>
                      <th>S. No</th>
                      <th>Species</th>
                      <th>Breed</th>
                      <th>Sex</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><input className='table-input' defaultValue={1}/></td>
                      <td><input className='table-input' defaultValue={microchipData?.pet_info?.selectedPet?.pet_species}/></td>
                      <td><input className='table-input' defaultValue={microchipData?.pet_info?.selectedPet?.pet_breed}/></td>
                      <td><input className='table-input' defaultValue={microchipData?.pet_info?.selectedPet?.pet_sex}/></td>
                  </tr>
                  </tbody>
                </table>
                <table>
                  <thead>
                    <tr>
                      <th>DOB</th>
                      <th>Color</th>
                      <th>Microchip No</th>
                      <th>Name - If any</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><input className='table-input' defaultValue={microchipData?.pet_info?.selectedPet?.pet_birth_date_str}/></td>
                      <td><input className='table-input' defaultValue={microchipData?.pet_info?.selectedPet?.pet_color}/></td>
                      <td><input className='table-input' defaultValue={microchipData?.pet_info?.selectedPet?.microchip_number}/></td>
                      <td><input className='table-input' defaultValue={microchipData?.pet_info?.selectedPet?.pet_name}/></td>
                  </tr>
                  </tbody>
                </table>
                <h3 className='microchip-text'>The Pet has been read with a microchip on <input /> as per Hospital Documentation, on Owner confirmation.</h3>
                <h3 className='microchip-text'>I/We have confirmed to have read a 15 digit ISO 11784/785 microchip located at the nape of the neck in between the shoulders.</h3>
                <h3 className='microchip-text'>The microchip number is : <input defaultValue={microchipData?.pet_info?.selectedPet?.microchip_number}/></h3>
                <h3 className='microchip-text'>Date : <input /></h3>
                <h3 className='microchip-text'>Vet Name : <input /></h3>
                <h3 className='microchip-text'>Clinic/Hospital Name : <input /></h3>
                <h3 className='microchip-text'>Vet registered No/Code : <input /></h3>
                <span className='signature' align="right" >
                  <input className='signature-input' />
                  <h2 align="center" style={{marginTop:'0.5rem',width:'200px'}} className='microchip-text'>Sign & Stamp</h2>
                </span>
              </div>
              <div className='not-print pad'>
                <Button sx={{...button_styles,background:'#76BFF3',"&:hover":{background:'#76BFF3'}}} onClick={handlePrint}>Download</Button>
                <Button sx={{...button_styles,background:'#76F4B5',"&:hover":{background:'#76F4B5'}}} onClick={handleSave}>Save</Button>
              </div>
              <div className='pad'>
                <h2>File Preview</h2>
                <embed src={fileURL} type="application/pdf" width="100%" height="500px" />
              </div>
        </div>
      )}
      

    </div>
  )
}

export default Microchip