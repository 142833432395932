import { createSlice } from "@reduxjs/toolkit";
import { 
    deleteCargoAgent,
    getCargoAgents, 
    getCompletedLeads, 
    getConfirmedLeads, 
    getFailedLeads,
    getInProgressLeads, 
    getLeadParentInformation, 
    editParentInformation,
    getLeadSummary, 
    getPetsInformation, 
    getToStartLeads,
    putCargoAgents,
    updateBaseDataFromGoogleSheet,
    getFollowUpLeads,
    getItems ,
    getItemTransaction,
    getExpenseTransaction,
    getExpense,
    getItemSales,
    postItemSale,
    deleteItemSale,    
    getLeadPetInformation,
    getLeadParentInformationByID,
    getPaymentsForLead,
    getUserActions,
    postUser,
    postComment,
    sendCreateTemplate,
    getEmailTemplates,
    getPetDocuments,
    getImagePetDocument,
    getPDFPetDocument,
    postPetDocument,
    getItemSaleTransaction,
    getPayments,
    postNewLeadParentData,
    postNewLeadPetData,
    postPayment,
    deleteEmailTemplate,
    deletePetInformation,
    editPetInformation,
    getExpenseSales,
    deleteExpenseSale,
    postExpenseSale

} from "./actions";

const initialState = {
    user: null,
    myleads: null,
    leadId:null,
    accessToken:null,
    myleadsflag:null,
    leadSummary: null,
    leadParentInformation:[],
    parentInformation:null,
    petsInformation:[],
    totalPetsFlag: {
      parentId: null,
      flag: 0,
    },
    cargoAgents:null,
    petspotItems:null,
    followUpLeads:null,
    items:null,
    expenses:null,
    itemSalesTransactions:null,
    expenseSalesTransactions:null,
    leadPayments:null,
    userActions:null,
    newParentInfo:null,
    newPetsInfo:[],
    emailTemplates:[],
    petDocuments:[],
    imagePetDocument:null,
    pdfPetDocument:null,
    finance_sales:[],
    finance_payments:[],
    finance_purchases:[],
    finance_expenses:[],
}

export const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        updateUserChannels: (state, action) => {
            state.userChannels = action.payload;
        },
        updateCompletedLeads: (state, action) =>{
            
            state.myleads  = action.payload
        },
        updateFailedLeads: (state, action) =>{
            
            state.myleads  = action.payload
        },
        updateInProgressLeads: (state, action) =>{
            
            state.myleads  = action.payload
        },
        updateToStartLeads: (state, action) =>{
            
            state.myleads  = action.payload
        },
        updateConfirmedLeads: (state, action) =>{
            
            state.myleads  = action.payload
        },
        updateLeadsSummary: (state, action) =>{
            state.leadSummary  = action.payload
        },
        updateLeadParentInfo: (state, action) =>{
            state.leadParentInformation = action.payload
        },
        updatePetsInfo: (state, action) =>{
            state.petsInformation = action.payload
        },
        updateCargoAgents: (state, action) =>{
            state.cargoAgents = action.payload
        },
        updateLeadID: (state, action) =>{
            state.leadId = action.payload
        },
        updateUser: (state, action) =>{
          state.user = action.payload
        },
        updateAccessToken: (state, action) =>{
            state.accessToken = action.payload
        },
        updateNewParentData :(state, action)=>{
          state.newParentInfo = action.payload
        },
        updateNewPetsData :(state, action)=>{
          state.newPetsInfo = action.payload
        },
        resetNewParentData: (state) => {
          state.newParentInfo = null;
        },
        resetNewPetsData: (state) => {
          state.newPetsInfo = [];
        },
        updateMyLeadsFlag: (state, action) =>{
          state.myleadsflag = action.payload
        },
        
        
        
    },
    extraReducers: (builder) => {
        builder
          
          .addCase(getCompletedLeads.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    console.info("completed leads fetched")
                    state.myleads = action.payload
                    state.myleadsflag ="Woah! Angels have flown successfully by Petspot Team. Congrats"
                    
                }else {
                    state.myleads = null
                    
                }
            } catch(error) {
                alert("Error in getting completed leads. Please contact admin")
            }
          })
          .addCase(getCompletedLeads.rejected, (state)=>{
            state.myleads = null
          })
          .addCase(getFailedLeads.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    state.myleads = action.payload
                    state.myleadsflag ="OOOOPs,, not all pets can get good service"
                    
                }else {
                    state.myleads = null
                    
                }
            } catch(error) {
                alert("Error in getting failed leads. Please contact admin")
            }
          })
          .addCase(getFailedLeads.rejected, (state)=>{
            state.myleads = null
          })
          .addCase(getInProgressLeads.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    state.myleads = action.payload
                    state.myleadsflag ="So exciting, we are working for these pets to fly with petspot"
                    
                }else {
                    state.myleads = null
                    
                }
            } catch(error) {
                alert("Error in getting in progress leads. Please contact admin")
            }
          })
          .addCase(getInProgressLeads.rejected, (state)=>{
            state.myleads = null
          })
          .addCase(getToStartLeads.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    state.myleads = action.payload
                    state.myleadsflag ="Yes, wholla lot of new pets want to fly with petspot"
                  
                }else {
                    state.myleads = null
                   
                }
            } catch(error) {
                alert("Error in getting to start leads. Please contact admin")
            }
          })
          .addCase(getToStartLeads.rejected, (state)=>{
            state.myleads = null
          })
          .addCase(getConfirmedLeads.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    state.myleads = action.payload
                    state.myleadsflag ="YAY, pets confirmed to fly with petspot. Lot of work to be done"
                  
                }else {
                    state.myleads = null
                   
                }
            } catch(error) {
                alert("Error in getting to start leads. Please contact admin")
            }
          })
          .addCase(getConfirmedLeads.rejected, (state)=>{
            state.myleads = null
          })
          .addCase(getLeadSummary.pending,()=>{
            console.info("getLeadSummary pending")
          })
          .addCase(getLeadSummary.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    
                    state.leadSummary = action.payload
                  
                }else {
                    console.info("No payload in action")
                   
                }
            } catch(error) {
                console.info("Error in getting to start leads. Please contact admin")
            }
          })
          .addCase(getLeadSummary.rejected, (state)=>{
            console.info("getLeadSummary rejected")
          })
          .addCase(getLeadParentInformation.fulfilled , (state, action)=>{
            try{
                console.log(action);
                if(action.payload){
                    
                    state.leadParentInformation = action.payload
                  
                }else {
                    console.info("No payload in action")
                   
                }
            } catch(error) {
                console.info("Error in getting to parent info for leads. Please contact admin")
            }
          })
          .addCase(getLeadParentInformation.rejected, (state)=>{
            console.info("getLeadParentInformation rejected")
            state.leadParentInformation = []
          })
          .addCase(editParentInformation.fulfilled, (state, action) => {
            try {
              console.log(action);
              if (action.payload) {
                state.leadParentInformation = action.payload;
              } else {
                console.info("No payload in action");
              }
            } catch (error) {
              console.info("Error in updating parent info for leads. Please contact admin");
            }
          })
          .addCase(postComment.fulfilled, (state, action) => {
            try {
              console.log(action);
              if (action.payload) {
                const updatedLead = action.payload; // Assuming action.payload contains the updated lead data
                const updatedLeadIndex = state.leadParentInformation.findIndex(lead => lead.id === updatedLead.id);
                
                if (updatedLeadIndex !== -1) {
                  // Update the specific lead's data in the array
                  state.leadParentInformation[updatedLeadIndex] = updatedLead;
                } else {
                  console.warn("Lead not found in leadParentInformation");
                }
              } else {
                console.info("No payload in action");
              }
            } catch (error) {
              console.error("Error in updating comment in parent info for leads. Please contact admin", error);
            }
          })
          .addCase(getPetsInformation.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    
                    state.petsInformation = action.payload
                  
                }else {
                    console.info("No payload in action")
                    state.petsInformation = []
                }
            } catch(error) {
                console.info("Error in getting to pets info for leads. Please contact admin")
                state.petsInformation = []
            }
          })
          .addCase(getPetsInformation.rejected, (state)=>{
            console.info("getPetsInformation rejected")
            state.petsInformation = []
          })
          .addCase(editPetInformation.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    
                    state.petsInformation = action.payload
                  
                }else {
                    console.info("No payload in action")
                    state.petsInformation = []
                }
            } catch(error) {
                console.info("Error in editing pets info for leads. Please contact admin")
                state.petsInformation = []
            }
          })
          .addCase(editPetInformation.rejected, (state)=>{
            console.info("editPetInformation rejected")
            state.petsInformation = []
          })
          .addCase(deletePetInformation.fulfilled , (state, action)=>{
            try{
              console.log("action",action.payload.data.id)
                if(action.payload){

                  console.log("action.payload",action.payload)
                  console.log("state.petsInformation",state.petsInformation)
                  state.petsInformation = state.petsInformation.filter(
                    (pet) => pet._id !== action.payload.data.id
                  );
                  
                }else {
                    console.info("No payload in action")
                   
                }
            } catch(error) {
                console.info("Error in deleting cargo agent. Please contact admin")
            }
          })
          .addCase(deletePetInformation.rejected, (state)=>{
            console.info("deletePetInformation rejected")
          })
          .addCase(updateBaseDataFromGoogleSheet.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    alert("Data refreshed for the leads")
                  
                }else {
                    alert("Data refreshed for the leads")
                   
                }
            } catch(error) {
                console.info("Error in getting to refreshing info for leads. Please contact admin")
            }
          })
          .addCase(updateBaseDataFromGoogleSheet.rejected, (state)=>{
            console.info("updateBaseDataFromGoogleSheet rejected")
          })
          .addCase(getCargoAgents.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    
                    state.cargoAgents = action.payload
                  
                }else {
                    console.info("No payload in action")
                   
                }
            } catch(error) {
                console.info("Error in getting cargo agent. Please contact admin")
            }
          })
          .addCase(getCargoAgents.rejected, (state)=>{
            console.info("getCargoAgents rejected")
          })
          .addCase(putCargoAgents.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    
                  
                }else {
                    console.info("No payload in action")
                   
                }
            } catch(error) {
                console.info("Error in getting cargo agent. Please contact admin")
            }
          })
          .addCase(putCargoAgents.rejected, (state)=>{
            console.info("putCargoAgents rejected")
          })
          .addCase(deleteCargoAgent.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    
                  
                }else {
                    console.info("No payload in action")
                   
                }
            } catch(error) {
                console.info("Error in deleting cargo agent. Please contact admin")
            }
          })
          .addCase(deleteCargoAgent.rejected, (state)=>{
            console.info("deleteCargoAgent rejected")
          })
          .addCase(getFollowUpLeads.pending,()=>{
            console.info("getFollowUpLeads pending")
          })
          .addCase(getFollowUpLeads.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    
                    state.followUpLeads = action.payload
                  
                }else {
                    console.info("No payload in action")
                   
                }
            } catch(error) {
                console.info("Error in getting to start leads. Please contact admin")
            }
          })
          .addCase(getFollowUpLeads.rejected, (state)=>{
            console.info("getFollowUpLeads rejected")
          })
          .addCase(getItems.pending,()=>{
            console.info("getItems pending")
          })
          .addCase(getItems.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    
                    state.items = action.payload
                  
                }else {
                    console.info("No payload in action")
                   
                }
            } catch(error) {
                console.info("Error in getting items. Please contact admin")
            }
          })
          .addCase(getItems.rejected, (state)=>{
            console.info("getItems rejected")
          })
          .addCase(getExpense.pending,()=>{
            console.info("getExpense pending")
          })
          .addCase(getExpense.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    
                    state.expenses = action.payload
                  
                }else {
                    console.info("No payload in action")
                   
                }
            } catch(error) {
                console.info("Error in getting expenses. Please contact admin")
            }
          })
          .addCase(getExpense.rejected, (state)=>{
            console.info("getExpense rejected")
          })
          .addCase(getItemSales.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    
                    state.itemSalesTransactions = action.payload
                  
                }else {
                    console.info("No payload in action")
                   
                }
            } catch(error) {
                console.info("Error in getting getItemSales transactions. Please contact admin")
            }
          })
          .addCase(getItemSales.rejected, (state)=>{
            console.info("getItemSales rejected")
            state.itemSalesTransactions = null
          })
          .addCase(deleteItemSale.fulfilled, (state, action) => {
            const deletedItemId = action.payload;
            state.itemSalesTransactions = state.itemSalesTransactions.filter(item => item._id !== deletedItemId);
          })
          .addCase(postItemSale.fulfilled, (state, action) => {
            if (state.itemSalesTransactions === null) {
                state.itemSalesTransactions = []; // Initialize as an empty array
              }
            state.itemSalesTransactions.push(action.payload);
          })
          .addCase(getExpenseSales.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    
                    state.expenseSalesTransactions = action.payload
                  
                }else {
                    console.info("No payload in action")
                   
                }
            } catch(error) {
                console.info("Error in getting getExpenseSales transactions. Please contact admin")
            }
          })
          .addCase(getExpenseSales.rejected, (state)=>{
            console.info("getExpenseSales rejected")
            state.expenseSalesTransactions = null
          })
          .addCase(deleteExpenseSale.fulfilled, (state, action) => {
            const deletedExpenseId = action.payload;
            state.expenseSalesTransactions = state.expenseSalesTransactions.filter(item => item._id !== deletedExpenseId);
          })
          .addCase(postExpenseSale.fulfilled, (state, action) => {
            if (state.expenseSalesTransactions === null) {
                state.expenseSalesTransactions = []; // Initialize as an empty array
              }
            state.expenseSalesTransactions.push(action.payload);
          })
          .addCase(getLeadPetInformation.fulfilled, (state, action) => {
            try {
              if (action.payload.data) {
                state.petsInformation = action.payload.data;
                // Set both lead ID and condition value in the leadInfo object
                state.totalPetsFlag = {
                  parentId: action.payload.parent_id,
                  flag: 1,
                };
              } else {
                state.petsInformation = [];
                console.info("No payload in action");
                // Set both lead ID and condition value in the leadInfo object
                state.totalPetsFlag = {
                  parentId: action.payload.parent_id,
                  flag: 0,
                };
              }
            } catch (error) {
              console.info("Error in getting getItemSales transactions. Please contact admin");
              // Set both lead ID and condition value in the leadInfo object
              state.totalPetsFlag = {
                parentId: action.payload.parent_id,
                flag: 0,
              };
            }
          })
          .addCase(getLeadPetInformation.rejected, (state, action) => {
            console.info("getItemSales rejected");
            state.petsInformation = [];
            // Set both lead ID and condition value in the leadInfo object
            state.totalPetsFlag = {
              parentId: action.payload.parent_id,
              flag: 0,
            };
          })
          .addCase(getLeadParentInformationByID.fulfilled, (state, action) => {
            try {
              if (action.payload) {
                
                state.parentInformation = action.payload;
              } else {
                console.info("No payload in action");
                // Handle the case when there is no payload if needed.
              }
            } catch (error) {
              console.info("Error in getting getItemSales transactions. Please contact admin");
            }
          }).addCase(getLeadParentInformationByID.rejected, (state) => {
            console.info("getItemSales rejected");
            // Handle the rejection case if needed.
          })
          .addCase(getPaymentsForLead.fulfilled, (state, action) => {
            try {
              if (action.payload) {
                
                state.leadPayments = action.payload;
              } else {
                console.info("No payload in action");
                // Handle the case when there is no payload if needed.
              }
            } catch (error) {
              console.info("Error in getting getItemSales transactions. Please contact admin");
            }
          }).addCase(getPaymentsForLead.rejected, (state) => {
            console.info("getItemSales rejected");
            state.leadPayments = null;
            // Handle the rejection case if needed.
          })
          .addCase(getUserActions.fulfilled, (state, action) => {
            try {
              if (action.payload) {
                
                state.userActions = action.payload;
              } else {
                console.info("No payload in action");
                // Handle the case when there is no payload if needed.
              }
            } catch (error) {
              console.info("Error in getting getItemSales transactions. Please contact admin");
            }
          }).addCase(getUserActions.rejected, (state) => {
            console.info("getItemSales rejected");
            state.userActions = null;
            // Handle the rejection case if needed.
          })
          .addCase(postUser.fulfilled, (state, action) => {
            try {
              if (action.payload) {
                
                state.user = action.payload;
              } else {
                console.info("No payload in action");
                // Handle the case when there is no payload if needed.
              }
            } catch (error) {
              console.info("Error in getting user=. Please contact admin");
            }
          }).addCase(postUser.rejected, (state) => {
            console.info("postUser rejected");
            state.user = null;
            // Handle the rejection case if needed.
          })
          .addCase(sendCreateTemplate.fulfilled, (state, action) => {
            try {
              if (action.payload) {
                if (state.emailTemplates === null) {
                  state.emailTemplates = []; // Initialize as an empty array
                }
                  state.emailTemplates.push(action.payload);


              } else {
                console.info("No payload in action");
                // Handle the case when there is no payload if needed.
              }
            } catch (error) {
              console.info("Error in sendCreateTemplate=. Please contact admin");
            }
          })
          .addCase(deleteEmailTemplate.fulfilled, (state, action) => {
            try {
              if (action.payload) {
                
                  state.emailTemplates.pop(action.payload);


              } else {
                console.info("No payload in action");
                // Handle the case when there is no payload if needed.
              }
            } catch (error) {
              console.info("Error in deleteEmailTemplate=. Please contact admin");
            }
          })
          .addCase(getEmailTemplates.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    
                    state.emailTemplates = action.payload
                    
                    
                }else {
                    state.emailTemplates = null
                    
                }
            } catch(error) {
                alert("Error in getting completed leads. Please contact admin")
            }
          })
          .addCase(getEmailTemplates.rejected, (state)=>{
            state.emailTemplates = null
          })
          .addCase(getPetDocuments.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    
                    state.petDocuments = action.payload
                    
                    
                }else {
                    state.petDocuments = null
                    
                }
            } catch(error) {
                alert("Error in getting completed leads. Please contact admin")
            }
          })
          .addCase(getPetDocuments.rejected, (state)=>{
            state.emailTemplates = null
          }).addCase(getImagePetDocument.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    
                    state.imagePetDocument = action.payload
                    
                    
                }else {
                    state.imagePetDocument = null
                    
                }
            } catch(error) {
                alert("Error in getting completed leads. Please contact admin")
            }
          })
          .addCase(getImagePetDocument.rejected, (state)=>{
            state.imagePetDocument = null
          })
          .addCase(getImagePetDocument.pending, (state)=>{
            state.imagePetDocument = null
          }).addCase(getPDFPetDocument.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    
                    state.pdfPetDocument = action.payload
                    
                    
                }else {
                    state.pdfPetDocument = null
                    
                }
            } catch(error) {
                alert("Error in getting completed leads. Please contact admin")
            }
          })
          .addCase(getPDFPetDocument.rejected, (state)=>{
            state.pdfPetDocument = null
          })
          .addCase(getPDFPetDocument.pending, (state)=>{
            state.pdfPetDocument = null
          }).addCase(postPetDocument.fulfilled, (state, action) => {
            try {
              if (action.payload) {
                const existingDocIndex = state.petDocuments.findIndex(
                  (doc) => doc.doc_id === action.payload.doc_id
                );
    
                if (existingDocIndex !== -1) {
                  // Update the document if it already exists in the array
                  state.petDocuments[existingDocIndex] = action.payload;
                } else {
                  // Add the document to the array if it doesn't exist
                  state.petDocuments.push(action.payload);
                }
              }
            } catch (error) {
              alert('Error in getting completed leads. Please contact admin');
            }
          }).addCase(postPetDocument.rejected, (state, action) => {
            const { meta } = action;
            if (meta.doc_id) {
              // Remove the document with matching doc_id from the array
              state.petDocuments = state.petDocuments.filter(
                (doc) => doc.doc_id !== meta.doc_id
              );
            }
          }).addCase(postPetDocument.pending, (state, action) => {
            const { meta } = action;
            if (meta.doc_id) {
              // Remove the document with matching doc_id from the array
              state.petDocuments = state.petDocuments.filter(
                (doc) => doc.doc_id !== meta.doc_id
              );
            }
          }).addCase(getItemSaleTransaction.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    
                    state.finance_sales = action.payload
                    
                    
                }else {
                    state.finance_sales = null
                    
                }
            } catch(error) {
                alert("Error in getting completed leads. Please contact admin")
            }
          })
          .addCase(getItemSaleTransaction.rejected, (state)=>{
            state.finance_sales = null
          })
          .addCase(getItemSaleTransaction.pending, (state)=>{
            state.finance_sales = null
          }).addCase(getPayments.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    
                    state.finance_payments = action.payload
                    
                    
                }else {
                    state.finance_payments = null
                    
                }
            } catch(error) {
                alert("Error in getting completed leads. Please contact admin")
            }
          })
          .addCase(getPayments.rejected, (state)=>{
            state.finance_payments = null
          })
          .addCase(getPayments.pending, (state)=>{
            state.finance_payments = null
          }).addCase(postPayment.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    
                    state.finance_payments = [...state.finance_payments, action.payload];
                    
                    
                }else {
                  window.alert('Registration of this payment was rejected. Please try again');
                    
                }
            } catch(error) {
              window.alert('Registration of this payment was rejected. Please try again');
            }
          })
          .addCase(postPayment.rejected, (state)=>{
            window.alert('Registration of this payment was rejected. Please try again');
          })
          .addCase(postPayment.pending, (state)=>{
            console.log("Post payment is pending")
          }).addCase(getItemTransaction.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    
                    state.finance_purchases = action.payload
                    
                    
                }else {
                    state.finance_purchases = null
                    
                }
            } catch(error) {
                alert("Error in getting completed leads. Please contact admin")
            }
          })
          .addCase(getItemTransaction.rejected, (state)=>{
            state.finance_purchases = null
          })
          .addCase(getItemTransaction.pending, (state)=>{
            state.finance_purchases = null
          }).addCase(getExpenseTransaction.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    
                    state.finance_expenses = action.payload
                    
                    
                }else {
                    state.finance_expenses = null
                    
                }
            } catch(error) {
                alert("Error in getting completed leads. Please contact admin")
            }
          })
          .addCase(getExpenseTransaction.rejected, (state)=>{
            state.finance_expenses = null
          })
          .addCase(getExpenseTransaction.pending, (state)=>{
            state.finance_expenses = null
          }).addCase(postNewLeadParentData.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    
                    state.newParentInfo = action.payload
                    
                    
                }else {
                    state.newParentInfo = null
                    
                }
            } catch(error) {
                alert("Error in getting completed leads. Please contact admin")
            }
          })
          .addCase(postNewLeadParentData.rejected, (state)=>{
            state.newParentInfo = null
          })
          .addCase(postNewLeadParentData.pending, (state)=>{
            state.newParentInfo = null
          }).addCase(postNewLeadPetData.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    
                    state.newPetsInfo = action.payload
                    
                    
                }else {
                  console.log("No Paylod  in Inserting New Lead Pet Data")
                    
                }
            } catch(error) {
                alert("Error in getting completed leads. Please contact admin")
            }
          })
          .addCase(postNewLeadPetData.rejected, (state)=>{
            console.log("Error in Inserting New Lead Pet Data")
          })
          .addCase(postNewLeadPetData.pending, (state)=>{
            console.log("Pending in Inserting New Lead Pet Data")
          })
          


          ;
      },
    })

export const {
    updateUserChannels,
    updateCompletedLeads,
    updateFailedLeads,
    updateInProgressLeads,
    updateToStartLeads,
    updateLeadsSummary,
    updateLeadParentInfo,
    updateLeadID,
    updateUser,
    updateAccessToken,
    updateNewParentData,
    updateNewPetsData,
    resetNewParentData,
    resetNewPetsData,
    updateMyLeadsFlag,

} = globalSlice.actions;

export default globalSlice.reducer;